import React, { useState } from "react";
import setSelectValues from "../../../utils/setSelectValues";
import { useSelector, useDispatch } from "react-redux";
import { CellRefSelectBox } from "../../CellRefSelectBox";
import TagTick from "../../../assets/img/svg/CustomerOverViewIcon/TagTick";
import _ from "lodash";
import { updateSystemFieldCustomerConatct } from "../../../redux/CustomerContactUpload/action";

const ContactSystemField = (props) => {
  //
  let { value, column, alignment, accessor, row, ...rest } = props;

  const dispatch = useDispatch();

  const { systemFields } = useSelector((e) => e.customerContactUploadReducer);

  const uploadFieldOptions = systemFields.map((systemColumn) => ({
    label: (
      <>
        {systemColumn.system_column}{" "}
        {systemColumn.required && <span style={{ color: "#fd372a" }}>*</span>}
        {systemColumn?.isSelected && <TagTick />}
      </>
    ),
    category: systemColumn.system_column,
    id: systemColumn.id,
    module: systemColumn.module,
  }));

  value = _.get(row.original, accessor, "");

  const filterOptions = (uploadFieldOptions, filterValue) => {
    return uploadFieldOptions.filter((option) =>
      option.category.toLowerCase().includes(filterValue.toLowerCase())
    );
  };

  return (
    <CellRefSelectBox
      placeholder=""
      value={setSelectValues(value)}
      onChange={(params) => {
        dispatch(
          updateSystemFieldCustomerConatct({
            index: row.index,
            systemField: params?.value[0]?.id,
            type: params?.type,
            module: params?.value[0]?.module,
          })
        );
      }}
      filterOptions={filterOptions}
      options={uploadFieldOptions}
    />
  );
};

export default ContactSystemField;
