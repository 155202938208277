import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { useMutation, useQuery } from "react-query";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  ACT_PAYMENT,
  CO_INVOICES_,
  CUSTOMER_OVERVIEW_,
  PAYMENT_INVOICE_LIST_,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import {
  customerInvoiceOverview,
  getConvienceFeePAY,
} from "../../../../services";
import useFormat from "../../../../hooks/useFormat";
import { Icon, Typography } from "../../../../components_v2";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { useSelector } from "react-redux";
import { currencyList } from "../../../../utils_v2/CurrencyToSymbol";
const PayForm = (props) => {
  const {
    isInvoice,
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    selectedInvoices = {},
    totalAmount = 0,
    is_inbound_call = false,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();
  const format = useFormat();
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  let { validationField } = useSelector((e) => e.customerOverviewDrawer);
  const [card, setCard] = useState({
    isCard: false,
    cardId: "add_card",
    paymentMethodId: null,
  });

  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const cards = useMemo(() => {
    let cards = _.get(customerData, "data.doc.transaction_cards", []);
    return cards && Array.isArray(cards)
      ? [...cards, { id: "add_card" }]
      : [{ id: "add_card" }];
  }, [customerData]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      choose_payment: [{ id: "INSTANT" }],
    },
  });

  useEffect(() => {
    if (card && card?.paymentMethodId) {
      setValue("payment_method", [{ id: card?.paymentMethodId }]);
    }
  }, [card.paymentMethodId]);

  const choosePayment = watch("choose_payment");
  const paymentAmount = watch("payment_amount");
  const paymentMethodId = watch("payment_method");
  const { currencies, outstanding_amount } = useMemo(() => {
    const currencies = _.get(
      customerData,
      "data.doc.invoice_currencies",
      []
    ).map((e) => ({ id: e, label: e }));
    const outstanding_amount = Number(
      _.get(customerData, "data.doc.total_outstanding_invoice_amount.value", 0)
    ).toFixed(2);

    setValue("payment_currency", currencies[0] ? [currencies[0]] : []);
    setValue("payment_amount", outstanding_amount);
    return { currencies, outstanding_amount };
  }, [customerData?.data]);

  useEffect(() => {
    if (totalAmount > 0) {
      setValue("payment_amount", totalAmount);
    } else if (
      _.get(validationField, "minPayment") &&
      Number(paymentAmount) < _.get(validationField, "minPayment")
    ) {
      return setError("payment_amount", {
        type: "custom",
        message: `*Minimum Payment amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else {
      setValue("payment_amount", outstanding_amount);
    }
  }, [choosePayment, totalAmount]);

  useEffect(() => {
    if (
      _.get(validationField, "minPayment") &&
      Number(paymentAmount) < _.get(validationField, "minPayment")
    ) {
      setError("payment_amount", {
        message: `*Minimum Payment amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else {
      clearErrors("payment_amount");
    }
  }, [paymentAmount]);

  const {
    data: convienceFeePayData,
    isFetched: isConventionFetched,
    isError: isErrorConvience,
    isLoading: isLoadingConvention,
  } = useQuery(
    [
      `GET_CONVENIENCE_FEE_PAY_${customerId}`,
      {
        amount: paymentAmount,
        paymentGatewayId: getSelectValues(paymentMethodId),
      },
    ],
    async ({ queryKey }) => {
      const [, { amount, count, paymentGatewayId }] = queryKey; // Destructure dynamic variables
      console.log(
        "Query function executed with:",
        amount,
        count,
        paymentGatewayId
      );
      return await getConvienceFeePAY({
        organization: currentOrganization,
        customerId: customerId,
        amount,
        count,
        paymentGatewayId, // Add the new query param
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled:
        Boolean(paymentAmount) && Boolean(getSelectValues(paymentMethodId)), // Ensure all conditions are met
      cacheTime: 5 * 60 * 1000,
      onSuccess: (data) => {
        console.log("Query success:", data);
      },
      onError: (error) => {
        console.error("Query error:", error);
      },
    }
  );

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: (data) => {
        onCancel();
        if (
          data &&
          data.data &&
          data.data.doc &&
          data.data.doc.payment_initiate_url
        ) {
          window.open(data.data.doc.payment_initiate_url);
        } else if (
          data &&
          data.data &&
          data.data.doc &&
          data.data.doc.payment_status_fetch_url
        ) {
          onSuccess();

          window.open(
            data &&
              data.data &&
              data.data.doc &&
              data.data.doc.payment_status_fetch_url
          );
        }
      },
    }
  );

  const onSubmit = async (data) => {
    const {
      choose_payment,
      payment_method,
      payment_amount,
      payment_currency,
      is_save_card = false,
      online_payment_type = false,
    } = data;

    if (isInvoice.length < 1) {
      return;
    }

    if (
      Number(totalAmount) !== 0 &&
      Number(payment_amount) > Number(totalAmount)
    ) {
      return setError("payment_amount", {
        type: "custom",
        message: "Amount greater than due amount",
      });
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));
    let values = {
      ...selectedInvoices,
      ...formValues,
      ...(card.cardId !== "add_card" ? { card_id: card.cardId } : {}),
      payment_type: getSelectValues(choosePayment),
      ...(online_payment_type ? { online_payment_type: "HSA" } : {}),
      payment_method: getSelectValues(payment_method),
      payment_amount: {
        value: payment_amount,
        currency: getSelectValues(payment_currency),
      },
      is_save_card,
      mention_users: uniqueMentionedUsers,
      ...getActionReminder(data, {
        is_hold: false,
        is_consent_enabled: false,
        is_calendar: false,
        is_followup: false,
        is_confirmation: false,
      }),
    };

    await serviceMutation.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", gap: "5px", marginTop: "20px" }}>
        <Controller
          name="choose_payment"
          control={control}
          render={({ field }) => (
            <SelectBox
              {...field}
              clearable={false}
              name={field.name}
              fullWidth
              size={"mini"}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "choose_payment",
              })}
              placeholder={intl.formatMessage({
                id: "choose_payment",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={referenceData["payment_type"]}
            />
          )}
        />

        {choosePayment &&
          choosePayment[0] &&
          choosePayment[0].id === "INSTANT" && (
            <>
              <Controller
                name="payment_method"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={"mini"}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "payment_method",
                    })}
                    placeholder={intl.formatMessage({
                      id: "payment_method",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    requiredAstric={true}
                    options={referenceData["online_payment_method"] || []}
                  />
                )}
              />

              <Controller
                name="payment_currency"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={"mini"}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "cur",
                    })}
                    placeholder={intl.formatMessage({
                      id: "cur",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    requiredAstric={true}
                    options={currencies}
                  />
                )}
              />

              <Controller
                name="payment_amount"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={"mini"}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "payment_amount",
                    })}
                    placeholder={intl.formatMessage({
                      id: "payment_amount",
                    })}
                    value={field.value}
                    requiredAstric={true}
                    type="number"
                  />
                )}
              />
            </>
          )}
      </div>
      {!watch("online_payment_type") &&
        convienceFeePayData &&
        convienceFeePayData?.doc &&
        convienceFeePayData?.doc?.total_fee_amount > 0 && (
          <div className="ins_suggestion">
            <Typography type="p" className="text-secondary">
              {`${
                _.get(convienceFeePayData, "doc.total_fee_amount", 0) > 0
                  ? ` ${
                      currencyList[
                        _.get(customerData, "data.doc.default_currency", 0)
                      ]
                    }${_.get(convienceFeePayData, "doc.total_fee_amount", 0)}`
                  : ""
              }  Processing Fees applies`}
            </Typography>
          </div>
        )}
      {_.get(paymentMethodId, "[0].is_hsa", false) && (
        <div className="cp-message-confirmation-checkbox">
          <Controller
            defaultValues={false}
            name="online_payment_type"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                <FormattedMessage id="enable_hsa">Enable HSA</FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        {cards.map((e, i) => (
          <>
            {e.id === "add_card" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "2px",
                  padding: "4px",
                  width: "195px",
                  border: "1px solid #EEEEEE",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setCard({
                    isCard: true,
                    cardId: e.id,
                    paymentMethodId: e.payment_config_id,
                  });
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <div className="add_saved_pay_cards">
                    <Icon icon="plus" color="#516beb" />
                  </div>

                  <Typography type="p" className="regular" subType="medium">
                    Add New Card
                  </Typography>
                </div>
                <Radio checked={card && card.cardId === e.id}></Radio>
              </div>
            ) : (
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "2px",
                  padding: "4px",
                  width: "195px",
                  border: "1px solid #EEEEEE",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setCard({
                    isCard: true,
                    cardId: e.id,
                    paymentMethodId: e.payment_config_id,
                  });
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography type="p" className="text-primary">
                    {format.rd({
                      name: "online_payment_method",
                      id: e?.payment_config_id,
                    })}
                  </Typography>
                  <div style={{ display: "flex", gap: "3px" }}>
                    <Typography type="p" className="regular" subType="regular">
                      Card Ends with
                    </Typography>
                    <Typography type="p" className="regular" subType="medium">
                      {e?.last4}
                    </Typography>
                  </div>
                </div>
                <Radio checked={card && card.cardId === e.id}></Radio>
              </div>
            )}
          </>
        ))}
      </div>

      <Controller
        defaultValues={false}
        name="is_save_card"
        control={control}
        render={({ field }) => (
          <CheckBoxBaseweb
            {...field}
            checked={field.value}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            <FormattedMessage id="save_card_information_for_future_purpose">
              save_card_information_for_future_purpose
            </FormattedMessage>
          </CheckBoxBaseweb>
        )}
      />

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        is_inbound_call={
          formValues.is_inbound_call ? true : false || is_inbound_call
        }
        outcome={ACT_PAYMENT}
        timeZone={_.get(customerData, "data.doc.timezone")}
        is_hold={false}
        is_confirmation={false}
        is_followup={false}
        is_calendar={false}
        is_consent_enabled={false}
        is_incrementor={props.isIncrementor}
      />

      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

const Pay = (props) => {
  const { customerId } = useParams();

  const [isInvoice, setIsInvoice] = useState([]);

  const { currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const [isPromiseExist, setIsPromiseExist] = useState(false);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  // const invoicesData = queryClient.getQueryData([
  //   `${CO_INVOICES_}${customerId}`,
  //   { filters: {}, pgid: _.get(customerData, "data.doc.pgid", "") },
  // ]);
  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);

  // const invoicesData = useQuery(
  //   [
  //     `${PAYMENT_INVOICE_LIST_}-${customerId}}`,
  //     //   need to add
  //     {
  //       pageIndex: 0,
  //       pageSize: 0,
  //       filters: {
  //         status: ["due", "overdue"],
  //         sort: "invoice_due_date",
  //         gid: _.get(customerData, "data.doc.gid"),
  //       },
  //     },
  //   ],
  //   async ({ queryKey }) => {
  //     let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
  //     filters = {
  //       ...filters,
  //     };
  //     return await customerInvoiceOverview({
  //       pageIndex,
  //       sortBy,
  //       pageSize,
  //       customer_id: customerId,
  //       filters,
  //       organization: currentOrganization,
  //     });
  //   }
  // );
  const format = useFormat();
  const invOption = useMemo(() => {
    // let findpgid = _.get(invoicesData, "data.docs", []).filter(
    //   ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
    // );

    let findpgid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData.data.doc._id
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
    }

    let invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        outstanding: not_paid_amount,
      }));
    let allInv = _.get(invoicesData, "data.docs", [])
      .filter((item) => {
        return item.status !== "paid";
      })
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        outstanding: not_paid_amount,
        label: `${format.rd({
          id: _.get(e, "business_unit", ""),
          name: "business_unit_list",
        })}${_.get(e, "business_unit", "") ? " / " : ""}${_.get(
          e,
          "invoice_number",
          ""
        )}${_.get(e, "invoice_number", "") ? " / " : ""}
        ${_.get(e, "client_reference", "")}${
          _.get(e, "client_reference", "") ? " / " : ""
        }
        ${moment
          .utc(e.invoice_date)
          .tz(currentDefaultFormatDetails.time_zone)
          .format(currentDefaultFormatDetails.date_format)}${
          _.get(not_paid_amount, "currency", "") ? " / " : ""
        }${_.get(not_paid_amount, "currency", "")}${
          _.get(not_paid_amount, "value", "") ? " " : ""
        }${_.get(not_paid_amount, "value", "")}`,
      }));

    setIsInvoice([...invIds]);
    return allInv;
  }, [invoicesData]);

  const promiseStatus = useMemo(() => {
    let matchingIds = [];
    for (
      let i = 0;
      i < _.get(customerData, "data.doc.promise_to_pay", []).length;
      i++
    ) {
      let invArray =
        customerData && customerData?.data?.doc?.promise_to_pay?.[i];
      for (let j = 0; j < invArray?.inv?.length; j++) {
        if (isInvoice.some((item) => item.id === invArray?.inv?.[j])) {
          if (invArray && invArray.status === "IN_PROGRESS") {
            matchingIds.push(invArray);
          }
        }
      }
    }
    return matchingIds;
  }, [isInvoice]);

  useEffect(() => {
    if (promiseStatus.length > 0) {
      setIsPromiseExist(true);
    }
  }, [isInvoice]);

  return (
    <div style={{ marginTop: "15px" }}>
      <div style={{ width: "350px" }}>
        <SelectBox
          requiredAstric={true}
          value={isInvoice}
          getValueLabel={({ option }) => {
            const { label } = option;
            const value = label ? label.split("/") : [];
            return <>{value && value[1] ? value[1] : value[0]}</>;
          }}
          onChange={(e) => {
            setIsInvoice(e.value);
          }}
          multi
          options={invOption}
          size={"mini"}
        />
        {isInvoice.length < 1 && (
          <span style={{ color: "red" }}>Invioces is required</span>
        )}
      </div>

      <PayForm
        isInvoice={isInvoice}
        selectedInvoices={{ invoices: isInvoice?.map(({ id }) => id) }}
        totalAmount={isInvoice.reduce((sum, item, i) => {
          return Number(+sum + +_.get(item, "outstanding.value", 0)).toFixed(2);
        }, 0)}
        {...props}
      />
    </div>
  );
};

export default Pay;
