import React, { useState, useContext } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { IconButton, SIZE, KIND } from "../../components/IconButton";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";

import PageTitle from "../../components/PageTitle";
import Refresh from "../../assets/img/svg/Refresh";

const DashboardTitle = ({
  queryKey,
  title,
  setElapsedTime,
  elapsedTime,
  collectionQueueKey,
  foreCastingQueueKey,
  collectionRmQuerykey,
  collectionClientQuerykey,
}) => {
  const queryClient = useQueryClient();
  const { currentOrganization } = useContext(MaxyfiContext);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);

  const waitFor = (sec) => new Promise((resolve) => setTimeout(resolve, sec));

  const handleRefresh = async () => {
    setLoading(true);
    setStartTime(new Date());

    await queryClient.invalidateQueries({
      predicate: (query) => {
        return query?.queryKey[0]?.startsWith(
          `${queryKey}${currentOrganization}_`
        );
      },
    });

    toast.success("Data Refresh Initiated");

    await waitFor(5000);
    setLoading(false);

    // const endTime = new Date();
    // const timeDiff = endTime - startTime;
    // const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
    // const hours = Math.floor(timeDiff / 1000 / 60 / 60);

    // setElapsedTime({ minutes, hours });
    // toast.info(
    //   `Data refresh completed in ${hours} hour(s) and ${minutes} minute(s)`
    // );
  };

  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <PageTitle id={title} />
      <IconButton
        size={SIZE.mini}
        kind={KIND.tertiary}
        isLoading={loading}
        isDisabled={loading}
        onClick={handleRefresh}
      >
        <Refresh size={18} />
      </IconButton>
    </div>
  );
};

export default DashboardTitle;
