import axiosWithAuth from "../providers/AxiosInterceptor";
import { download } from "../utils/downloadFile";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;
let fileType = {
  plain: "txt",
  csv: "csv",
};
export const roleMaintenanceForm = async ({
  organization,
  customerId,
  ...rest
}) => {
  return await axiosWithAuth.post(`${HOST}role`, rest, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const roleMaintenanceFormEdit = async ({
  organization,
  customerId,
  roleId,
  ...rest
}) => {
  return await axiosWithAuth.put(`${HOST}role/${roleId}`, rest, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const getRoleMaintenanceJasonData = async ({}) => {
  return await axiosWithAuth.get(`${HOST}role/all-role-permissions`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

// export const getRoleMaintenance = async ({ organization, customerId }) => {
//   return await axiosWithAuth.get(`${HOST}role`, {
//     headers: {
//       appCode: APP_CODE,
//     },
//   });
// };

export const getRoleMaintenance = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
}) => {
  return await axiosWithAuth.get(`${HOST}role`, {
    params: {
      // page: pageIndex + 1,
      // sort: sortBy,
      // page_size: pageSize,
      ...filters,
    },
  });
};

export const roleMaintenancePatch = async ({ roleId }) => {
  return await axiosWithAuth.patch(`${HOST}role/${roleId}`);
};

export const roleMaintenanceDelete = async ({ roleId }) => {
  return await axiosWithAuth.delete(`${HOST}role/${roleId}`);
};

export const getRoleMaintenanceAudit = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(`${HOST}role/${id}/audits`);
};

export const getRoleMaintenanceData = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(`${HOST}role/${id}`);
};

export const enableReportMain = async ({ organization, id }) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/report-delivery/${id}`
  );
};

export const getRoleDeliveryArenaData = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/report-delivery/${id}/executions`,
    {
      params: {
        page: 1,
        // sort: sortBy,
        page_size: 0,
      },
    }
  );
};

export const downloadExecutionInfo = async ({ organization = null, id }) => {
  return await axiosWithAuth
    .get(`${HOST}${organization}/report-delivery/${id}/download`, {
      headers: {
        appCode: APP_CODE,
      },
      responseType: "arraybuffer",
    })
    .then((blob) => {
      download({
        fileName: `execution-info.${
          fileType[blob?.headers?.["content-type"]?.split("/")[1]]
        }`,
        fileData: blob.data,
      });
    });
};
