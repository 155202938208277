import React, { useEffect, useContext, Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import keycloak from "../../providers/KeyCloak";
import CustomerOverviewV2 from "../../views_v2/CustomerOverview";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  ADHOC_UPLOAD,
  BUSINESS_UNIT_MAINTENANCE,
  CLIENT_DASHBOARD,
  COMMAND_CENTER_DASHBOARD,
  COMMUNICATION_TEMPLATE_MAINTENANCE,
  CREDIT_NOTE,
  CUSTOMERANALYSIS,
  CUSTOMER_OVERVIEW,
  DASHBOARD,
  DEBT_COLLECTION_DASHBOARD,
  INVOICE_ITEM_MAINTENANCE,
  INVOICE_OVERVIEW,
  ORGANIZATION_SETTINGS,
  REPORTS,
  REVENUE_BILLING,
  REVENUE_DASHBOARD,
  ROLE_MAINTENANCE,
  UPLOAD_CLIENT_CUSTOMER_INVOICE,
  USER_DASHBOARD,
  USER_MAINTENANCE,
  WORKFLOW_MAINTENANCE,
  SMS_ANALYTICS,
  HOLIDAY_BLACKLIST_MAINTENANCE,
  REQUEST_INFORMATION,
  MINI_MIRANDA_CONFIGURATION,
  Contact_Status_Maintenance,
  LETTER_STATUS,
  VIEW_LOCATION_TRACKER,
  CHECK_MAINTENANCE,
  KPI_MENU_CODE,
  CONSUMER_STATUS_MAINTENANANCE,
  PERFORMANCE_DASHBOARD,
  VIEW_CONSUMER_STATUS,
  EMAIL_ANALYTICS,
  EMAIL_DASHBOARD,
  STRATEGY_MAINTAINANCE,
  REPORT_MAINTAINANCE_ALL,
  REPORT_DELIVERY_SETTINGS,
} from "../../providers/RBACProvider/permissionList";

import DashboardView from "../../views/DashBoard/DashboardView";
import CustomerSummary from "../../views/CustomerSummary";
import InvoiceSummary from "../../views/InvoiceSummary/InvoiceSummary";
import InvoiceOverview from "../../views/InvoiceOverview";
import KpiMaintenance from "../../views_v2/KpiMaintenance/KpiMaintenance";
import PerformanceDashboard from "../../views_v2/PerformanceDashboard/PerformanceDashboard";
import WebAccessDeniedView from "../../views/AccessDenied/WebAccessDeniedView";
import ReportDownload from "../../views_v2/ReportDownload/ReportDownload";
import CheckMaintenance from "../../views_v2/CheckMaintenance";
import ReportDeliverySettings from "../../views/ReportDeliverySettings";
import DrawingBoard from "../../views/datalake/DrawingBoard.js";

const ReportView = React.lazy(() => import("../../views/report/ReportView"));
const AddNewInvoicing = React.lazy(() => import("../../views/AddNewInvoicing"));

const CustomerAnalysisDashBoardView = React.lazy(() =>
  import("../../views/CustomerAnalysis/CustomerAnalysisDashboard")
);
const SMSDeliverabilityDashboard = React.lazy(() =>
  import("../../views/SMSDeliverabilityDashboard/SMSDeliverabilityDashboard")
);
const EmailAnalyticsDashboard = React.lazy(() =>
  import("../../views/EmailAnalytics/EmailAnalyticsDashboard")
);

const NewDashBoardView = React.lazy(() =>
  import("../../views/BusinessDashboard/NewDashBoard")
);
const RevenueDashboardView = React.lazy(() =>
  import("../../views/RevenueDashboard/RevenueDashboardView")
);
const CommandCenterDashboad = React.lazy(() =>
  import("../../views/CommandCenterDashboard/CommandCenterDashboard")
);
const DebtCollectionDashboard = React.lazy(() =>
  import("../../views/DebtCollectionDashboard/DebtCollectionDashboard")
);

const AccessDeniedView = React.lazy(() =>
  import("../../views/AccessDenied/AccessDeniedView")
);
const ClientUpload = React.lazy(() => import("../../views/ClientUpload"));

const CreditNote = React.lazy(() => import("../../views/CreditNotes"));
const UserRegistationView = React.lazy(() =>
  import("../../views/UserProfile/UserView")
);

const AccountingBooks = React.lazy(() =>
  import("../../containers/OnBording/AccountingBook/AccountingBook")
);
const AddOraganization = React.lazy(() =>
  import("../../containers/OnBording/AddOrganization/AddOrganization")
);
const ThankYou = React.lazy(() =>
  import("../../containers/OnBording/ThankYou/ThankYou")
);

const AdhocUpload = React.lazy(() => import("../../views/AdhocUpload"));
const CommunicationTemplate = React.lazy(() =>
  import("../../views/CommunicationTemplate")
);
const StrategicMainteinace = React.lazy(() =>
  import("../../views/StrategicMainteince/index")
);
const ReportMainteinace = React.lazy(() =>
  import("../../views/ReportMaintanance/index")
);
const UpdateCommunicationTemplate = React.lazy(() =>
  import("../../views/CommunicationTemplate/UpdateCommunicationTemplate")
);
const UpdateStrategyTemplate = React.lazy(() =>
  import("../../views/StrategicMainteince/UpdateStrategyMainteince")
);
const UpdateReportTemplate = React.lazy(() =>
  import("../../views/ReportMaintanance/updateReportMaintanance.js")
);

const Workflow = React.lazy(() =>
  import("../../views/WorkflowMaintenance/Workflow")
);
const CreateWorkFlow = React.lazy(() =>
  import("../../views/WorkflowMaintenance/CreateWorlFlow")
);

const ContactStatusMaintenance = React.lazy(() =>
  import("../../views/ContactStatusMaintenance/ContactStatusMaintenance")
);
const LetterStatus = React.lazy(() => import("../../views/LetterStatus"));

const UserView = React.lazy(() => import("../../views/UserPage/UserView"));
const Setting = React.lazy(() => import("../../views/setting/Setting"));
const InvoiceItemMaintenance = React.lazy(() =>
  import("../../views/InvoiceItemMaintenance")
);
const RoleMaintenance = React.lazy(() => import("../../views/roleMaintenance"));
const BusinessUnitMaintenance = React.lazy(() =>
  import("../../views/BusinessUnitMaintenance/BusinessUnitMaintenance")
);
const RevenueAndBilling = React.lazy(() =>
  import("../../views/RevenueAndBilling/RevenueAndBilling")
);
const HolidayBlackListMaintenance = React.lazy(() =>
  import("../../views/HolidayBlackoutMaintenance")
);
const HolidayBlackoutMaintenanceForm = React.lazy(() =>
  import("../../views/HolidayBlackoutMaintenance/HolidayBlackoutForm")
);
const MiniMiranda = React.lazy(() =>
  import("../../views/MiniMiranda/MiniMiranda")
);
const RequestInformation = React.lazy(() =>
  import("../../views/RequestInformation")
);

const LocationTracker = React.lazy(() =>
  import("../../views/LocationTracker/LocationTracker")
);
const ConsumerStatusMaintenance = React.lazy(() =>
  import("../../views/ConsumerStatusMaintenance/consumerStatusMaintenance")
);
const BankruptcyMaintenance = React.lazy(() =>
  import("../../views_v2/BankruptcyMaintenance/bankruptcyMaintenance")
);
const StickyNotesMaintenance = React.lazy(() =>
  import("../../views/StickyNotesMaintainance/StickyNotesMaintenance")
);

const ProtectedRoutes = ({ children }) => {
  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, []);

  if (keycloak.authenticated) {
    return children;
  }

  return null;
};

const BlankPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return null;
};

const CombineRouter = (props) => {
  let { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);

  let findingUser = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const creditNotePermission =
    space && space?.role_permissions?.includes(CREDIT_NOTE);

  const routesListNew = [
    {
      path: "/",
      Element: DashboardView,
      permission: [USER_DASHBOARD],
      isRootPath: true,
    },
    {
      path: "/debt-collection-dashboard",
      Element: DebtCollectionDashboard,
      permission: [DEBT_COLLECTION_DASHBOARD],
      isRootPath: true,
    },
    {
      path: "/business-dashboard",
      Element: NewDashBoardView,
      permission: [CLIENT_DASHBOARD],
      isRootPath: true,
    },
    {
      path: "/CustomerAnalysis",
      Element: CustomerAnalysisDashBoardView,
      permission: [CUSTOMERANALYSIS],
      isRootPath: true,
    },
    {
      path: "/sms-analytics",
      Element: SMSDeliverabilityDashboard,
      permission: [SMS_ANALYTICS],
      isRootPath: true,
    },
    {
      path: "/email-analytics",
      Element: EmailAnalyticsDashboard,
      permission: [EMAIL_ANALYTICS],
      isRootPath: true,
    },
    {
      path: "/revenue-dashboard",
      Element: RevenueDashboardView,
      permission: [REVENUE_DASHBOARD],
      isRootPath: true,
    },
    {
      path: "/command-center",
      Element: CommandCenterDashboad,
      permission: [COMMAND_CENTER_DASHBOARD],
      isRootPath: true,
    },
    {
      path: "/customers",
      Element: CustomerSummary,
      permission: [CUSTOMER_OVERVIEW],
      isRootPath: true,
    },
    {
      path: "/customers/:customerId",
      key: ":customerId",
      Element: CustomerOverviewV2,
      permission: [],
    },
    {
      path: "/invoices",
      Element: InvoiceSummary,
      permission: [INVOICE_OVERVIEW],
    },
    {
      path: "/invoices/:id",
      Element: InvoiceOverview,
      permission: [INVOICE_OVERVIEW],
    },
    {
      path: "/workflows",
      Element: Workflow,
      permission: [WORKFLOW_MAINTENANCE],
    },
    {
      path: "/workflows/:id",
      Element: CreateWorkFlow,
      permission: [WORKFLOW_MAINTENANCE],
    },
    {
      path: "/templates",
      Element: CommunicationTemplate,
      permission: [COMMUNICATION_TEMPLATE_MAINTENANCE],
    },
    {
      path: "/templates/:id",
      Element: UpdateCommunicationTemplate,
      permission: [COMMUNICATION_TEMPLATE_MAINTENANCE],
    },
    {
      path: "/strategy-maintenance",
      Element: StrategicMainteinace,
      permission: [STRATEGY_MAINTAINANCE],
    },
    {
      path: "/report-maintenance",
      Element: ReportMainteinace,
      permission: [REPORT_MAINTAINANCE_ALL],
    },
    {
      path: "/report-maintenance/:id",
      Element: UpdateReportTemplate,
      key: ":id",
      permission: [REPORT_MAINTAINANCE_ALL],
    },

    {
      path: "/strategy-maintenance/:id",
      Element: UpdateStrategyTemplate,
      permission: [STRATEGY_MAINTAINANCE],
    },
    {
      path: "/settings",
      Element: Setting,
      permission: [ORGANIZATION_SETTINGS],
    },
    {
      path: "/users",
      Element: UserView,
      permission: [USER_MAINTENANCE],
    },

    { path: "/organization", Element: AddOraganization, permission: [] },
    { path: "/accounting/:org_id", Element: AccountingBooks, permission: [] },
    { path: "/thank-you", Element: ThankYou, permission: [] },
    {
      path: "/uploads",
      Element: AdhocUpload,
      permission: [ADHOC_UPLOAD],
      isRootPath: true,
    },
    {
      path: "/report/:reportType",
      Element: ReportView,
      permission: [REPORTS],
    },

    {
      path: "/invoice-item",
      Element: InvoiceItemMaintenance,
      permission: [INVOICE_ITEM_MAINTENANCE],
    },
    { path: "/invoice-form", Element: AddNewInvoicing, permission: [] },
    {
      path: "/role-maintenance",
      Element: RoleMaintenance,
      permission: [ROLE_MAINTENANCE],
    },
    {
      path: "/business-unit",
      Element: BusinessUnitMaintenance,
      permission: [BUSINESS_UNIT_MAINTENANCE],
    },
    {
      path: "/revenue-billing",
      Element: RevenueAndBilling,
      permission: [REVENUE_BILLING],
    },
    {
      path: "/client-uploads",
      Element: ClientUpload,
      permission: [UPLOAD_CLIENT_CUSTOMER_INVOICE],
    },
    {
      path: "/profile",
      Element: UserRegistationView,
      permission: [],
    },
    {
      path: "/letterStatus",
      Element: LetterStatus,
      permission: [LETTER_STATUS],
    },
    {
      path: "/contact-status-maintenance",
      Element: ContactStatusMaintenance,
      permission: [Contact_Status_Maintenance],
    },
    {
      path: "/holiday-blackout-maintenance",
      Element: HolidayBlackListMaintenance,
      permission: [HOLIDAY_BLACKLIST_MAINTENANCE],
    },
    {
      path: "/holiday-blackout-maintenance/:resourceId",
      Element: HolidayBlackoutMaintenanceForm,
      permission: [HOLIDAY_BLACKLIST_MAINTENANCE],
    },
    {
      path: "/mini-miranda-configuration",
      Element: MiniMiranda,
      permission: [MINI_MIRANDA_CONFIGURATION],
    },
    {
      path: "/request-information",
      Element: RequestInformation,
      permission: [REQUEST_INFORMATION],
    },
    {
      path: "/location-tracking",
      Element: LocationTracker,
      permission: [VIEW_LOCATION_TRACKER],
    },

    {
      path: "/consumer-status-maintenance",
      Element: ConsumerStatusMaintenance,
      permission: [VIEW_CONSUMER_STATUS],
    },
    {
      path: "/bankruptcy-maintenance",
      Element: BankruptcyMaintenance,
      permission: [],
    },
    {
      path: "/kpi_maintenance",
      Element: KpiMaintenance,
      permission: [KPI_MENU_CODE],
    },
    {
      path: "/report-download",
      Element: ReportDownload,
      permission: [],
    },
    {
      path: "/performance-dashboard",
      Element: PerformanceDashboard,
      permission: [PERFORMANCE_DASHBOARD],
    },
    {
      path: "/nacha-maintenance",
      Element: CheckMaintenance,
      permission: [CHECK_MAINTENANCE],
    },
    {
      path: "/sticky-notes-maintenance",
      Element: StickyNotesMaintenance,
      // permission: [STICKY_MAINTENANCE],
    },
    // {
    //   path: "/drawing-board",
    //   Element: DrawingBoard,
    //   // permission: [STICKY_MAINTENANCE],
    // },
    // {
    //   path: "/report-delivery-settings",
    //   Element: ReportDeliverySettings,
    //   permission: [],
    // },
    {
      path: "/report-delivery-settings",
      Element: ReportDeliverySettings,
      permission: [REPORT_DELIVERY_SETTINGS],
    },
  ];

  const csvRouteList = [];

  const accountingRouteList = [
    ...(creditNotePermission
      ? [
          {
            path: "/credit-notes",
            Element: CreditNote,
            permission: [CREDIT_NOTE],
          },
        ]
      : []),
  ];

  const allowedRoutes = routesListNew.filter((rt) => {
    if (!rt.permission || rt.permission.length === 0) {
      return true;
    }
    const hasAllPermission = rt.permission.map(
      (perm) => space && space.role_permissions?.includes(perm)
    );
    if (hasAllPermission.some((e) => e)) {
      return true;
    }
    return false;
  });

  const rootPathRoute = allowedRoutes.filter((e) => e.isRootPath);

  if (
    userInfo &&
    userInfo.acc_ty &&
    Array.isArray(userInfo.acc_ty) &&
    !userInfo.acc_ty.includes("WEB")
  ) {
    return <WebAccessDeniedView />;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {[
          ...allowedRoutes,
          ...(findingUser?.accounting_book === "CSV"
            ? csvRouteList
            : accountingRouteList),
          ...(rootPathRoute && rootPathRoute[0]
            ? [{ ...rootPathRoute[0], path: "/" }]
            : [
                {
                  path: "/",
                  Element: AccessDeniedView,
                },
              ]),
        ].map(({ path, Element, key }) => {
          return (
            <Route key={key ? key : path} path={path} element={<Element />} />
          );
        })}
        <Route path="*" element={<BlankPage />} />
      </Routes>
    </Suspense>
  );
};

export default CombineRouter;
