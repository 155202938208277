import React from "react";
import LetterIcon from "../../assets/img/svg/CustomerTemplate/Letter";
import EmailIcon from "../../assets/img/svg/CustomerTemplate/Email";
import MessageIcon from "../../assets/img/svg/Template/Message";
import {
  EMAIL,
  SMS,
  LETTER,
  INVOICE,
  NOTE,
  PROMISE,
  CALLBACKLATER,
  MEETINGREQUEST,
  HOLDACTION,
  CALLUNSUCCESSFUL,
  DISPUTE,
  NEXTACTION,
  REVIEWPROMISE,
  SKIPPEDTIMELINE,
  RELEASED,
  WHATSAPP,
  CUSTOMERT_STATUS,
  BUSINESS_UNIT,
  WORKFLOW,
  RM,
  NOTES,
  CONTACTS,
  CUSTOMERPORTAL,
  PORTAL_PAYMENT,
  CALL_RECORDING,
  CUSTOMER_NAME_UPDATE,
  SKIP_TRACE,
  ESIGN,
} from "../../constants";
import InvoiceSvg from "../../assets/img/svg/InvoiceSvg";
import Notesvg from "../../assets/img/svg/Notesvg";
import PromiseToPay from "../../assets/img/svg/PromiseToPay";
import CallBackLater from "../../assets/img/svg/CallBackLater";
import MeetingRequest from "../../assets/img/svg/MeetingRequest";
import HoldAction from "../../assets/img/svg/HoldAction";
import CallUnsuccessful from "../../assets/img/svg/CallUnsuccessful";
import Dispute from "../../assets/img/svg/Dispute";
import NextAction from "../../assets/img/svg/NextAction";
import ReviewPromiseToPay from "../../assets/img/svg/ReviewPromiseToPay";
import HoldActionTimeLineCard from "../../assets/img/svg/HoldActionTimeLineCard";
import SkippedTimeLineCard from "../../assets/img/svg/SkippedTimeLineCard";
import { faPray } from "@fortawesome/free-solid-svg-icons";
import ReleaseTimeLineCard from "../../assets/img/svg/ReleaseTimeLineCard";
import WhatsApp from "../../assets/img/svg/Template/WhatsApp";
import CustomerStatus from "../../assets/img/svg/CustomerStatus";
import BusinessUnit from "../../assets/img/svg/BusinessUnit";
import Workflow from "../../assets/img/svg/Workflow";
import User from "../../assets/img/svg/Upload/User";
import RelManager from "../../assets/img/svg/RelManager";
import NotesTimelineSvg from "../../assets/img/svg/NotesTimelineSvg";
import ContactTimelineSvg from "../../assets/img/svg/ContactTimelineSvg";
import PortalTLSvg from "../../assets/img/svg/PortalTLSvg";
import PortalPayment from "../../assets/img/svg/PortalPayment";
import CallRecordingSvg from "../../assets/img/svg/CallRecordingSvg";
import CustomerNameSvg from "../../assets/img/svg/CustomerNameSvg";
import LocationSvg from "../../assets/img/svg/LocationSvg";
import SkipTrace from "../../assets/img/svg/SkipTrace";
import NextActionIcon from "../../assets/img/svg/NextActionIcon";
import RequestInformation from "../../assets/img/svg/RequestInformation";
import EsignSvg from "../../assets/img/svg/EsignSvg";

const TemplateIcon = ({ type, color, ...rest }) => {
  switch (type) {
    case WHATSAPP:
      return <WhatsApp />;
    case SMS:
      return <MessageIcon />;

    case LETTER:
      return <LetterIcon />;

    case EMAIL:
      return <EmailIcon />;

    case INVOICE:
      return <InvoiceSvg />;

    case NOTE:
      return <Notesvg />;

    case ESIGN:
      return <EsignSvg size="22" />;

    // case WHATSAPP:
    //   return <p>w</p>;

    case PROMISE:
      return <PromiseToPay />;

    case CALLBACKLATER:
      return <CallBackLater {...rest} />;

    case MEETINGREQUEST:
      return <MeetingRequest />;

    case HOLDACTION:
      return <HoldActionTimeLineCard />;

    case CALLUNSUCCESSFUL:
      return <CallUnsuccessful />;

    case DISPUTE:
      return <Dispute />;

    case NEXTACTION:
      return <NextAction />;

    case REVIEWPROMISE:
      return <ReviewPromiseToPay />;

    case SKIPPEDTIMELINE:
      return <SkippedTimeLineCard width={22} height={22} />;

    case RELEASED:
      return <ReleaseTimeLineCard />;

    case CUSTOMERT_STATUS:
      return <CustomerStatus />;

    case BUSINESS_UNIT:
      return <BusinessUnit />;

    case WORKFLOW:
      return <Workflow />;

    case "SKIP_TRACE":
      return <SkipTrace />;

    case RM:
      return <RelManager />;

    case NOTES:
      return <NotesTimelineSvg />;

    case CONTACTS:
      return <ContactTimelineSvg />;

    case CUSTOMERPORTAL:
      return <PortalTLSvg />;

    case PORTAL_PAYMENT:
      return <PortalPayment />;
    case CALL_RECORDING:
      return <CallRecordingSvg />;

    case CUSTOMER_NAME_UPDATE:
      return <CustomerNameSvg />;
    case "FIELD_VISIT":
      return <LocationSvg color={color} />;
    case "NEXTACTIONRESCHEDULA":
      return <NextActionIcon />;
    case "REQUESTINFORMATION":
      return <RequestInformation />;
    default:
      return null;
  }
};

export default TemplateIcon;
