import DownArrow from "../../assets/img/svg/DownArrow";
import DocFileType from "../../assets/img/svg/DocFileType";
import Edit from "../../assets/img/svg/Template/Edit";
import { LabelMedium, LabelSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import React, { useContext, useMemo, useState } from "react";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";
import { IconButton } from "../IconButton";
import DocumentCard from "../DocumentCard/DocumentCard";
import { useParams, useSearchParams } from "react-router-dom";
import { Typography } from "../../components_v2";
import useFormat from "../../hooks/useFormat";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";
import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../constants";

const statusColor = {
  CCL: "#FD372A",
  PEN: "#FBBD04",
  COM: "#0FB158",
};
const statusMapping = {
  PEN: "Pending",
  CCL: "Cancelled",
  COM: "Complete",
};
function EsignListCard({
  setResource,
  status,
  setIsEdit,
  isEdits,
  templates,
  cby,
  n_via,
  ex_dt,
  sts,
  cus_txt,
  tem,
  to,
  sgn_tem,
  created_by,
  customerId,
  isList = true,
  children,
  ...props
}) {
  let {
    referenceData,
    users,
    currentOrganization,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const raisedBy = users.find((e) => e && e.id === cby);
  const customer = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const contactOptions = useMemo(() => {
    if (n_via === "email") {
      return _.get(customer, "data.doc.contacts", []).reduce((prev, curr) => {
        let { email = [] } = curr;
        let nestedContacts = email
          .map(({ status, email_status, ...nRest }) => {
            let designation =
              referenceData?.recipient_type?.find(
                (rf) => rf?.id === curr?.designation
              )?.label || "";

            return {
              label: `${curr.first_name} - ${designation} - ${nRest.value}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              email_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.email_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      }, []);
    } else if (n_via === "sms") {
      return _.get(customer, "data.doc.contacts", []).reduce((prev, curr) => {
        let { phone = [] } = curr;
        let nestedContacts = phone
          .map(({ status, sms_status, ...nRest }) => {
            let designation =
              referenceData?.recipient_type?.find(
                (rf) => rf?.id === curr?.designation
              )?.label || "";

            return {
              label: `${_.get(curr, "first_name", "")} - ${_.get(
                designation,
                "",
                ""
              )} - ${_.get(nRest, "country_code", "")} ${_.get(
                nRest,
                "value",
                ""
              )}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              sms_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.sms_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      }, []);
    }
    return [];
  }, [n_via, customer, referenceData]);

  const PhoneEmailValue =
    Array.isArray(to) &&
    to.length > 0 &&
    to
      .map((toObj) => {
        const match = contactOptions.find(
          (contact) =>
            contact.id === toObj.id ||
            contact.recipient_id === toObj.recipient_id
        );
        return match ? match.label : null;
      })
      .filter(Boolean)
      .join(", ");

  return (
    <>
      <div className="request_information_card">
        <div className="request_information">
          <div className="request_header">
            <div className="request_head_line">
              <LabelMedium>{_.get(props, "evt", "")}</LabelMedium>
            </div>
            {isList ? (
              <IconButton
                kind="tertiary"
                size="mini"
                onClick={() => {
                  setResource(props._id, "", true);
                }}
              >
                <Edit width={15} height={15} fill="#ADADAD" />
              </IconButton>
            ) : (
              <></>
            )}
          </div>

          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Raised By:
              </ParagraphMedium>
              <ParagraphMedium>
                {raisedBy && raisedBy.displayName ? raisedBy.displayName : ""}{" "}
                on{" "}
                {moment
                  .utc(props && props.cat)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .format(currentDefaultFormatDetails.date_format)}
              </ParagraphMedium>
            </div>
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Status :
              </ParagraphMedium>
              <div
                className="request_status"
                style={{
                  background: statusColor[sts] ? statusColor[sts] : "#FBBD04",
                }}
              ></div>
              <ParagraphMedium>
                {statusMapping[sts] || "Unknown"}{" "}
                {/* Use mapped status or fallback */}
              </ParagraphMedium>
            </div>
          </div>
          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                E-Signature Template:
              </ParagraphMedium>
              <ParagraphMedium>
                {_.get(templates, "data.data.docs", []).length > 0 && sgn_tem
                  ? _.get(templates, "data.data.docs", []).find(
                      (item) => item._id === sgn_tem
                    )?.name
                  : "-"}
              </ParagraphMedium>
            </div>
          </div>

          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Custom Text
              </ParagraphMedium>
              <ParagraphMedium>{cus_txt ? cus_txt : "-"}</ParagraphMedium>
            </div>
          </div>
          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Notification Via:
              </ParagraphMedium>
              <ParagraphMedium>{_.capitalize(n_via)}</ParagraphMedium>
            </div>
          </div>
          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Notificate Template
              </ParagraphMedium>
              <ParagraphMedium>
                {tem
                  ? _.get(templates, "data.data.docs", []).length > 0 && tem
                    ? _.get(templates, "data.data.docs", []).find(
                        (item) => item._id === tem
                      )?.name
                    : "-"
                  : "-"}
              </ParagraphMedium>
            </div>
          </div>

          <div className="request_header">
            <div className="request_head_line">
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                Notificate To
              </ParagraphMedium>
              <ParagraphMedium>
                {PhoneEmailValue ? PhoneEmailValue : ""}
              </ParagraphMedium>
            </div>
            {ex_dt ? (
              <div className="request_head_line">
                <ParagraphMedium $style={{ color: "#ADADAD" }}>
                  Expired Date :
                </ParagraphMedium>

                <ParagraphMedium>
                  {moment
                    .utc(ex_dt)
                    .tz(currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)}
                </ParagraphMedium>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {children ? (
          <div style={{ padding: "0px 10px" }}>{children}</div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default EsignListCard;
