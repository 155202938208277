import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const createReportService = async ({
  organization = null,
  ...templateData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/report-maintenance`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const copyReportService = async ({
  organization = null,
  copyId,
  ...templateData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/report-maintenance/${copyId}`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const updateReportService = async ({
  organization = null,
  reportId,
  ...templateData
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/report-maintenance/${reportId}`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

//Download setting ṭhe report

export const updateReportDownloadService = async ({
  organization = null,
  reportId,
  ...templateData
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/report-maintenance/${reportId}/download-setting`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const postAddReport = async ({
  organization,
  customerId,
  letterId,
  ...rest
}) => {
  return await axiosWithAuth.post(`${HOST}${organization}/email-report`, rest, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const editAddReportFn = async ({ organization, id, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/email-report/${id}`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const addReportDlv = async ({
  organization,
  customerId,
  letterId,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/report-delivery`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const updateReportDlv = async ({
  organization = null,
  reportId,
  ...templateData
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/report-delivery/${reportId}`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getReportDlvList = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/report-delivery`, {
    params: {
      page: pageIndex + 1,
      sort: sortBy,
      page_size: pageSize,
      ...filters,
    },
  });
};
