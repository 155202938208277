import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Input } from "baseui/input";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";

import { Icon, Typography } from "../../components_v2";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  deleteSavedCards,
  getGridStructure,
  getPaymentDatas,
  useCustomerPaymentDtl,
} from "../../services";
import CurrencyFormate from "../../components_v2/CurrencyFormate";
import { ADD_PAYMENT, PAYMENT_DRAWER } from "../../redux/actions";
import { CUSTOMER_OVERVIEW_, NEW } from "../../constants";
import _ from "lodash";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { ADD_PAYMENTS } from "../../providers/RBACProvider/permissionList";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { KIND, TextButton } from "../../components/TextButton";
import queryClient from "../../providers/queryClient";
import { toast } from "react-toastify";
import useFormat from "../../hooks/useFormat";
import columnMapper_V2 from "../../utils/mapper_v2";

const defaultData = [
  {
    firstName: "01-12-2023",
    lastName: "Instant Pay",
    age: 2400,
    visits: 100,
    status: "In Relationship",
    progress: 50,
  },
];

const columnHelper = createColumnHelper();

const Header = ({ label = "" }) => {
  const intl = useIntl();

  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: label })}
    </Typography>
  );
};

const Cell = ({ cellType, ...info }) => {
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  if (cellType === "CURRENCY") {
    const { value, currency } = info.getValue();
    return (
      <Typography
        type="p"
        subType="regular"
        className="mx-table-value"
        style={{ textAlign: "right", paddingRight: "5px" }}
      >
        <sup className="mx-text-currency">{currency}</sup>
        {CurrencyFormate(value)}
      </Typography>
    );
  }

  if (cellType === "DATEORGTZ") {
    const value = info.getValue();

    return (
      <Typography type="p" subType="regular" className="mx-table-header">
        {value
          ? moment
              .utc(value)
              .tz(currentDefaultFormatDetails.time_zone)
              .format(currentDefaultFormatDetails.date_format)
          : "-"}
      </Typography>
    );
  }

  return (
    <Typography type="p" subType="regular" className="mx-table-value">
      {_.capitalize(info.getValue())}
    </Typography>
  );
};

export default function COPaymentTable({ setDrawer }) {
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const { customerId } = useParams();
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const paymentCards = useMemo(() => {
    const cards = _.get(customerData, "data.doc.transaction_cards", []);

    return cards && Array.isArray(cards) ? cards : [];
  }, [customerData]);

  const invoices = useCustomerPaymentDtl({
    customerId: customerId,
    organization: currentOrganization,
    filters: {
      pgid: _.get(customerData, "data.doc.pgid", []),
    },
  });

  // const columns = useMemo(() => {
  //   const columns = [
  //     {
  //       accessorKey: "date",
  //       header: () => <Header label="date" />,
  //       cell: (info) => <Cell {...info} cellType="DATEORGTZ" />,
  //       width: 50,
  //     },
  //     {
  //       accessorKey: "status",
  //       header: () => <Header label="status" />,
  //       cell: (info) => <Cell {...info} cellType="" />,
  //       width: 100,
  //     },
  //     {
  //       accessorKey: "amount",
  //       header: () => <Header label="amount" />,
  //       cell: (info) => <Cell {...info} cellType="CURRENCY" />,
  //       width: 50,
  //     },
  //   ];
  //   return columns;
  // }, []);

  const gridStructure = useQuery(
    [`GRID_STRUCTURE_PAYMENT_CUSTOMER_LIST_${customerId}`],
    async () => {
      return await getGridStructure({
        organization: currentOrganization,
        menu: "customer_overview_payment_v2",
      });
    },
    {
      staleTime: Infinity, // Data is always considered fresh, no refetch
      cacheTime: 86400000, // Cache data for 24 hours (in milliseconds)
      refetchOnWindowFocus: false, // Avoid refetch on window focus
    }
  );

  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns:
        gridStructure.data &&
        gridStructure.data.data &&
        gridStructure.data.data.doc &&
        gridStructure.data.data.doc.table,
      isExpanded: false,
      isInvExpand: false,
      isExpandedHeader: false,
      isSelection: false,
    });
    return columns;
  }, [gridStructure.status]);

  const data = useMemo(() => {
    const data =
      invoices.data &&
      invoices.data.data &&
      invoices.data.data.docs &&
      Array.isArray(invoices.data.data.docs)
        ? invoices.data.data.docs
        : [];
    return data;
  }, [invoices.status, invoices.isFetching, invoices.isLoading]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
  });

  let paymentData = { actionType: NEW };
  const [isRemovePopoverOpen, setRemovePopoverOpen] = useState(false);
  const format = useFormat();
  const deleteSavedCardsMutation = useMutation(
    (id) =>
      deleteSavedCards({
        customerId: customerId,
        organization: currentOrganization,

        saveId: id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          } else {
            errorData = data.message;
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        // queryClient.invalidateQueries(`${REVIEW_PROMISE}${customerId}`);
      },
    }
  );

  return (
    <div className="co-payment-card" style={{ minHeight: "160px" }}>
      {/* SECTION HEADER */}
      <div className="co-payment-card_header">
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Typography type="h3">Payments</Typography>
          {paymentCards.length > 0 && (
            <StatefulPopover
              placement={PLACEMENT.bottom}
              triggerType={TRIGGER_TYPE.click}
              content={({ close }) => (
                <div
                  className="save_payment_card_wrapper_popover"
                  style={{
                    display: "flex",
                    width: "210px",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    backgroundColor: "white",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingLeft: "5px",
                    borderRadius: "5px",
                    gap: "10px",
                    paddingBottom: "10px",
                    maxHeight: "150px",
                    overflow: "scroll",
                  }}
                >
                  <Typography type="p" className="text-secondary">
                    Saved cards
                  </Typography>
                  {paymentCards.map((item) => (
                    <div
                      key={item.id}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div className="payment_card_con_popver">
                        <Typography type="p" className="text-primary">
                          {format.rd({
                            id: item.payment_config_id,
                            name: "online_payment_method",
                          })}
                        </Typography>
                      </div>
                      <div
                        className="payment_card_con_pover_content"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          <Typography
                            type="p"
                            className="regular"
                            subType="regular"
                          >
                            Card ends with
                          </Typography>
                          <Typography
                            type="p"
                            className="regular"
                            subType="medium"
                          >
                            {item.last4}
                          </Typography>
                        </div>

                        <StatefulPopover
                          placement={PLACEMENT.leftTop}
                          triggerType={TRIGGER_TYPE.click}
                          isOpen={isRemovePopoverOpen}
                          onClick={() => setRemovePopoverOpen(true)}
                          content={({ close: innerClose }) => (
                            <div
                              className="co_groping_delete_callback_popover"
                              key={item.id}
                            >
                              <div className="co_groping_delete_call_back_popover_body">
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <Icon icon="alert" color="#FD372A" />
                                  <Typography type="h4">
                                    Are You Sure?
                                  </Typography>
                                </div>

                                <Typography
                                  type="p"
                                  className="text-secondary"
                                  subtype="regular"
                                >
                                  This will permanently remove the card ends
                                  with {item?.last4}.
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <TextButton
                                  size="mini"
                                  kind={KIND.tertiary}
                                  type="button"
                                  onClick={() => {
                                    setRemovePopoverOpen(false);
                                    innerClose(); // Close inner popover
                                  }}
                                >
                                  <Typography
                                    type="h5"
                                    style={{ color: "#FD372A" }}
                                  >
                                    Cancel
                                  </Typography>
                                </TextButton>
                                <TextButton
                                  size="mini"
                                  kind={KIND.secondary}
                                  onClick={async () => {
                                    await deleteSavedCardsMutation.mutateAsync(
                                      item.id
                                    );
                                    // Handle card removal logic here
                                    setRemovePopoverOpen(false);
                                    innerClose(); // Close inner popover
                                    close(); // Close outer popover
                                  }}
                                  type="error"
                                >
                                  Confirm
                                </TextButton>
                              </div>
                            </div>
                          )}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setRemovePopoverOpen(true);
                            }}
                          >
                            <Typography type="p" className="text-error">
                              Remove
                            </Typography>
                          </div>
                        </StatefulPopover>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  padding: "2px",
                  borderRadius: "5px",

                  backgroundColor: "#EEEEEEEE",
                }}
              >
                <Icon icon="pay_card_outline" isPressable />
                {paymentCards.length > 1 && (
                  <Typography type="h4">+{paymentCards.length - 1}</Typography>
                )}
              </div>
            </StatefulPopover>
          )}
        </div>

        <div className="add_payment_icon_wraper">
          {currentDefaultFormatDetails.is_add_payment ? (
            <RBACWrapper role={ADD_PAYMENTS} type="PERMISSION">
              <Icon
                icon="plus"
                size={18}
                isPressable
                onClick={() => {
                  setDrawer(ADD_PAYMENT, paymentData);
                }}
              />
            </RBACWrapper>
          ) : (
            <></>
          )}

          <Icon
            icon="enlarge"
            size={18}
            isPressable
            onClick={() => {
              setDrawer(PAYMENT_DRAWER);
            }}
          />
        </div>
      </div>
      {/* SECTION HEADER */}

      <div className="co-invoice-table_wrapper">
        {/* TABLE HEADER */}
        <div className="co-table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="co-table_header-group">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  className="co-table_header-cell"
                  style={{ width: header.getSize() }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* TABLE HEADER */}

        {/* TABLE BODY */}
        <div className="co-table_body">
          {/* TABLE ROW */}
          {table.getRowModel().rows.map((row) => (
            <div key={row.id} className="co-payment-overview-table_body-group">
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  className="co-table_body-cell"
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ))}
            </div>
          ))}
          {/* TABLE ROW */}
        </div>
        {/* TABLE BODY */}
      </div>
    </div>
  );
}
