import React, { useContext } from "react";

import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  assignMulInvoicePauseAction,
  assignMulPauseAction,
  assignMultBatch,
} from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { TextArea } from "../../components/TextArea";
import { Typography } from "../../components_v2";
import _ from "lodash";
import queryClient from "../../providers/queryClient";
import { CHECK_MAINTENANCE } from "../../constants";

const CheckBulkPach = ({
  selectedCustomer = [],
  handleModal,
  toggleAllRowsSelected,
  isAllRowsSelected,
  queryFilter,
  selectedInvoice = [],
  skipBatch = null,
  selectAll = null,
  formTitle = null,
  actionType = null,
  isCheckSearch = {},
  nsfNACHA = null,
}) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    referenceData,
    refetch,
    space,
    appAccessRest,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;
  const { filters } = useSelector((s) => s.checkMaintenance.tableCurrentState);

  const { gridStructure } = useSelector((state) => state.checkMaintenance);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_skip_batch: true,
      select_match_record: false,
      ...(nsfNACHA?.length ? { is_nsf_fee: false } : {}),
    },
  });

  let { hold_reason } = watch();

  const assignMultiInvPause = useMutation(
    (relMang) =>
      assignMultBatch({
        ...relMang,
        action_type: actionType,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        handleModal();
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        queryClient.invalidateQueries(
          `${CHECK_MAINTENANCE}-${currentOrganization}`
        );
        reset();
        handleModal();
      },
    }
  );

  const onSubmit = async (data) => {
    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      // customerQueryFilter: queryFilter && queryFilter.display_name,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    let values = {
      ...data,
      hold_reason: getSelectValues(data.hold_reason),
      ...(nsfNACHA?.length ? { is_nsf_fee: data.is_nsf_fee } : {}),
      ...(data.hold_action_till
        ? {
            hold_action_till: moment
              .tz(
                `${data.hold_action_till.getFullYear()}-${
                  data.hold_action_till.getMonth() + 1
                }-${data.hold_action_till.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.select_match_record
        ? {
            // query: ,
            filter: newFilter ? newFilter : {},
            ...(isCheckSearch?.search_key &&
            isCheckSearch?.search_key.length > 0
              ? {
                  filter_type: _.get(isCheckSearch, "search_key[0].id"),
                  filter_value: isCheckSearch.search_debonce,
                }
              : {}),
          }
        : {
            ...(selectedInvoice &&
              selectedInvoice.length > 0 && { filter: selectedInvoice }),
          }),
    };

    await assignMultiInvPause.mutateAsync(values);
  };

  const intl = useIntl();
  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography type="h3" style={{ marginBottom: "10px" }}>
          {formTitle}
        </Typography>
        <Controller
          name="is_skip_batch"
          control={control}
          render={({ field }) => (
            <CheckBoxBaseweb {...field} checked={field.value}>
              <ParagraphSmall style={{ color: "#333860" }}>
                {skipBatch}
              </ParagraphSmall>
            </CheckBoxBaseweb>
          )}
        />
        {nsfNACHA?.length && (
          <Controller
            name="is_nsf_fee"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  {nsfNACHA}
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}

        <div style={{ marginTop: "15px" }}>
          <Controller
            control={control}
            name="cmnt"
            render={({ field }) => (
              <TextArea
                {...field}
                name={field.name}
                label={intl.formatMessage({
                  id: "user_comments",
                })}
                placeholder={intl.formatMessage({
                  id: "user_comments",
                })}
                size={SIZE.mini}
                value={field.value}
              />
            )}
          />
        </div>
        {isAllRowsSelected && (
          <Controller
            name="select_match_record"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  {selectAll}
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}
        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default CheckBulkPach;
