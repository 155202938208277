import { StatefulTooltip } from "baseui/tooltip";
import { Paragraph2, ParagraphSmall } from "baseui/typography";
import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

const EmailAndFTP = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  console.log("VALUE_DATA", value, row.original);

  let isMulti = Array.isArray(value);
  //
  let data;
  let maxyfiContextValue = useContext(MaxyfiContext);

  let originalData = row.original;

  const { referenceData } = maxyfiContextValue;

  // [...referenceData["business_unit_list"]] [...referenceData["client_group_list"]]

  let rdName = column && column?.rdName;

  if (props.rdName) {
    rdName = props.rdName;
  }

  let tootltipData = [];

  if (_.get(originalData, "typ", "") === "EML") {
    data = [...value];
    tootltipData = [...data];
  } else if (_.get(originalData, "typ", "") === "FTP") {
    const label = _.get(originalData, "serv_adr", "");

    data = label;
  } else {
    data = "-";
  }

  console.log("JKFHDKJHFKDHKDJ", data);

  return isMulti ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "10px",
      }}
    >
      {/* {visible ? ( */}
      <StatefulTooltip
        accessibilityType={"tooltip"}
        content={
          <div style={{ minWidth: "auto", maxWidth: "200px" }}>{data}</div>
        }
      >
        <ParagraphSmall
          $style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: alignment,
            maxWidth: "100px",
          }}
        >
          {data ? data : "-"}
        </ParagraphSmall>
      </StatefulTooltip>
      {data && data.length > 1 && _.get(originalData, "typ", "") === "EML" && (
        <div style={{}}>
          <StatefulTooltip
            accessibilityType={"tooltip"}
            content={`${tootltipData.join(", ").toString()}${"."}`}
          >
            <div
              style={{
                border: "1px solid #333860",
                width: "25px",
                height: "15px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#333860",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                +{data && data.length - 1}
              </div>
            </div>
          </StatefulTooltip>
        </div>
      )}
    </div>
  ) : (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ minWidth: "auto", maxWidth: "200px" }}>{data}</div>
      }
    >
      <ParagraphSmall
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: alignment,
          maxWidth: "150px",
        }}
      >
        {data ? data : "-"}
      </ParagraphSmall>
    </StatefulTooltip>
  );
};

export default EmailAndFTP;
