import React, { useContext, useEffect, useState } from "react";
import "../../layouts/SideBar/sideBar.css";
import { Avatar } from "baseui/avatar";
import Switch from "../../assets/img/svg/Switch";
import { LabelLarge } from "baseui/typography";
import { TextButton } from "../../components/TextButton";
import Plus from "../../assets/img/svg/Upload/Plus";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useNavigate } from "react-router-dom";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import MappingOrgList from "./MappingOrgList";
import Sync from "../../assets/img/svg/Sync";
import { useSelector } from "react-redux";
import { ADD_ORGANIZATION } from "../../providers/RBACProvider/permissionList";
import { Icon, Typography } from "../../components_v2";

export function OrgList() {
  let navigate = useNavigate();
  const [isCount, setIsCount] = useState(true);

  let maxyfiContextValue = useContext(MaxyfiContext);

  const { referenceData, restrictions, space } = maxyfiContextValue;

  useEffect(() => {
    if (space.org_count >= restrictions && restrictions.MULTORGACCESS) {
      setIsCount(false);
    }
  }, [space]);
  return (
    <div>
      <div>
        <LabelLarge padding="scale500" $style={{ textAlign: "center" }}>
          Switch Organization
        </LabelLarge>
      </div>
      <div className="sidebar-switch__organization-list_v2">
        {referenceData &&
          referenceData?.organizations &&
          referenceData?.organizations?.map((res) => (
            <MappingOrgList {...res} />
          ))}
      </div>
      <div className="sidebar-switch__bottom-btn">
        <RBACWrapper type="PERMISSION" role={ADD_ORGANIZATION}>
          <RBACWrapper type="LICENSE" enabled={isCount} placement="bottom">
            <TextButton
              fullWidth
              onClick={() => {
                navigate("/organization");
              }}
              size="mini"
            >
              <Plus /> Add Organization
            </TextButton>
          </RBACWrapper>
        </RBACWrapper>
      </div>
    </div>
  );
}

const OrgSwitch = () => {
  let { isActive } = useSelector((e) => e.organizationListReducer);

  let maxyfiContextValue = useContext(MaxyfiContext);

  const { currentDefaultFormatDetails, currentOrganization, referenceData } =
    maxyfiContextValue;

  const findCurrentOrgName = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  return (
    <div>
      <div className="avather_text_eraper">
        <Avatar
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                backgroundColor: "#F5F6FA",
                borderRadius: "3px",
              }),
            },
            Initials: {
              style: () => ({
                color: "#516beb",
                fontSize: "14px",
                fontWeight: "700",
              }),
            },
            Avatar: {
              style: ({ $theme }) => ({
                borderRadius: "3px",
              }),
            },
          }}
          name={findCurrentOrgName?.label}
          size="scale1000"
          src={
            currentDefaultFormatDetails && currentDefaultFormatDetails.org_logo
          }
        />
        <Typography type="h3" subType="medium">
          {findCurrentOrgName?.label}
        </Typography>
        <Icon icon="chevron_right" color="#ffffff" />
      </div>

      {isActive ? (
        <div className="sidebar-sync__cointainer">
          <Sync />
        </div>
      ) : (
        <></>
      )}

      <div className="sidebar-switch__icon">
        <Switch />
      </div>
    </div>
  );
};

export default OrgSwitch;
