import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useFilters,
  usePagination,
} from "react-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Input } from "baseui/input";
import { Search } from "baseui/icon";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { ParagraphSmall } from "baseui/typography";
import { useDebouncedCallback } from "use-debounce";

import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import TripleDot from "../../assets/img/svg/TripleDot";
import PaginationWraper from "../../components/Pagination/Pagination";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  getGridStructure,
  getHolidayBlackoutList,
  enableHolidayBlackout,
} from "../../services";
import columnMapper from "../../utils/mapper";
import Loader from "../../components/Loader";
import {
  EDIT_HOLIDAY_BLACKLIST,
  VIEW_HOLIDAY_BLACKLIST,
  VIEW_AUDIT_HOLIDAY_BLACKLIST,
  ENABLE_HOLIDAY_BLACKLIST,
  ENABLE_DISABLE_REPORT_DELIVERY_SETTINGS,
  EDIT_REPORT_DELIVERY_SETTINGS,
  VIEW_REPORT_DELIVERY_SETTINGS,
} from "../../providers/RBACProvider/permissionList";
import moment from "moment";
import ArenaMaxReport from "./ArenaMaxReport";
import { enableReportMain } from "../../services/roleMaintenance";
import { EDIT, REPORT_DELIVERY_SETTINGS_ } from "../../constants";
import { getReportDlvList } from "../../services/reportMaintanace";
import { Icon } from "../../components_v2";

const TableBody = ({ page, prepareRow, getTableBodyProps, setIsOpen }) => {
  const { currentOrganization } = useContext(MaxyfiContext);
  const queryClient = useQueryClient();

  const enableReportMainMutation = useMutation(
    (id) =>
      enableReportMain({
        organization: currentOrganization,
        id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context, error) => {
        queryClient.invalidateQueries(
          `${REPORT_DELIVERY_SETTINGS_}${currentOrganization}`
        );
      },
    }
  );

  const [isArena, setIsArena] = useState({
    isOpen: false,
    arenaId: null,
  });

  return (
    <>
      <div className="customer__tabel__body" {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          let today = moment();
          let dayDiffDate = moment(row.original.end_date);
          let findDayDiff = dayDiffDate.diff(today, "days");
          return (
            <React.Fragment key={rowProps.key}>
              <div
                key={index}
                className={`table-main__body-row toggle-btn__relate ${
                  row.isExpanded ? "expanded" : ""
                }`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <div
                      className="table-main__body-cell"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}

                <>
                  <div className="user-end_icon-wraper" style={{ width: "5%" }}>
                    <RBACWrapper
                      role={ENABLE_DISABLE_REPORT_DELIVERY_SETTINGS}
                      type="PERMISSION"
                    >
                      <div>
                        <Checkbox
                          checked={row.original.enb}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={() => {
                            enableReportMainMutation.mutateAsync(
                              row.original._id
                            );
                          }}
                          disabled={enableReportMainMutation.isLoading}
                        />
                      </div>
                    </RBACWrapper>

                    <StatefulPopover
                      content={({ close }) => (
                        <div className="user-popover__btn">
                          {/* {findDayDiff >= 0 && ( */}
                          <RBACWrapper
                            role={EDIT_REPORT_DELIVERY_SETTINGS}
                            type="PERMISSION"
                          >
                            <ParagraphSmall
                              $style={{
                                cursor: "pointer",
                                padding: "5px 30px",
                                textAlign: "center",
                                borderBottom: "0.5px solid #CDCED9",
                              }}
                              onClick={() => {
                                setIsOpen({
                                  isOpen: true,
                                  data: row.original,
                                  type: EDIT,
                                });
                                close();
                              }}
                            >
                              Edit
                            </ParagraphSmall>
                          </RBACWrapper>
                          {/* )} */}

                          <RBACWrapper
                            type="PERMISSION"
                            role={VIEW_REPORT_DELIVERY_SETTINGS}
                          >
                            <div>
                              <ParagraphSmall
                                $style={{
                                  cursor: "pointer",
                                  padding: "5px 30px",
                                  textAlign: "center",
                                  borderBottom: "0.5px solid #CDCED9",
                                }}
                                onClick={() => {
                                  setIsArena({
                                    isOpen: true,
                                    arenaId: row?.original?._id,
                                  });
                                  close();
                                }}
                              >
                                Execution Info
                              </ParagraphSmall>
                            </div>
                          </RBACWrapper>
                        </div>
                      )}
                      accessibilityType={"tooltip"}
                      triggerType={TRIGGER_TYPE.hover}
                      overrides={{
                        Inner: {
                          style: () => ({
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                            background: "#ffffff",
                            border: "0.5px solid #CDCED9",
                          }),
                        },
                        Body: {
                          style: () => ({
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                            top: "-10px",
                          }),
                        },
                      }}
                    >
                      <div className="report_dot">
                        <Icon icon="triple_dot" />
                      </div>
                    </StatefulPopover>
                  </div>
                </>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {isArena.isOpen && (
        <ArenaMaxReport isArena={isArena} setIsArena={setIsArena} />
      )}
    </>
  );
};

const ReportDeliverySettingsTable = ({ setIsOpen }) => {
  const [filterSearch, setFilterSearch] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  // const [isOpen, setIsOpen] = React.useState(false);
  const [isAudit, setIsAudit] = useState({});
  let { currentOrganization } = useContext(MaxyfiContext);
  const [columnData, setColumnData] = useState([]);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortBy, setQuerySortBy] = useState("-_id");
  const [queryFilter, setQueryFilter] = useState({});
  const [width, setWidth] = useState(0);

  const debounceFilter = useDebouncedCallback((filter) => {
    setFilterSearch(filter);
  }, 1000);

  let ref = useRef();

  let globalFilter = { search: filterSearch };

  const gridStructureQuery = useQuery(
    ["GRID_STRUCTURE_REPORT_DELIVERY_SETTINGS"],
    async () =>
      await getGridStructure({
        organization: currentOrganization,
        menu: "report_delivery_settings",
      }),
    {
      staleTime: 86400000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data, isFetching, isFetched, isError, isLoading, isRefetching } =
    useQuery(
      [
        `${REPORT_DELIVERY_SETTINGS_}${currentOrganization}`,
        {
          pageIndex: queryPageIndex,
          pageSize: queryPageSize,
          sortBy: querySortBy,
          // filters: {
          //   ...globalFilter,
          // },
        },
      ],
      async ({ queryKey }) => {
        let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
        return await getReportDlvList({
          organization: currentOrganization,
          pageIndex,
          sortBy,
          pageSize,
          filters,
        });
      },
      {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        enabled: gridStructureQuery.isSuccess,
      }
    );

  useEffect(() => {
    if (
      gridStructureQuery.data &&
      gridStructureQuery.data.data &&
      gridStructureQuery.data.data.doc
    ) {
      setColumnData(gridStructureQuery.data.data.doc.table);
    }
  }, [gridStructureQuery.isLoading, gridStructureQuery.data]);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
    }
  }, [ref]);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: false,
      isExpandedHeader: false,
      width: width - 150,
    });
    return columns;
  }, [columnData]);

  const tabledata = useMemo(
    () =>
      isFetched && !isError && data && data.data && data.data.docs
        ? data.data.docs
        : [],
    [isFetching, data, isRefetching]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        isFetched && !isError && data && data.data && data.data.totalPages
          ? data.data.totalPages
          : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  );

  useEffect(() => {
    if (pageIndex == queryPageIndex) {
      gotoPage(0);
    }
    setQueryPageIndex(pageIndex);
    setQueryPageSize(pageSize);
    setQuerySortBy(
      sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : "" + sortBy[0].id
        : "-_id"
    );
    let filterObj = {};
    filters.map((e) => {
      if (e.value) {
        if (Array.isArray(e.value)) {
          filterObj[e.id] = e.value.filter((val) => val);
        } else {
          filterObj[e.id] = e.value;
        }
      }
    });
    setQueryFilter(filterObj);
  }, [pageIndex, pageSize, sortBy, filters]);

  return (
    <>
      <div
        className="table-main user-table__main"
        {...getTableProps()}
        style={{ overflow: "hidden" }}
      >
        <div className="table-main-container" ref={ref}>
          <div className="table-main__head">
            {headerGroups.map((headerGroup) => (
              <div
                className="table-main__head-container"
                {...headerGroup.getHeaderGroupProps()}
              >
                <div className="table-main__head-row">
                  {headerGroup.headers.map((column, index) => (
                    <div className="table-main__head-cell-wrapper">
                      <div
                        className="table-main__head-cell"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {isLoading ? (
            <Loader />
          ) : tabledata && tabledata.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <TableBody
              page={page}
              prepareRow={prepareRow}
              getTableBodyProps={getTableBodyProps}
              setIsOpen={setIsOpen}
            />
          )}
        </div>
      </div>
      <PaginationWraper
        pageSize={pageSize}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default ReportDeliverySettingsTable;
