import { ParagraphMedium } from "baseui/typography";
import React, { useContext } from "react";
import _ from "lodash";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

const PaymentOverviewGrid = (props) => {
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);

  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  value = _.get(original, accessor, "");

  //

  const findPaymentStatus = referenceData?.payment_status?.find(
    ({ id }) => id === value
  );

  return (
    <div style={{ textAlign: alignment, width: "100%" }}>
      <ParagraphMedium
        style={{
          color: `${
            findPaymentStatus && findPaymentStatus?.id === "success"
              ? "#0FB158"
              : findPaymentStatus && findPaymentStatus?.id === "failed"
              ? "#FD372A"
              : ""
          }`,
        }}
      >
        {findPaymentStatus && findPaymentStatus?.label
          ? findPaymentStatus?.label
          : " "}
      </ParagraphMedium>
    </div>
  );
};

export default PaymentOverviewGrid;
