import { Controller, useForm, useWatch } from "react-hook-form";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import React, { useContext, useMemo, useRef, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { SelectBox } from "../../components/SelectBox";
import { TextButton, SIZE, KIND } from "../../components/TextButton";
import { useIntl } from "react-intl";
import { TextArea, SIZE as TASize } from "../../components/TextArea";
import { ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import {
  addRequestInformation,
  updateRequestInformation,
  customerInvoiceOverview,
  getHolidayBlackoutHolidayDate,
  addEsignAction,
  updateEsignAction,
} from "../../services";
import { getSubCategory } from "../../services/documentSystem";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import {
  requestInformationAttachment,
  requestInformationRemoveAttachment,
  requestInformationUpdateAttachment,
  requestInformationReset,
} from "../../redux/actions";
import { Plus } from "baseui/icon";
import EmailAttachment from "../../components/EmailAttachment/EmailAttachment";
import {
  ADD_LIST_VALUES,
  ASSIGN_RI_TO_CLIENT,
  CLIENT_PORTAL_ACCESS,
} from "../../providers/RBACProvider/permissionList";
import "quill-mention";
import _ from "lodash";
import "quill-mention/dist/quill.mention.css";
import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../constants";
import { Icon, Typography } from "../../components_v2";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";

function EsignForm({
  isEdit = false,
  defaultValues = {},
  resourceId,
  sub_category = "",
  customerId,
  templates,
  ...props
}) {
  const dispatch = useDispatch();

  const customer = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  let {
    currentOrganization,
    users,
    referenceData,
    currentDefaultFormatDetails,
    userInfo,
    space,
    myTeam,
  } = useContext(MaxyfiContext);

  useEffect(() => {
    return () => {
      dispatch(requestInformationReset());
    };
  }, []);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
  });

  const intl = useIntl();

  const addEsignMutation = useMutation(
    (contactData) =>
      addEsignAction({
        ...contactData,
        organization: currentOrganization,
        customerId: customerId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        // queryClient.invalidateQueries(
        //   `customer-${customerId}-request-information`
        // );
        // queryClient.invalidateQueries([`${CO_TIMELINE_}${customerId}`]);
        props.reset();
      },
    }
  );

  const updateEsignMutation = useMutation(
    (contactData) =>
      updateEsignAction({
        ...contactData,
        organization: currentOrganization,
        customerId: customerId,
        resourceId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        // reset();
        // setValue("comment", "");
        // dispatch(requestInformationReset());
        // queryClient.invalidateQueries(
        //   `customer-${customerId}-request-information`
        // );
        // queryClient.invalidateQueries(
        //   `customer-${customerId}-request-${resourceId}`
        // );
        // queryClient.invalidateQueries([`${CO_TIMELINE_}${customerId}`]);
      },
    }
  );
  const addValuePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  const onSubmit = async (data) => {
    let {
      evt,
      sgn_tem,
      cus_txt = "",
      n_via = "",
      to = [],
      ex_dt = "",
      tem = [],
      sts,
    } = data;
    let recipients = [];
    if (Array.isArray(to) && to?.length > 0) {
      to?.map((e) => {
        recipients?.push({ id: e?.id, recipient_id: e?.recipient_id });
      });
    }

    let values = {
      ...(!isEdit ? { evt: getSelectValues(evt, false) } : {}),

      sgn_tem: getSelectValues(sgn_tem, false),
      ...(sts
        ? {
            sts: getSelectValues(sts),
          }
        : {}),
      ...(cus_txt
        ? {
            cus_txt: cus_txt,
          }
        : {}),
      ...(!isEdit ? { n_via: n_via } : {}),
      ...(!isEdit ? { to: recipients } : {}),
      ...(!isEdit ? { tem: getSelectValues(tem, false) } : {}),

      ...(ex_dt
        ? {
            ex_dt: moment(ex_dt).utc().valueOf(),
          }
        : {}),
    };

    if (!isEdit) {
      await addEsignMutation.mutateAsync(values);
    } else {
      await updateEsignMutation.mutateAsync(values);
    }
  };

  const nViaWatch = watch("n_via"); // Watch for the current value of "n_via"
  const contactOptions = useMemo(() => {
    if (nViaWatch === "email") {
      return _.get(customer, "data.doc.contacts", []).reduce((prev, curr) => {
        let { email = [] } = curr;
        let nestedContacts = email
          .map(({ status, email_status, ...nRest }) => {
            let designation =
              referenceData?.recipient_type?.find(
                (rf) => rf?.id === curr?.designation
              )?.label || "";

            return {
              label: `${curr.first_name} - ${designation} - ${nRest.value}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              email_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.email_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      }, []);
    } else if (nViaWatch === "sms") {
      return _.get(customer, "data.doc.contacts", []).reduce((prev, curr) => {
        let { phone = [] } = curr;
        let nestedContacts = phone
          .map(({ status, sms_status, ...nRest }) => {
            let designation =
              referenceData?.recipient_type?.find(
                (rf) => rf?.id === curr?.designation
              )?.label || "";

            return {
              label: `${curr.first_name} - ${designation} - ${nRest.country_code} ${nRest.value}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              sms_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.sms_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      }, []);
    }
    return [];
  }, [nViaWatch, customer, referenceData]);

  const templateOptions = useMemo(() => {
    return _.get(templates, "data.data.docs", []) &&
      Array.isArray(_.get(templates, "data.data.docs", []))
      ? _.get(templates, "data.data.docs", []).map((itm) => ({
          ...itm,
          label: `${itm.name} (${
            itm.language
              ? itm?.language?.toLocaleUpperCase()
              : "en".toLocaleUpperCase()
          })`,
          id: itm._id,
        }))
      : [];
  }, [templates.isFetching]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="clarification_form">
        <Controller
          name="evt"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              requiredAstric={true}
              clearable={false}
              name={field.name}
              size={SIZE.mini}
              label={intl.formatMessage({
                id: `event`,
              })}
              placeholder={intl.formatMessage({
                id: "event",
              })}
              error={errors[field.name] && errors[field.name].message}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              disabled={isEdit}
              options={referenceData["e_sign_event"]}
              creatable={addValuePermission ? true : false}
            />
          )}
        />

        <Controller
          name="sgn_tem"
          rules={{ required: "Required" }}
          control={control}
          render={({ field }) => (
            <SelectBox
              name={field.name}
              requiredAstric={true}
              label={intl.formatMessage({
                id: "e_signature_template",
              })}
              placeholder={intl.formatMessage({
                id: "e_signature_template",
              })}
              error={errors[field.name] && errors[field.name].message}
              size={SIZE.mini}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                Array.isArray(templateOptions) &&
                templateOptions.filter((item) => item.type === "e_sign")
              }
            />
          )}
        />
      </div>
      {isEdit ? (
        <div style={{ width: "200px" }}>
          <Controller
            name="sts"
            rules={{ required: "Required" }}
            control={control}
            render={({ field }) => (
              <SelectBox
                {...field}
                clearable={false}
                name={field.name}
                size={SIZE.mini}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: `status`,
                })}
                placeholder={intl.formatMessage({
                  id: "status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["e_sign_status"]}
              />
            )}
          />
        </div>
      ) : (
        <></>
      )}

      <Controller
        name="cus_txt"
        control={control}
        render={({ field }) => (
          <TextArea
            {...field}
            fullWidth
            name={field.name}
            error={errors[field.name] && errors[field.name].message}
            label={intl.formatMessage({
              id: "custom_text",
            })}
            placeholder={intl.formatMessage({
              id: "custom_text",
            })}
            value={field.value}
            size={TASize.mini}
          />
        )}
      />

      <div style={{ width: "250px" }}>
        <Controller
          name="ex_dt"
          control={control}
          render={({ field }) => (
            <DateRangeSelect
              clearable={false}
              size={SIZE.mini}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "expiry_date",
              })}
              placeholder={intl.formatMessage({
                id: "expiry_date",
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
              minDate={new Date()}
            />
          )}
        />
      </div>
      {!isEdit && (
        <>
          <Typography type="h4" className="regular" subType="regular">
            Notify Via
          </Typography>

          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              gap: "20px",
            }}
          >
            <Controller
              name="n_via"
              rules={{ required: "Required" }}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={field.value}
                  align={ALIGN.horizontal}
                  onChange={(e) => {
                    // Call field's onChange handler to update the radio value
                    field.onChange(e);

                    // Update the "to" and "tem" fields
                    setValue("to", []); // Assuming you're using react-hook-form's setValue
                    setValue("tem", []);
                  }}
                >
                  <Radio
                    value="email"
                    overrides={{
                      Label: {
                        style: ({ $theme }) => ({
                          fontWeight: 400, // Set font weight to 300
                        }),
                      },
                      RadioMarkOuter: {
                        style: ({ $theme }) => ({
                          borderWidth: "2px", // Customize border width
                          borderColor: "rgb(153, 153, 153)",
                        }),
                      },
                    }}
                  >
                    Email
                  </Radio>
                  <Radio
                    value="sms"
                    overrides={{
                      Label: {
                        style: ({ $theme }) => ({
                          fontWeight: 400, // Set font weight to 300
                        }),
                      },
                      RadioMarkOuter: {
                        style: ({ $theme }) => ({
                          borderWidth: "2px", // Customize border width
                          borderColor: "rgb(153, 153, 153)",
                        }),
                      },
                    }}
                  >
                    SMS
                  </Radio>
                </RadioGroup>
              )}
            />
            {nViaWatch === undefined && (
              <div style={{ color: "red", marginTop: "8px" }}>
                {_.get(errors, "n_via.message", "")}
              </div>
            )}
          </div>
        </>
      )}

      {nViaWatch && !isEdit ? (
        <div style={{ width: "200px" }}>
          <Controller
            name="tem"
            rules={{ required: "Required" }}
            control={control}
            render={({ field }) => (
              <SelectBox
                {...field}
                clearable={false}
                name={field.name}
                size={SIZE.mini}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: `template`,
                })}
                placeholder={intl.formatMessage({
                  id: "template",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={
                  Array.isArray(templateOptions) &&
                  templateOptions.filter((item) => item.type === watch("n_via"))
                }
              />
            )}
          />
        </div>
      ) : (
        <></>
      )}
      {nViaWatch && !isEdit ? (
        <Controller
          name="to"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size="mini"
              {...field}
              name={field.name}
              requiredAstric={true}
              noResultMessage={`${"Phone Number is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Phone Number"}`}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "recipients",
              })}
              placeholder={intl.formatMessage({
                id: "recipients",
              })}
              value={field.value}
              multi
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={contactOptions}
            />
          )}
        />
      ) : (
        <></>
      )}

      <div className="request_flex">
        <TextButton
          size="mini"
          kind={KIND.tertiary}
          onClick={() => props.reset()}
          type="button"
        >
          Cancel
        </TextButton>
        <TextButton size="mini" type="submit" kind={KIND.primary}>
          Save
        </TextButton>
      </div>
    </form>
  );
}

export default EsignForm;
