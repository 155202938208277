import {
  CUSTOMER_CONTACT_ADD_ADHOC_UPLOAD_DATA,
  CUSTOMER_CONTACT_UPDATE_MAP_FIELDS,
  CUSTOMER_CONTACT_UPDATE_FORMAT_DATA,
  CUSTOMER_CONTACT_UPDATE_DEFAULT_DATA,
  CUSTOMER_CONTACT_UPDATE_SYSTEM_FIELDS,
  CUSTOMER_CONTACT_UPDATE_TEMPLATE_DATA_LIST,
  CUSTOMER_CONTACT_INITIALSTATE_STATE,
  CUSTOMER_CONTACT_ADHOC_REFETCH,
  CUSTOMER_CONTACT_SET_AU_FILTER,
  CUSTOMER_CONTACT_ADHOC_FILTER,
  CUSTOMER_CONTACT_VALIDATION_ADHOC_FILTER,
  CUSTOMER_CONTACT_MAP_BASED_ON,
  CUSTOMER_CONTACT_BUSINESS_UNIT_DATA,
  CUSTOMER_CONTACT_FORMULA_FIELDS,
  CUSTOMER_CONTACT_DELETE_FORMULA_FIELDS,
  CUSTOMER_CONTACT_UPDATE_FORMULA_FIELDS,
  CUSTOMER_CONTACT_ORG_DTL,
  CUSTOMER_CONTACT_BUSINESS_LOACTION_DATA,
  CONTACT_UPDATE_CONTACT_INDEX,
} from "./action";
import { alphabetize } from "../../utils/alphabetize";
import moment from "moment";
import { Parser } from "hot-formula-parser";
import { tokenize } from "excel-formula-tokenizer";
import setSelectValues from "../../utils/setSelectValues";
import { formulas } from "../../utils/formulaObj";
import _ from "lodash";

const cusNameRmv = ["contact_first_name", "customer_name", "contact_name"];

const conditions = [
  {
    trigger: "contact_first_name",
    toRemove: ["customer_name", "contact_name"],
  },
  {
    trigger: "customer_name",
    toRemove: ["contact_first_name", "contact_name"],
  },
  {
    trigger: "contact_name",
    toRemove: ["contact_first_name", "customer_name"],
  },
];

function updateRequiredFields(mappedField, requiredField) {
  console.log("UPDATED_MAP", mappedField, requiredField);
  const mappedFieldsSet = new Set(
    mappedField.map((field) => field.system_field)
  );
  const requiredFieldsSet = new Set(requiredField.map((field) => field.id));

  // const invAmountMap = new Map(
  //   invAmount.map((e) => [e, _.startCase(e.replaceAll("_", " "))])
  // );

  const cusNameMap = new Map(
    cusNameRmv.map((e) => [e, _.startCase(e.replaceAll("_", " "))])
  );

  function addField(system_column, id, isMapped = false) {
    requiredField.push({ system_column, id, isMapped });
  }

  function removeFieldById(id) {
    const index = requiredField.findIndex(({ id: fieldId }) => fieldId === id);
    if (index !== -1) requiredField.splice(index, 1);
  }

  // function handleInvAmountFields(trigger, ...fieldsToRemove) {
  //   if (
  //     requiredFieldsSet.has(trigger) &&
  //     fieldsToRemove.every((field) => !requiredFieldsSet.has(field))
  //   ) {
  //     removeFieldById(trigger);
  //     const mappedInvAmount = invAmount.filter(
  //       (e) => e !== trigger && mappedFieldsSet.has(e)
  //     );
  //     if (mappedInvAmount.length) {
  //       addField(
  //         invAmountMap.get(mappedInvAmount[0]),
  //         mappedInvAmount[0],
  //         true
  //       );
  //     } else {
  //       invAmount.forEach((field) => addField(invAmountMap.get(field), field));
  //     }
  //   }
  // }

  function handleNameValidation(trigger, ...fieldsToRemove) {
    console.log("VALIDATION_DATE", trigger);
    console.log("VALIDATION_DATE2", trigger, fieldsToRemove);
    if (
      requiredFieldsSet.has(trigger) &&
      fieldsToRemove.every((field) => !requiredFieldsSet.has(field))
    ) {
      removeFieldById(trigger);
      const mappedInvAmount = cusNameRmv.filter(
        (e) => e !== trigger && mappedFieldsSet.has(e)
      );
      if (mappedInvAmount.length) {
        addField(cusNameMap.get(mappedInvAmount[0]), mappedInvAmount[0], true);
      } else {
        cusNameRmv.forEach((field) => addField(cusNameMap.get(field), field));
      }
    }
  }

  handleNameValidation("contact_name", "customer_name", "contact_first_name");
  handleNameValidation("contact_first_name", "customer_name", "contact_name");
  handleNameValidation("customer_name", "contact_first_name", "contact_name");

  requiredField.forEach((field) => {
    if (mappedFieldsSet.has(field.id)) field.isMapped = true;
  });

  conditions.forEach(({ trigger, toRemove }) => {
    if (mappedFieldsSet.has(trigger)) {
      toRemove.forEach((idToRemove) => {
        const index = requiredField.findIndex(
          (field) => field.id === idToRemove && !field.isMapped
        );
        if (index !== -1) requiredField.splice(index, 1);
      });
    }
  });
}

const initialState = {
  mappingFieldList: [],
  defaultSystemFields: [],
  defaultFields: [],
  requiredFields: [],
  systemFields: [],
  requiredDefaultFields: [],
  requiredFormatFields: [],
  adhocRefetchActions: 0,
  validDefaultFields: [],
  tableCurrentState: {
    filters: {},
    formFilters: [
      {
        id: [],
      },
    ],
  },
  adhocFilter: false,
  defaultRequiredFields: [],
  fieldMessage: [],
  mapBasedOn: [],
  businessUnitRd: [],
  customerDtl: {},
  businessLoactionRd: [],
  formulaVariables: [],
};

const customerContactUploadReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case CUSTOMER_CONTACT_ADHOC_FILTER: {
      return {
        ...state,
        adhocFilter: payload,
      };
    }
    case CUSTOMER_CONTACT_ADD_ADHOC_UPLOAD_DATA:
      /* getting the mapping system fields value */
      if (payload.systemFields) {
        const requiredFields = [];
        payload.systemFields.map(({ required, system_column, id }) => {
          if (required == true) {
            requiredFields.push({ system_column, id, isMapped: false });
          }
        });
        payload.requiredFields = [...requiredFields];

        payload.defaultSystemFields = payload.systemFields;
      }
      /* set value of unmapped default fieds  */
      if (payload.defaultFields) {
        payload.requiredDefaultFields = [...payload.defaultFields];
      }

      if (payload.mappingFieldList) {
        payload.mappingFieldList = payload.mappingFieldList.map((e, i) => {
          return {
            index: alphabetize[i],
            upload_field: e.upload_field,
            first_row_data: e.first_row_data,
            format: e.format,
            system_field: e.system_field,
            contact_index: e.contact_index,
            module: e.module,
          };
        });
      }

      return {
        ...state,
        ...payload,
      };

    // case UPDATE_MAP_FIELDS:

    //   state.systemColumn[payload.index].mapped_field = payload.mapField;
    //   state.systemColumn[payload.index].data =
    //     state.firstRowData[payload.mapField];
    //   const systemColumn = [...state.systemColumn];
    //   return {
    //     ...state,
    //     systemColumn: [...systemColumn],
    //   };

    case CUSTOMER_CONTACT_UPDATE_FORMAT_DATA:
      /* formate value  */
      if (!state.mappingFieldList[payload.index].format) {
        const formatSelectIndex = state.requiredFormatFields.findIndex(
          ({ id, isSelected }) =>
            id == state.mappingFieldList[payload.index].system_field &&
            isSelected == false
        );
        state.requiredFormatFields[formatSelectIndex].isSelected = true;
      }

      state.mappingFieldList[payload.index].format = payload.formatData;

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
      };

    case CUSTOMER_CONTACT_UPDATE_SYSTEM_FIELDS: {
      const cusNameRmv = [
        "contact_name",
        "contact_first_name",
        "customer_name",
      ];
      const prevSystemField =
        state.mappingFieldList[payload.index].system_field;
      if (prevSystemField === payload.systemField) {
        return {
          ...state,
        };
      }

      let newRequiredFields = [...state.requiredFields];

      // if (payload.type == "clear") {
      let checkDueReqFields = state.requiredFields.find(
        ({ id }) => id == "due_amount"
      );
      let checkInvDueReqFields = state.requiredFields.find(
        ({ id }) => id == "invoice_total_amount"
      );

      let customerName = state.requiredFields.find(
        ({ id }) => id == "customer_name"
      );
      let contactFirstName = state.requiredFields.find(
        ({ id }) => id == "contact_first_name"
      );

      let contactName = state.requiredFields.find(
        ({ id }) => id == "contact_name"
      );

      // if (
      //   customerName &&
      //   !contactFirstName &&
      //   !contactName &&
      //   state.mappingFieldList[payload.index].system_field == "customer_name"
      // ) {
      //   let findIndexOfInvAmt = state.requiredFields.findIndex(
      //     ({ id }) => id === "customer_name"
      //   );

      //   state.requiredFields.splice(findIndexOfInvAmt, 1);

      //   let fiterInvAmt = cusNameRmv.filter((e) => e !== "customer_name");

      //   let findMapInvAmt = fiterInvAmt.filter((value) =>
      //     state.mappingFieldList.some((item) => item.system_field === value)
      //   );

      //   if (findMapInvAmt.length > 0) {
      //     state.requiredFields.push({
      //       system_column: _.startCase(
      //         findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
      //       ),
      //       id: findMapInvAmt && findMapInvAmt[0],
      //       isMapped: true,
      //     });
      //   } else {
      //     state.requiredFields.push({
      //       system_column: "Consumer Name",
      //       id: "customer_name",
      //       isMapped: false,
      //     });
      //     state.requiredFields.push({
      //       system_column: "Contact First Name",
      //       id: "contact_first_name",
      //       isMapped: false,
      //     });
      //     state.requiredFields.push({
      //       system_column: "Contact Name",
      //       id: "contact_name",
      //       isMapped: false,
      //     });
      //   }
      // }

      if (
        contactFirstName &&
        !contactName &&
        // !customerName &&
        state.mappingFieldList[payload.index].system_field ==
          "contact_first_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "contact_first_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let fiterInvAmt = cusNameRmv.filter((e) => e !== "contact_first_name");

        let findMapInvAmt = fiterInvAmt.filter((value) =>
          state.mappingFieldList.some((item) => item.system_field === value)
        );

        if (findMapInvAmt.length > 0) {
          state.requiredFields.push({
            system_column: _.startCase(
              findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
            ),
            id: findMapInvAmt && findMapInvAmt[0],
            isMapped: true,
          });
        } else {
          // state.requiredFields.push({
          //   system_column: "Consumer Name",
          //   id: "customer_name",
          //   isMapped: false,
          // });
          state.requiredFields.push({
            system_column: "Contact First Name",
            id: "contact_first_name",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Contact Name",
            id: "contact_name",
            isMapped: false,
          });
        }
      }

      if (
        contactName &&
        !contactFirstName &&
        // !customerName &&
        state.mappingFieldList[payload.index].system_field == "contact_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "contact_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let fiterInvAmt = cusNameRmv.filter((e) => e !== "contact_name");

        let findMapInvAmt = fiterInvAmt.filter((value) =>
          state.mappingFieldList.some((item) => item.system_field === value)
        );

        if (findMapInvAmt.length > 0) {
          state.requiredFields.push({
            system_column: _.startCase(
              findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
            ),
            id: findMapInvAmt && findMapInvAmt[0],
            isMapped: true,
          });
        } else {
          // state.requiredFields.push({
          //   system_column: "Consumer Name",
          //   id: "customer_name",
          //   isMapped: false,
          // });
          state.requiredFields.push({
            system_column: "Contact First Name",
            id: "contact_first_name",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Contact Name",
            id: "contact_name",
            isMapped: false,
          });
        }
      }

      if (
        !contactFirstName &&
        customerName &&
        state.mappingFieldList[payload.index].system_field == "customer_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "customer_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "contact_first_name"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "Contact First Name",
            id: "contact_first_name",
            isMapped: true,
          });
        } else {
          // state.requiredFields.push({
          //   system_column: "Customer Name",
          //   id: "customer_name",
          //   isMapped: false,
          // });
          state.requiredFields.push({
            system_column: "Contact First Name",
            id: "contact_first_name",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Contact Name",
            id: "contact_name",
            isMapped: false,
          });
        }
      }

      if (
        !checkDueReqFields &&
        checkInvDueReqFields &&
        state.mappingFieldList[payload.index].system_field ==
          "invoice_total_amount"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "invoice_total_amount"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "due_amount"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
        }
      }

      if (
        checkDueReqFields &&
        !checkInvDueReqFields &&
        state.mappingFieldList[payload.index].system_field == "due_amount"
      ) {
        let findDueAmt = state.requiredFields.findIndex(
          ({ id }) => id === "due_amount"
        );

        state.requiredFields.splice(findDueAmt, 1);

        let findMapInvAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "invoice_total_amount"
        );

        if (findMapInvAmt) {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
        }
      }
      // }

      if (prevSystemField) {
        const preSystemFieldIndex = state.systemFields.findIndex(
          (sys) => prevSystemField == sys.id
        );
        const preSystemFieldConfig = state.systemFields[preSystemFieldIndex];
        if (preSystemFieldConfig && preSystemFieldConfig.required) {
          // state.requiredFields.push(preSystemFieldConfig);

          let requiredFieldIndex = state.requiredFields.findIndex(
            ({ id }) => id == prevSystemField
          );

          if (requiredFieldIndex != -1) {
            state.requiredFields[requiredFieldIndex].isMapped = false;
          }
        }

        if (preSystemFieldConfig && preSystemFieldConfig.format) {
          let requiredFieldIndex = state.requiredFormatFields.findIndex(
            ({ id }) => id == prevSystemField
          );

          if (requiredFieldIndex != -1) {
            state.requiredFormatFields.splice(requiredFieldIndex, 1);
          }
        }

        state.systemFields[preSystemFieldIndex].isSelected = false;
      }

      state.mappingFieldList[payload.index].system_field = payload.systemField;
      state.mappingFieldList[payload.index].format = null;

      const requiredFieldIndex = state.requiredFields.findIndex((req) => {
        return req.id == payload.systemField;
      });
      if (requiredFieldIndex != -1) {
        state.requiredFields[requiredFieldIndex].isMapped = true;
      }

      const selectSystemField =
        state &&
        state.systemFields &&
        state.systemFields.findIndex(
          (select) => select.id == payload.systemField
        );

      state.systemFields[selectSystemField].isSelected = true;

      if (state.mappingFieldList[payload.index].system_field === "due_amount") {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "invoice_total_amount" || isMapped
        );
      }

      // if (
      //   state.mappingFieldList[payload.index].system_field === "customer_name"
      // ) {
      //   newRequiredFields = state.requiredFields.filter(
      //     ({ id, isMapped }) => id != "contact_first_name" || isMapped
      //   );
      // }

      // if (
      //   state.mappingFieldList[payload.index].system_field ===
      //   "contact_first_name"
      // ) {
      //   newRequiredFields = state.requiredFields.filter(
      //     ({ id, isMapped }) => id != "customer_name" || isMapped
      //   );
      // }

      if (
        customerName &&
        state.mappingFieldList[payload.index].system_field === "customer_name"
      ) {
        let filterName = cusNameRmv.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterName.includes(item.id)
        );
      }

      if (
        contactFirstName &&
        state.mappingFieldList[payload.index].system_field ===
          "contact_first_name"
      ) {
        let filterName = cusNameRmv.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterName.includes(item.id)
        );
      }

      if (
        contactName &&
        state.mappingFieldList[payload.index].system_field === "contact_name"
      ) {
        let filterName = cusNameRmv.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterName.includes(item.id)
        );
      }

      if (
        state.mappingFieldList[payload.index].system_field ===
        "invoice_total_amount"
      ) {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "due_amount" || isMapped
        );
      }
      // }

      if (payload && payload.module) {
        state.mappingFieldList[payload.index].module = payload.module;
      } else {
        delete state.mappingFieldList[payload.index].module;
        state.mappingFieldList[payload.index].contact_index = null;
      }

      if (state.systemFields[selectSystemField].format?.reference) {
        const { system_column, id } = state.systemFields[selectSystemField];
        const findRequiredFormatFields = state.requiredFormatFields.find(
          (e) => e.id === id
        );
        {
          state.requiredFormatFields.push({
            system_column,
            id,
            isSelected: false,
          });
        }
      }

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        requiredFields: [...newRequiredFields],
        requiredFormatFields: [...state.requiredFormatFields],
      };
    }

    case CONTACT_UPDATE_CONTACT_INDEX: {
      state.mappingFieldList[payload.index].contact_index =
        payload.contactIndex;
      if (payload && payload.module) {
        state.mappingFieldList[payload.index].module = payload.module;
      }
      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
      };
    }

    case CUSTOMER_CONTACT_UPDATE_DEFAULT_DATA: {
      // if (!state.validDefaultFields[payload.index].default.value) {
      //   const requiredDefaultFieldsIndex =
      //     state.requiredDefaultFields.findIndex(
      //       ({ id }) => id === state.validDefaultFields[payload.index].id
      //     );
      //   state.requiredDefaultFields.splice(requiredDefaultFieldsIndex, 1);
      // }

      let newValidDefaultFields = [...state.validDefaultFields];
      let newReqFields = [...state.fieldMessage];

      newValidDefaultFields[payload.index].default.value = payload.defaultData;

      if (
        state.validDefaultFields[payload.index].id == "invoice_date" &&
        !payload.defaultData.startsWith("{{") &&
        !payload.defaultData.endsWith("}}")
      ) {
        let isDateVaied = moment(
          payload.defaultData,
          state.organizationDtl.date_format,
          true
        ).isValid();

        if (!isDateVaied) {
          newReqFields.push({
            id: "invoice_date",
            message: `Invoice Date is not as per the format ${state.organizationDtl.date_format}`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_date");
        }
      }

      if (
        state.validDefaultFields[payload.index].id == "invoice_due_date" &&
        !payload.defaultData.startsWith("{{") &&
        !payload.defaultData.endsWith("}}")
      ) {
        let isDateVaied = moment(
          payload.defaultData,
          state.organizationDtl.date_format,
          true
        ).isValid();

        if (!isDateVaied) {
          newReqFields.push({
            id: "invoice_due_date",
            message: `Due Date entered is not as per the format ${state.organizationDtl.date_format}`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_due_date");
        }
      }

      let findInvDateFields = newValidDefaultFields.find(
        (e) => e.id == "invoice_date"
      );

      let findDueDateFields = newValidDefaultFields.find(
        (e) => e.id == "invoice_due_date"
      );

      if (
        findInvDateFields &&
        findInvDateFields.id &&
        !findInvDateFields.default.value.startsWith("{{") &&
        !findInvDateFields.default.value.endsWith("}}") &&
        findDueDateFields &&
        findDueDateFields.id &&
        !findDueDateFields.default.value.startsWith("{{") &&
        !findDueDateFields.default.value.endsWith("}}") &&
        newReqFields.filter((nr) =>
          ["invoice_date", "invoice_due_date"].includes(nr.id)
        ).length === 0
      ) {
        let invDate = moment(
          findInvDateFields.default.value,
          state.organizationDtl.date_format
        );
        let dueDate = moment(
          findDueDateFields.default.value,
          state.organizationDtl.date_format
        );

        if (invDate.diff(dueDate, "days") > 0) {
          newReqFields.push({
            id: "invoice_date_gr",
            message: `Invoice Date has to be lesser than or equal to Due Date`,
          });
        } else {
          newReqFields = newReqFields.filter(
            (e) => e.id != "invoice_due_date_gr"
          );
        }

        if (dueDate.diff(invDate, "days") < 0) {
          newReqFields.push({
            id: "invoice_due_date_gr",
            message: `Due Date has to be greater than or equal to Invoice Date`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_date_gr");
        }
      } else {
        newReqFields = newReqFields.filter(
          (e) => e.id != "invoice_due_date_gr" || e.id != "invoice_date_gr"
        );
      }

      return {
        ...state,
        validDefaultFields: [...state.validDefaultFields],
        fieldMessage: [...newReqFields],
      };

      break;
    }

    case CUSTOMER_CONTACT_UPDATE_TEMPLATE_DATA_LIST:
      let formulaVar = [];
      let newRequiredFields = state.requiredFields;
      let newFormatFields = [];
      state.mappingFieldList = state.mappingFieldList.map((e) => ({
        ...e,
        format: null,
        system_field: null,
        contact_index: null,
        module: null,
      }));

      let filterFormulaData = payload.mappingFields.filter(
        ({ is_formula }) => is_formula
      );

      if (filterFormulaData && filterFormulaData.length > 0) {
        let filterPreFormaul = state.mappingFieldList.filter(
          ({ is_formula }) => !is_formula
        );
        let mergeFormuaData = [...filterPreFormaul, ...filterFormulaData];
        state.mappingFieldList = [...mergeFormuaData].map((e, i) => {
          return {
            ...e,
            index: alphabetize[i],
          };
        });
      } else {
        let filterPreFormaul = state.mappingFieldList.filter(
          ({ is_formula }) => !is_formula
        );
        state.mappingFieldList = [...filterPreFormaul].map((e, i) => {
          return {
            ...e,
            index: alphabetize[i],
          };
        });
      }

      let validatingData = state.mappingFieldList.reduce((acc, cur) => {
        acc[cur.index] = cur.first_row_data;
        return acc;
      }, {});

      // let mergeFormuaData = [...state.mappingFieldList, ...filterFormulaData];
      // state.mappingFieldList = [...mergeFormuaData].map((e, i) => {
      //   return {
      //     ...e,
      //     index: alphabetize[i],
      //   };
      // });

      payload.mappingFields.map((e) => {
        if (e.is_formula) {
          const tokens = tokenize(e.upload_field);
          let parser = new Parser();
          let isVariable = [];
          for (const { value, type, subtype } of tokens) {
            if (type == "operand" && subtype == "range") {
              isVariable.push(value);
              parser.setVariable(value, validatingData[value]);
            }
          }

          for (const formulaName of Object.keys(formulas)) {
            parser.setFunction(formulaName, formulas[formulaName]);
          }

          let { result, error } = parser.parse(e.upload_field);

          let findIndexOfFormula = state.mappingFieldList.findIndex(
            ({ upload_field }) => upload_field == e.upload_field
          );

          state.mappingFieldList[findIndexOfFormula] = {
            ...state.mappingFieldList[findIndexOfFormula],
            first_row_data: result,
            variableUsed: [...isVariable],
          };
          validatingData[e.index] = result;
        }

        const initialMappingFieldIndex = state.mappingFieldList.findIndex(
          ({ index, upload_field, is_formula }) => {
            if (is_formula && e.is_formula && !e.index) {
              return upload_field == e.upload_field;
            } else if (e.is_formula && is_formula) {
              return index == e.index;
            } else {
              return upload_field == e.upload_field;
            }
          }
        );

        const systemFieldIndex = state.systemFields.findIndex(
          ({ id }) => e.system_field === id
        );

        let isUploadField = state.mappingFieldList.find(
          ({ index, upload_field, is_formula }) => {
            if (is_formula && e.is_formula && !e.index) {
              return upload_field == e.upload_field;
            } else if (is_formula && e.is_formula) {
              return index == e.index;
            } else {
              return upload_field == e.upload_field;
            }
          }
        );

        // if (systemFieldIndex !== -1 && isUploadField) {
        //   state.systemFields[systemFieldIndex].isSelected = true;
        // }

        const selectTick = state.systemFields.findIndex(
          ({ id }) => id === e.system_field
        );

        if (
          initialMappingFieldIndex != -1 &&
          state.systemFields[selectTick]?.required
        ) {
          const { system_column, id } = state.systemFields[selectTick];
          const requiredFieldIndex = newRequiredFields.findIndex(
            (e) => e.id == id
          );

          if (requiredFieldIndex != -1) {
            newRequiredFields[requiredFieldIndex].isMapped = true;
          }

          // newRequiredFields.push({ system_column, id, isMapped: true });
        }

        if (
          initialMappingFieldIndex != -1 &&
          state.systemFields[selectTick]?.format &&
          isUploadField
        ) {
          const { system_column, id } = state.systemFields[selectTick];
          newFormatFields.push({ system_column, id, isSelected: true });
        }

        if (state.systemFields[selectTick]) {
          const { module } = state.systemFields[selectTick];
          if (module === "CON") {
            state.mappingFieldList[initialMappingFieldIndex] = {
              ...state.mappingFieldList[initialMappingFieldIndex],
              module: "CON",
            };
          }
        }

        if (state.systemFields[selectTick] && isUploadField) {
          state.systemFields[selectTick].isSelected = true;
          state.mappingFieldList[initialMappingFieldIndex] = {
            ...state.mappingFieldList[initialMappingFieldIndex],
            format: e.format,
            system_field: e.system_field,
            contact_index: e.contact_index,
            ...(e.contact_index
              ? {
                  module: "CON",
                }
              : {}),
          };
        }

        if (e.system_field == "contact_first_name") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "customer_name" || isMapped
          );
        } else if (e.system_field == "contact_name") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "contact_first_name" || isMapped
          );
        }

        if (e.system_field == "invoice_total_amount") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "due_amount" || isMapped
          );
        } else if (e.system_field == "due_amount") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "invoice_total_amount" || isMapped
          );
        }
      });

      let mappingState = [...state.mappingFieldList];

      if (filterFormulaData && filterFormulaData.length > 0) {
        formulaVar = mappingState.reduce(
          (acc, curr) => acc.concat(curr.variableUsed),
          []
        );
      } else {
        formulaVar = [...initialState.formulaVariables];
      }

      payload.defaultFields.map((e) => {
        const initialDefaultFieldIndex = state.defaultFields.findIndex(
          ({ id }) => id == e.id
        );
        if (
          state &&
          state.defaultFields &&
          state.defaultFields[initialDefaultFieldIndex] &&
          state.defaultFields[initialDefaultFieldIndex].defaultstate &&
          state.defaultFields[initialDefaultFieldIndex].default.value
        ) {
          state.defaultFields[initialDefaultFieldIndex].default.value = e.value;
        }
      });

      let mapBasedOnData = payload.mapBasedOn.map((e) => {
        return {
          id: e,
        };
      });

      const mapBasedOn = payload.mapBasedOn;

      const requiredFields = state.systemFields.reduce(
        (prev, { required, system_column, id, isSelected }) => {
          if (
            required &&
            id !== "business_unit" &&
            required &&
            id !== "customer_name"
          ) {
            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          if (
            mapBasedOn?.includes(id) &&
            id !== "business_unit" &&
            mapBasedOn?.includes(id) &&
            id !== "customer_name"
          ) {
            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          return prev;
        },
        []
      );

      updateRequiredFields(state.mappingFieldList, requiredFields);

      // ...newRequiredFields,

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        systemFields: [...state.systemFields],
        requiredFields: [...requiredFields],
        requiredFormatFields: [...newFormatFields],
        mapBasedOn: [...mapBasedOnData],
        formulaVariables: [...formulaVar],
      };

    case CUSTOMER_CONTACT_ADHOC_REFETCH: {
      const { adhocRefetchActions } = state;
      return {
        ...state,
        adhocRefetchActions: adhocRefetchActions + 1,
      };
    }

    case CUSTOMER_CONTACT_SET_AU_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case CUSTOMER_CONTACT_VALIDATION_ADHOC_FILTER: {
      let defaultFields = [];

      state.defaultFields.map((e) => {
        let findMappedFields = state.mappingFieldList.find(
          ({ system_field }) => system_field == e.id
        );

        if (!findMappedFields || !findMappedFields.system_field) {
          defaultFields.push(e);
        }
      });

      let newDefaultFields = defaultFields.reduce((prev, curr) => {
        if (curr.id === "business_unit") {
          let businessUnit = state.systemFields.find(
            ({ id }) => id === "business_unit"
          );

          let mapBasedOn = state.mapBasedOn.find(
            ({ id }) => id === "business_unit"
          );

          let findBusinessUnit =
            state && state.businessUnitRd.find(({ label }) => label == "ALL");

          if (businessUnit && !businessUnit.isMapped && mapBasedOn) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: findBusinessUnit?.id },
                is_create: false,
              },
            ];
          }

          return prev;
        }

        if (curr.id === "customer_name") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "customer_name"
          );

          let mapBasedOn = state.mapBasedOn.find(
            ({ id }) => id === "customer_name"
          );

          if (sysField && !sysField.required && !mapBasedOn) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "{{First_name+Last_name}}" },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "notes_category") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "notes_category"
          );

          if (sysField && !sysField.isSelected) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "general" },
                is_create: true,
              },
            ];
          }

          return prev;
        }

        if (curr.id === "type") {
          let sysField = state.systemFields.find(({ id }) => id === "type");

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "INFORMATIONAL" },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "created_by") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "created_by"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "system" },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "currency") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "customer_currency"
          );
          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: state.customerDtl.default_currency,
                },
              },
            ];
          }
          return prev;
        }

        if (curr.id === "contact_relation") {
          return [
            ...prev,
            {
              ...curr,
              is_create: true,
            },
          ];
        }

        if (curr.id === "phone_code") {
          return [
            ...prev,
            {
              ...curr,
              default: {
                ...curr.default,
                value: "+1",
              },
            },
          ];
        }

        if (curr.id === "contact_type") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "contact_type"
          );
          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: "primary_contact",
                },
              },
            ];
          }
          return prev;
        }

        if (curr.id === "business_location") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "business_location"
          );
          // let findBusinessLocation =
          //   state &&
          //   state.businessLoactionRd.find(({ label }) => label == "ALL");
          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: {
                  ...curr.default,
                  value: "ALL",
                },
              },
            ];
          }
          return prev;
        }

        if (curr.id === "created_at") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "created_at"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "{{Today}}" },
              },
            ];
          }

          return prev;
        }

        return [...prev, curr];
      }, []);

      return {
        ...state,
        validDefaultFields: [...newDefaultFields],
      };
    }

    case CUSTOMER_CONTACT_MAP_BASED_ON: {
      const mapBasedData = payload?.map((e) => e?.id);
      const requiredFields = state.systemFields.reduce(
        (prev, { required, system_column, id, isSelected }) => {
          if (
            required &&
            id !== "business_unit" &&
            required &&
            id !== "customer_name"
          ) {
            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          if (
            mapBasedData?.includes(id) &&
            id !== "business_unit" &&
            mapBasedData?.includes(id) &&
            id !== "customer_name"
          ) {
            // if (id === "customer_name") {
            //   return [
            //     ...prev,
            //     {
            //       system_column: "Contact First Name",
            //       id: "contact_first_name",
            //       isMapped: false,
            //     },
            //     {
            //       system_column,
            //       id,
            //       isMapped: isSelected ? isSelected : false,
            //     },
            //   ];
            // }

            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          return prev;
        },
        []
      );

      let reqField = [...requiredFields];

      let findContactName = state.mappingFieldList.find(
        (e) => e.system_field === "contact_name"
      );
      let findContactFirstName = state.mappingFieldList.find(
        (e) => e.system_field === "contact_first_name"
      );

      if (findContactFirstName && findContactName) {
        reqField = reqField.filter((e) => e.id !== "contact_first_name");
      } else if (findContactName) {
        reqField = reqField.filter((e) => e.id !== "contact_first_name");
      } else if (findContactFirstName) {
        reqField = reqField.filter((e) => e.id !== "contact_name");
      }

      return {
        ...state,
        mapBasedOn: [...payload],
        requiredFields: [...reqField],
      };
    }

    case CUSTOMER_CONTACT_INITIALSTATE_STATE: {
      return {
        ...state,
        ...initialState,
        requiredFormatFields: [],
        mapBasedOn: [],
      };
    }

    case CUSTOMER_CONTACT_BUSINESS_UNIT_DATA: {
      return {
        ...state,
        businessUnitRd: [...payload],
      };
    }

    case CUSTOMER_CONTACT_BUSINESS_LOACTION_DATA: {
      return {
        ...state,
        businessLoactionRd: [...payload],
      };
    }

    case CUSTOMER_CONTACT_FORMULA_FIELDS: {
      let formulaFieldStrucher;
      if (payload?.formulaFields) {
        formulaFieldStrucher = {
          ...payload.formulaFields,
          index: alphabetize[state.mappingFieldList.length],
        };
      }
      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList, formulaFieldStrucher],
        formulaVariables: [
          ...state.formulaVariables,
          ..._.get(payload, "formulaFields.variableUsed", []),
        ],
      };
    }

    case CUSTOMER_CONTACT_DELETE_FORMULA_FIELDS: {
      let formulMapdata = state.mappingFieldList;
      let deleteFormulaFields = formulMapdata.splice(payload, 1);
      let reMapFieldData = formulMapdata.map((e, i) => {
        return {
          ...e,
          index: alphabetize[i],
        };
      });

      let vaValues = new Set();
      state.mappingFieldList.forEach((obj) =>
        obj?.variableUsed?.forEach((value) => vaValues.add(value))
      );

      let filteredY = state.formulaVariables.filter((value) =>
        vaValues.has(value)
      );

      return {
        ...state,
        mappingFieldList: [...reMapFieldData],
        formulaVariables: [...filteredY],
      };
    }

    case CUSTOMER_CONTACT_UPDATE_FORMULA_FIELDS: {
      state.mappingFieldList[payload.formulaFields.rowIndex] = {
        ...state.mappingFieldList[payload.formulaFields.rowIndex],
        upload_field: payload.formulaFields.upload_field,
        first_row_data: payload.formulaFields.first_row_data,
        isValid: payload.formulaFields.isValid,
        variableUsed: [...payload.formulaFields.variableUsed],
      };

      let vaValues = new Set();
      state.mappingFieldList.forEach((obj) => {
        obj?.variableUsed?.forEach((value) => vaValues.add(value));
      });

      let filteredY = state.formulaVariables.filter((value) =>
        vaValues.has(value)
      );

      state.mappingFieldList.forEach((obj) => {
        obj?.variableUsed?.forEach((value) => {
          if (!filteredY.includes(value)) {
            filteredY.push(value);
          }
        });
      });
      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        formulaVariables: [...filteredY],
      };
    }

    case CUSTOMER_CONTACT_ORG_DTL: {
      return {
        ...state,
        customerDtl: { ...payload },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default customerContactUploadReducer;
