import { useSelector, useDispatch } from "react-redux";
import { SIZE } from "../../components/IconButton";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Search } from "baseui/icon";
import { useIntl } from "react-intl";
import AllFilters from "../../components/AllFilters/AllFilters";
// PaginationWraper
import PaginationWraper from "../../components/Pagination/Pagination";
import TableViewPicker from "../../components/TableViewPicker";
// Redux
import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useDebouncedCallback } from "use-debounce";
import Loader from "../../components/Loader";
import setTableGridStructure from "../../utils/setTableGridStructure";
import {
  BATCH_CHECK,
  BULK_PAUSE,
  UPADTE_CHECK_STATUS,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import ColumnToogle from "../../components/ColumnToggle/ColumnToggle";
import { ModalContext } from "../../providers/ModalProvider";
import PauseActionFormInv from "../../components/FormModal/PauseActionFormInv";
import {
  setCheckManiTableHeaderOrder,
  setCheckMtFilter,
  setCheckMtGlobalFilter,
  setCheckMtGridAndView,
  setCheckMtGridStructure,
  setCheckMtPageIndex,
  setCheckMtPageSize,
  setCheckMtPinnedView,
  setCheckMtSort,
  setCheckMtTableView,
  setTableWidthCheckMt,
  updateTableCheckMtDraftState,
} from "../../redux/checkMaintenance/action";
import CheckBulkPach from "./CheckBulkPach";
import { TextBox } from "../../components/TextBox";
import work from "../../assets/img/workqueue.png";
import { Select } from "baseui/select";
import { SelectBox } from "../../components/SelectBox";

const TableBody = ({ page, prepareRow, getTableBodyProps }) => {
  return (
    <div className="customer__tabel__body" {...getTableBodyProps()}>
      {page.map((row, index) => {
        prepareRow(row);
        const rowProps = row.getRowProps();
        return (
          <React.Fragment key={rowProps.key}>
            <div
              key={index}
              className={`table-main__body-row --inv_summery_tabel_body ${
                row.isExpanded ? "expanded" : ""
              }`}
              {...row.getRowProps()}
            >
              {/* <Link to={`/customer/${index}`}> */}
              {row.cells.map((cell) => {
                return (
                  <div
                    className="table-main__body-cell"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
  return nextProps.isColumnResizing;
});

const CheckMaintenanceTable = (props) => {
  let { isCheckSearch, setIsCheckSearch } = props;
  let {
    currentOrganization,
    userPersonalization,
    customInvoice,
    referenceData,
  } = useContext(MaxyfiContext);

  const { handleModal } = useContext(ModalContext);
  let intl = useIntl();

  let ref = useRef();
  const setView = (props) => dispatch(setCheckMtTableView(props));

  useEffect(() => {
    if (ref.current) {
      dispatch(setTableWidthCheckMt(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  // FILTERS STARTS

  // Customer Filter
  const filterReduxData = useSelector(
    (s) => s.checkMaintenance.tableCurrentState.formFilters
  );

  // TO FIND LENGTH STARTS
  const InvoiceFilter = useSelector(
    (s) => s.checkMaintenance.tableCurrentState.formFilters
  );

  // TO FIND LENGTH ENDS

  // GRID STRUCTURE FOR CUSTOMER

  const gridStructureValues = useSelector(
    (s) => s.checkMaintenance.gridStructure.table
  );

  // GRID STRUCTURE FOR CUSTOMER
  const expandedRowId = useRef(null);

  const debounceFilter = useDebouncedCallback((filter) => {
    setIsCheckSearch({ ...isCheckSearch, search_debonce: filter });
    dispatch(setCheckMtPageIndex(0));
    // dispatch(setCheckMtGlobalFilter(filter));
  }, 1000);
  const dispatch = useDispatch();
  const invoiceSummaryReduxData = useSelector(
    (s) => s.checkMaintenance.tableState
  );
  let {
    viewName,
    gridAndViewLoaded,
    tableCurrentState,
    tableState,
    tableDraftView,
    gridStructure,
  } = useSelector((state) => state.checkMaintenance);
  const NeedsActionFilter = useSelector(
    (s) => s.checkMaintenance.tableCurrentState.needs_action
  );
  const pinnedView = useSelector((s) => s.checkMaintenance.pinnedView);

  const columns = useMemo(() => {
    return invoiceSummaryReduxData.columns;
  }, [invoiceSummaryReduxData.columns]);

  const tableData = useMemo(
    () =>
      props.data && props.data.data && props.data.data.docs
        ? props.data.data.docs
        : [],
    [props.isFetched, props.data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters, columnResizing, expanded },
    prepareRow,
    selectedFlatRows,
    setHiddenColumns,
    setAllFilters,
    setColumnOrder,
    allColumns,
    toggleAllRowsSelected,
    gotoPage,
    setPageSize,
    setSortBy,
    toggleRowExpanded,
    isAllRowsSelected,
  } = useTable(
    {
      columns,

      initialState: {
        sortBy: tableCurrentState.sort ? tableCurrentState.sort : [],
        pageIndex: tableCurrentState.pageIndex,
        pageSize: tableCurrentState.pageSize,
      },

      data: tableData,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        props.isFetched &&
        !props.isError &&
        props.data &&
        props.data.data &&
        props.data.data.docs
          ? props.data.data.totalPages
          : 0,
      // pageCount: isFetched && !isError && data.data ? data.data.totalPages : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  const isColumnResizing = !!columnResizing.isResizingColumn;

  useEffect(() => {
    const menu = "check_maintenance_grid";

    if (!viewName && !gridAndViewLoaded) {
      setTableGridStructure({
        menu,
        currentOrganization,
        setGridStructureAction: setCheckMtGridStructure,
        setViewAction: setCheckMtTableView,
        setGridAndViewAction: setCheckMtGridAndView,
        userPersonalization,
        dispatch,
        isSetGridAndView: true,
      });
    }
  }, []);

  useEffect(() => {
    if (tableState.sort) {
      let isDesc = tableState.sort.startsWith("-");
      setSortBy([{ id: tableState.sort.replace("-", ""), desc: isDesc }]);
    }
  }, [tableState.sort]);

  useEffect(() => {
    if (invoiceSummaryReduxData.filters) {
      setAllFilters(invoiceSummaryReduxData.filters);
    }
  }, [invoiceSummaryReduxData.filters]);

  useEffect(() => {
    let rowIds = Object.keys(expanded);

    if (rowIds.length > 1) {
      toggleRowExpanded(
        rowIds.filter((e) => e == expandedRowId.current),
        false
      );
    }

    expandedRowId.current = rowIds[0];
  }, [expanded]);

  useEffect(() => {
    setHiddenColumns(invoiceSummaryReduxData.hiddenColumns);
  }, [invoiceSummaryReduxData.hiddenColumns]);

  /** Update Table Structure Changes to Redux as Draft Table State **/
  useEffect(() => {
    if (!isColumnResizing) {
      dispatch(
        updateTableCheckMtDraftState({
          table: allColumns,
          sort: sortBy,
        })
      );
    }
  }, [isColumnResizing, sortBy, filters, columns, allColumns, visibleColumns]);

  useEffect(() => {
    let currTableSort =
      sortBy && sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : sortBy[0].id
        : null;

    let currRdxSort =
      tableCurrentState.sort && tableCurrentState.sort[0]
        ? tableCurrentState.sort[0].desc
          ? "-" + tableCurrentState.sort[0].id
          : tableCurrentState.sort[0].id
        : null;

    if (currTableSort != currRdxSort) {
      gotoPage(0);
      dispatch(setCheckMtSort(sortBy));
    }
  }, [sortBy]);

  const {
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let selectedCustomer = [...(selectedFlatRows ? selectedFlatRows : [])].map(
    (i) => i.original._id
  );
  // useEffect(() => {
  //   if (
  //     referenceData["check_filter_type"] &&
  //     referenceData["check_filter_type"].length > 0
  //   ) {
  //     setIsCheckSearch({
  //       ...isCheckSearch,
  //       search_key: ,
  //     });
  //   }
  // }, []);
  return (
    <>
      <div
        className="table-main"
        {...getTableProps()}
        style={{ overflowY: "hidden" }}
      >
        <div className="table-main-container" ref={ref}>
          <div className="table-main__head" style={{ position: "unset" }}>
            {headerGroups.map((headerGroup) => (
              <>
                <div className="table-main__head-container">
                  {selectedFlatRows && selectedFlatRows.length > 0 ? (
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        height: "50px",
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                        padding: "0px 8px",
                      }}
                    >
                      <RBACWrapper role={BATCH_CHECK} type="PERMISSION">
                        <div
                          style={{
                            width: "120px",
                            height: "36px",
                            background: "#DDE3FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleModal({
                              title: "",
                              content: "",
                              successCallback: () => {
                                // deleteTemplate.mutate(_id);
                              },
                              formContent: ({ handleModal }) => {
                                return (
                                  <>
                                    <CheckBulkPach
                                      selectedInvoice={selectedCustomer}
                                      handleModal={handleModal}
                                      toggleAllRowsSelected={
                                        toggleAllRowsSelected
                                      }
                                      isAllRowsSelected={isAllRowsSelected}
                                      queryFilter={props.queryFilter}
                                      selectAll={
                                        "Select all the matching consumer records"
                                      }
                                      skipBatch={
                                        "Skip Batched records previously"
                                      }
                                      formTitle={"Batch Check"}
                                      actionType={"BATCH"}
                                      isCheckSearch={isCheckSearch}
                                    />
                                  </>
                                );
                              },
                              cancelCallback: () => {},
                              isChildren: true,
                              image: work,
                              buttonText: "",
                              closeButtonText: "",
                              isCloseAble: false,
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <i className="mx-icon-add" />
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#333860",
                                fontWeight: "450",
                              }}
                            >
                              Batch Check
                            </div>
                          </div>
                        </div>
                      </RBACWrapper>
                      <RBACWrapper role={UPADTE_CHECK_STATUS} type="PERMISSION">
                        <div
                          style={{
                            width: "145px",
                            height: "36px",
                            background: "#DDE3FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleModal({
                              title: "",
                              content: "",
                              successCallback: () => {
                                // deleteTemplate.mutate(_id);
                              },
                              formContent: ({ handleModal }) => {
                                return (
                                  <>
                                    <CheckBulkPach
                                      selectedInvoice={selectedCustomer}
                                      handleModal={handleModal}
                                      toggleAllRowsSelected={
                                        toggleAllRowsSelected
                                      }
                                      isAllRowsSelected={isAllRowsSelected}
                                      queryFilter={props.queryFilter}
                                      selectAll={
                                        "Update all the matching consumer records"
                                      }
                                      skipBatch={
                                        "Skip records that are not in Initiated Status"
                                      }
                                      formTitle={"Success Payment"}
                                      actionType={"SUCCESS"}
                                      isCheckSearch={isCheckSearch}
                                    />
                                  </>
                                );
                              },
                              cancelCallback: () => {},
                              isChildren: true,
                              image: work,
                              buttonText: "",
                              closeButtonText: "",
                              isCloseAble: false,
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <i className="mx-icon-add" />
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#333860",
                                fontWeight: "450",
                              }}
                            >
                              Success Payment
                            </div>
                          </div>
                        </div>
                      </RBACWrapper>
                      <RBACWrapper role={UPADTE_CHECK_STATUS} type="PERMISSION">
                        <div
                          style={{
                            width: "130px",
                            height: "36px",
                            background: "#DDE3FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleModal({
                              title: "",
                              content: "",
                              successCallback: () => {
                                // deleteTemplate.mutate(_id);
                              },
                              formContent: ({ handleModal }) => {
                                return (
                                  <>
                                    <CheckBulkPach
                                      selectedInvoice={selectedCustomer}
                                      handleModal={handleModal}
                                      toggleAllRowsSelected={
                                        toggleAllRowsSelected
                                      }
                                      isAllRowsSelected={isAllRowsSelected}
                                      queryFilter={props.queryFilter}
                                      selectAll={
                                        "Update all the matching consumer records"
                                      }
                                      skipBatch={
                                        "Skip records that are not in Initiated Status"
                                      }
                                      formTitle={"Failed Payment"}
                                      actionType={"FAILURE"}
                                      isCheckSearch={isCheckSearch}
                                      nsfNACHA={"Apply NSF Fee"}
                                    />
                                  </>
                                );
                              },
                              cancelCallback: () => {},
                              isChildren: true,
                              image: work,
                              buttonText: "",
                              closeButtonText: "",
                              isCloseAble: false,
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <i className="mx-icon-add" />
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#333860",
                                fontWeight: "450",
                              }}
                            >
                              Failed Payment
                            </div>
                          </div>
                        </div>
                      </RBACWrapper>
                    </div>
                  ) : (
                    <div
                      className="table-main__head-row"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            padding: "25px 0px 10px 10px",
                            width: "280px",
                          }}
                        >
                          <TextBox
                            name="check_filter_input"
                            label={intl.formatMessage({
                              id: "search_cus_name_check_no",
                            })}
                            placeholder={intl.formatMessage({
                              id: "search",
                            })}
                            // endEnhancer={<Search size="18px" />}
                            onChange={(val) => {
                              let value = val.target.value;
                              setIsCheckSearch({
                                ...isCheckSearch,
                                search_value: value,
                              });
                              debounceFilter(value);
                            }}
                            size={SIZE.compact}
                            value={isCheckSearch.search_value}
                          />
                        </div>
                        <div
                          style={{
                            // padding: "10px 0px 10px 10px",
                            width: "150px",
                            // marginTop: "1%",
                            paddingTop: "15px",
                          }}
                        >
                          <SelectBox
                            name="check_filter"
                            clearable={false}
                            options={referenceData["check_filter_type"]}
                            value={isCheckSearch.search_key}
                            placeholder=""
                            label=""
                            // clearable={false}
                            size={SIZE.compact}
                            onChange={(params) =>
                              setIsCheckSearch({
                                ...isCheckSearch,
                                search_key: params.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                          gap: "10px",
                        }}
                      >
                        <div style={{}}>
                          <TableViewPicker
                            allColumns={[]}
                            tableDraftView={tableDraftView}
                            viewName={viewName}
                            menu={"check_maintenance_grid"}
                            setView={setView}
                            currentFilteringData={filterReduxData}
                            gridStructure={gridStructure}
                            tableCurrentState={tableCurrentState}
                            pinnedView={pinnedView}
                            setPinnedView={setCheckMtPinnedView}
                            colOrder={(data) =>
                              dispatch(setCheckManiTableHeaderOrder(data))
                            }
                          />
                        </div>
                        <div style={{ width: "40px" }}>
                          <AllFilters
                            setQueryFilter={props.setQueryFilter}
                            setPageIndex={gotoPage}
                            filterReduxData={filterReduxData}
                            // stageFilter={customerStageFilters}

                            name={"Custom"}
                            gridStructureValues={gridStructureValues}
                            mainFilter={(data) =>
                              dispatch(setCheckMtFilter(data))
                            }
                            // needsActionFilter={setNeedsActionFilterInv}
                            pageIndex={setCheckMtPageIndex}
                            mainFilterDataLength={InvoiceFilter}
                            needsActionFilterDataLength={NeedsActionFilter}
                            id={"check"}
                            title={"custom"}
                          />
                        </div>

                        <ColumnToogle
                          allColumns={allColumns}
                          setColumnOrder={setColumnOrder}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className="table-main__head-row"
                    style={{ backgroundColor: "#F5F6FA" }}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <div
                        className="table-main__head-cell-wrapper"
                        {...column.getHeaderProps()}
                      >
                        <div className="table-main__head-cell">
                          {column.render("Header")}
                          {column.canResize && (
                            <a
                              className="table-main__head-cell-resizer"
                              {...column.getResizerProps()}
                            ></a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
          </div>
          {props.isLoading || !gridAndViewLoaded ? (
            <Loader />
          ) : tableData && tableData.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <TableBodyMemo
              isColumnResizing={isColumnResizing}
              page={page}
              prepareRow={prepareRow}
              getTableBodyProps={getTableBodyProps}
              visibleColumns={visibleColumns}
            />
          )}
        </div>
      </div>
      <PaginationWraper
        pageIndex={tableCurrentState.pageIndex}
        pageSize={tableCurrentState.pageSize}
        pageCount={pageCount}
        localKey={"invoice_summary_page_size"}
        gotoPage={(val) => {
          gotoPage(val);
          dispatch(setCheckMtPageIndex(val));
        }}
        setPageSize={(val) => {
          setPageSize(val);
          dispatch(setCheckMtPageSize(val));
        }}
      />
    </>
  );
};

export default CheckMaintenanceTable;
