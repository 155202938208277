import { HeadingXLarge, ParagraphLarge } from "baseui/typography";
import { IconButton, KIND as Icon } from "../../components/IconButton";
import Plus from "../../assets/img/svg/Upload/Plus";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { TextButton, SIZE as size, KIND } from "../../components/TextButton";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getCommunicationTemplateList,
  getCustomerEsignList,
} from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";

import setSelectValues from "../../utils/setSelectValues";

import { ChevronLeft } from "baseui/icon";
import _ from "lodash";

import { useSelector } from "react-redux";
import EsignSvg from "../../assets/img/svg/EsignSvg";
import EsignForm from "../../containers/ESignFormContainer/EsignForm";
import EsignListCard from "../../components/EsignListContainer/EsignListCard";
import { COMMUNICATION_TEMPLATE } from "../../constants";
import queryClient from "../../providers/queryClient";

const EsignList = ({ setAdd, setResource, customerId, templates }) => {
  let { currentOrganization, users, referenceData } = useContext(MaxyfiContext);

  const listEsignRef = useRef();
  const { isLoading, data, isFetching } = useQuery(
    `ESIGN-ACTION-LIST_${customerId}`,
    async () => {
      return await getCustomerEsignList({
        organization: currentOrganization,
        customerId,
      });
    },
    {
      onSuccess: (data) => {
        if (
          _.get(data, "data.docs", []) &&
          Array.isArray(_.get(data, "data.docs", []))
        ) {
          if (
            _.get(data, "data.doc", []) &&
            _.get(data, "data.doc", []).length > 0
          ) {
            setAdd(false);
          } else {
            setAdd();
          }
        }
      },
    }
  );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      {_.get(data, "data.doc", []).length > 0 ? (
        _.get(data, "data.doc", []).map((e) => {
          return (
            <EsignListCard
              {...e}
              ref={null}
              key={e._id}
              isEdit={false}
              setIsEdit={() => {}}
              setResource={setResource}
              templates={templates}
              customerId={customerId}
              isList={true}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

const EsignInfo = ({ id, reset, isEdit = false, customerId, templates }) => {
  let { currentOrganization, users, currentDefaultFormatDetails, space } =
    useContext(MaxyfiContext);
  const data = queryClient.getQueryData(`ESIGN-ACTION-LIST_${customerId}`);

  const filteredItem = useMemo(() => {
    const docArray = _.get(data, "data.doc", []);
    return docArray.find((item) => item._id === id) || null;
  }, [data, id]);

  if (filteredItem && filteredItem?._id) {
    const {
      to = [],
      tem,
      sts,
      ex_dt,
      n_via,
      evt = "",
      cat,
      cus_txt,
      cby,
      sgn_tem,
    } = filteredItem;

    let recipients = [];
    if (Array.isArray(to) && to?.length > 0) {
      to?.map((e) => {
        recipients?.push({ id: e?.id, recipient_id: e?.recipient_id });
      });
    }

    const defaultValues = {
      sgn_tem: setSelectValues(sgn_tem),
      n_via: n_via,
      sts: setSelectValues(sts, false),
      evt: setSelectValues(evt, false),
      ...(cus_txt ? { cus_txt: cus_txt } : {}),
      ex_dt: ex_dt,
      ...(n_via ? { tem: setSelectValues(tem) } : {}),
      ...(Array.isArray(to) && to.length > 0 && n_via ? { to: to } : {}),
    };

    return (
      <EsignListCard {...filteredItem} isList={false} templates={templates}>
        {isEdit ? (
          <div style={{ marginTop: "10px" }}>
            <EsignForm
              resourceId={id}
              defaultValues={defaultValues}
              isEdit
              reset={reset}
              customerId={customerId}
              templates={templates}
            />
          </div>
        ) : (
          <></>
        )}
      </EsignListCard>
    );
  }
};

const LIST = "LIST";
const ADD = "ADD";
const EDIT = "EDIT";
const VIEW = "VIEW";
const initialState = {
  type: LIST,
  resourceId: null,
  resourceName: "",
};

function EsignDrawer({ setIsRequest, active, resourceId, resourceName }) {
  let customerId = "";
  let { currentOrganization, users, currentDefaultFormatDetails, space } =
    useContext(MaxyfiContext);

  // business Unit ID for V2 UI
  let { data } = useSelector((e) => e.customerOverviewDrawer);

  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [state, setState] = useState(initialState);

  const params = useParams();

  if (params.customerId) {
    customerId = params.customerId;
  } else {
    customerId = getSearchParams.get("customers");
  }

  const setAdd = (isAdd = true) => {
    setState({ ...state, type: isAdd ? ADD : LIST });
  };

  const setResource = (id, name, isEdit = false) => {
    setState({
      ...state,
      type: isEdit ? EDIT : VIEW,
      resourceId: id,
      resourceName: name,
    });
  };

  const reset = () => {
    if (resourceId && resourceName) {
      setIsRequest();
    } else {
      setState({ ...initialState });
    }
  };

  useEffect(() => {
    if (resourceId) {
      setResource(resourceId, resourceName, true);
    }

    return () => {
      if (resourceId) {
        getSearchParams.delete("customers");
        setGetSearchParams(getSearchParams.toString());
      }
    };
  }, []);

  const templates = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-${currentOrganization}-${customerId}`,
      {
        filters: {
          // type: "email",
          // tags: tags,
          // ...(master_tag ? { master_tag: master_tag } : {}),
        },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
      });
    }
  );

  return (
    <>
      <div
        // autoFocus
        // onClose={() => {
        //   setIsRequest(false);
        // }}
        // isOpen={active}
        // onBackdropClick={() => {}}
        style={{ width: "600px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            paddingRight: "30px",
          }}
        >
          {state.type === ADD || state.type === LIST ? (
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <EsignSvg width={22} color="black" />
              <HeadingXLarge>E-Signature</HeadingXLarge>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {resourceId && resourceName ? (
                <></>
              ) : (
                <IconButton
                  kind="tertiary"
                  size="mini"
                  onClick={() => {
                    reset();
                  }}
                >
                  <ChevronLeft color="#ADADAD" />
                </IconButton>
              )}
              <ParagraphLarge $style={{ color: "#ADADAD" }}>
                E-Signature{" "}
              </ParagraphLarge>{" "}
              {/* <HeadingXLarge>
                {state.resourceName ? state.resourceName : ""}
              </HeadingXLarge> */}
            </div>
          )}
          <div>
            {state.type === LIST ? (
              <TextButton
                kind="secondary"
                size="mini"
                onClick={() => {
                  setAdd();
                }}
              >
                <Plus fill="#516BEB" size={18} />
                Add
              </TextButton>
            ) : (
              <></>
            )}
          </div>
        </div>
        {state.type === ADD && (
          <EsignForm
            reset={reset}
            business_unit={data}
            customerId={customerId}
            templates={templates}
          />
        )}
        {state.type === LIST && (
          <EsignList
            setAdd={setAdd}
            setResource={setResource}
            business_unit={data}
            customerId={customerId}
            templates={templates}
          />
        )}
        {state.type === VIEW || state.type === EDIT ? (
          <EsignInfo
            id={state.resourceId}
            reset={reset}
            isEdit={state.type === EDIT ? true : false}
            business_unit={data}
            customerId={customerId}
            templates={templates}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default EsignDrawer;
