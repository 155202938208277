import { HeadingXSmall, ParagraphMedium } from "baseui/typography";
import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { FormattedNumber } from "react-intl";
import _ from "lodash";

const StatusConverter = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;

  let { referenceData } = useContext(MaxyfiContext);
  value = _.get(row.original, accessor, "");

  const user = referenceData?.promise_status?.find(({ id }) => {
    return id == value;
  });

  const promiseAmount = row?.original?.paid_amount_percentage;
  const paidAmount = row?.original?.paid_amount?.value;

  return (
    <div>
      {user?.id === "IN_PROGRESS" ? (
        <ParagraphMedium>In-Progress</ParagraphMedium>
      ) : user?.id === "IA" ? (
        <ParagraphMedium>In-Active</ParagraphMedium>
      ) : user?.id === "BROKEN" ? (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              height: "7px",
              width: "7px",
              backgroundColor: "#FD372A",
              borderRadius: "50%",
            }}
          ></div>
          <div>
            {" "}
            <ParagraphMedium>{user?.label}</ParagraphMedium>{" "}
          </div>
        </div>
      ) : user?.id === "PARTIAL" ? (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: "7px",
                  width: "7px",
                  backgroundColor: "#FBBD04",
                  borderRadius: "50%",
                }}
              ></div>
              <ParagraphMedium>{user?.label}</ParagraphMedium>
            </div>
            {/* <FormattedNumber
              value={promiseAmount.value}
              style="currency"
              currency={promiseAmount.currency}
            /> */}
            {promiseAmount === 100 ? (
              <></>
            ) : (
              <>
                <HeadingXSmall
                  style={{ color: "#ADADAD", display: "flex", gap: "2px" }}
                >
                  <HeadingXSmall style={{ color: "#ADADAD" }}>
                    {promiseAmount ? `${promiseAmount.toFixed(2)}% - ` : ""}
                  </HeadingXSmall>
                  <HeadingXSmall style={{ color: "#ADADAD" }}>
                    ${paidAmount}
                  </HeadingXSmall>
                </HeadingXSmall>
              </>
            )}
          </div>
        </div>
      ) : user?.id === "CANCELED" ? (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                height: "7px",
                width: "7px",
                backgroundColor: "#FD372A",
                borderRadius: "50%",
              }}
            ></div>
            <div>
              <ParagraphMedium>{user?.label}</ParagraphMedium>
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                height: "7px",
                width: "7px",
                backgroundColor: "#0FB158",
                borderRadius: "50%",
              }}
            ></div>
            <div>
              <ParagraphMedium>{user?.label}</ParagraphMedium>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StatusConverter;
