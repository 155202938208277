// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import { async } from "q";
import axiosWithAuth from "../providers/AxiosInterceptor";
import { download } from "../utils/downloadFile";
import { downloadPdf, showFile } from "../utils/downloadPdf";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  CUSTOMER_OVERVIEW,
  CUSTOMER_OVERVIEW_,
  CO_TIMELINE_,
  CO_PAYMENT_,
} from "../constants";
import queryClient from "../providers/queryClient";
import _ from "lodash";
import { toast } from "react-toastify";
const HOST = process.env.REACT_APP_HOST;
// const HOST = `http://192.168.1.56:3030/api/`;
// const HOST = `http://192.168.1.47:3001/api/`;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const postInvoices = async ({ organization, ...rest }) => {
  return await axiosWithAuth.post(`${HOST}${organization}/invoices`, rest, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const letterPreviewPost = async ({
  organization,
  template_id,
  recipient,
  customerId,
  invoiceId,
  paymentId = null,
}) => {
  return await axiosWithAuth
    .post(
      `${HOST}${organization}/customer/${customerId}/letters/preview`,
      { template_id, recipient },
      {
        headers: {
          appCode: APP_CODE,
          Accept: "application/pdf",
        },
        params: {
          paymentId,
          invoiceId,
        },
        responseType: "arraybuffer",
      }
    )
    .then((blob) => {
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(
        new Blob([blob.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
    });
};

export const emailTempalteBuffer = async ({
  organization,
  template_id,
  recipient,
  customerId,
}) => {
  return await axiosWithAuth
    .post(
      `${HOST}${organization}/customer/${customerId}/letters/preview`,
      { template_id, recipient },
      {
        headers: {
          appCode: APP_CODE,
          Accept: "application/pdf",
        },
        responseType: "arraybuffer",
      }
    )
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob.data], { type: "application/pdf" })
      );
      return url;
    });
};

// export const downloadClientFile = async ({ organization = null, uploadId }) => {
//   return await axiosWithAuth
//     .get(`${HOST}${organization}/adhoc-data/${uploadId}/download-file`, {
//       headers: {
//         appCode: APP_CODE,
//       },
//     })
//     .then((blob) => {
//       const url = window.URL.createObjectURL(
//         new Blob([blob.data], { type: "text/csv;charset=utf-8" })
//       );
//       return url;
//     });
// };

export const putInvoiceEdit = async ({ organization, invoiceId, ...rest }) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/invoices/${invoiceId}/edit-invoice`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const putLetterAction = async ({
  organization,
  customerId,
  letterId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/letters/${letterId}`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const putCustomerStatus = async ({
  organization,
  customerId,
  letterId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/customer-status`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const putBusinessStatus = async ({
  organization,
  customerId,
  letterId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/business-data`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getItemAmount = async ({ organization, feeSubId, startDate }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/fee-subscription/${feeSubId}/first-invoice-data?start_date=${startDate}`,

    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const letterName = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/letter-name`,

    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const postCheckIn = async ({ organization, customerId, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/checkin`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
export const putCheckIn = async ({
  organization,
  customerId,
  checkinId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/checkin/${checkinId}`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getCheckIn = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/checkin`,

    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getCheckLocation = async ({ organization }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/checkin-location`,

    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getRecurringInvoiceItem = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/line-item-list`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const markCheckInAsBilled = async ({
  customerId,
  _id,
  organization = null,
  checkinId,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/checkin/${checkinId}`
  );
};

export const deleteCheckIn = async ({
  customerId,
  _id,
  organization = null,
  checkinId,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/checkin/${checkinId}`
  );
};

export const deleteCallback = async ({ organization = null, customerId }) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/call-back`
  );
};

export const deleteUploadCustomer = async ({
  organization = null,
  adhoc_id,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/adhoc-data/${adhoc_id}`
  );
};

export const postSubscription = async ({
  organization,
  customerId,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/subscription`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getSubscription = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/recurring-invoice`,
    {
      // params: {
      // type: `${syncStatus}`,
      // },
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
export const getSubscriptionSingleData = async ({
  organization,
  customerId,
  subscriptionId,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/recurring-invoice/${subscriptionId}`,
    {
      // params: {
      // type: `${syncStatus}`,
      // },
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const editSubscription = async ({
  customerId,
  organization = null,
  subscriptionId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/recurring-invoice/${subscriptionId}`,
    {
      ...rest,
    }
  );
};

export const updateSubscriptionStatus = async ({
  customerId,
  organization = null,
  recurringInvoiceId,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/recurring-invoice/${recurringInvoiceId}`
  );
};

export const deleteSubscription = async ({
  customerId,
  organization = null,
  subscribeId,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/subscription/${subscribeId}`
  );
};

export const makeCall = async ({ organization, customerId, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/make-call`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getCustomerDetails = async ({ customerId, organization }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const postDefendantsData = async ({
  customerId,
  organization,
  id_list,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/legal-action/sub/DEFENDENT`,
    { id_list },
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getMiniMiranda = async ({ miniMirandaId, organization }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/mini-miranda/${miniMirandaId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const patchPaymentTableSelect = async ({
  customerId,
  organization,
  syncStatus,
  paymentId,
  data,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/change-payment-status/${paymentId}`,
    { status: data },
    {
      params: {
        actionType: `${syncStatus}`,
      },
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getActionPrice = async ({ organization, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/workflow/action-price`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getCustomerActions = async ({ customerId, organization }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/actions`
  );
};

export const getCustomerOverviewTimelineList = async ({
  page = 1,
  page_size = 20,
  sort = "-executed_on",
  organization = null,
  id,
  entity: entity,
  ...filters
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/${entity}/${id}/timeline`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        page,
        page_size,
        sort,
        ...filters,
      },
    }
  );
};

export const getCustomerOverviewTimelineListV2 = async ({
  page = 1,
  page_size = 50,
  sort = "-executed_on",
  organization = null,
  id,
  entity: entity,
  ...filters
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/${entity}/${id}/timeline`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        page,
        page_size,
        sort,
        ...filters,
      },
    }
  );
};

// Used in Dispute.js
export const getDisputeTableData = async ({}) => {
  return await axiosWithAuth.get(`${HOST}invoices?customer_ref=3`);
};

// Api Call That need to Get Data in Customer Portal

export const getCustomerPortal = async ({
  customerPortalId,
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerPortalId}/customer-portal-setting`
  );
};

// Api Call That need to Get Data in Customer Portal

export const getDairyNotes = async ({
  customerId,
  organization = null,
  entity,
  filter,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/${entity}/${customerId}/notes`,
    { params: { ...filter } }
  );
};

// Api Call That need to Get Data in Customer Portal

export const getPaymentDatas = async ({
  paymentId,
  organization = null,
  ...filters
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${paymentId}/payments`,
    { params: { ...filters } }
  );
};

// Api Call used in Adhoc Action Review Promise
export const getReviewPromiseDatas = async ({
  promiseId,
  organization = null,
  payment_plan_id,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${promiseId}/promise-to-pay`,
    {
      params: {
        payment_plan_id: payment_plan_id,
      },
    }
  );
};

export const postPopUpData = async ({
  organization,
  promiseId,
  popId,
  data,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${promiseId}/promise-to-pay/${popId}`,
    { ...data }
  );
};

export const updatePromiseDate = async ({
  organization = null,
  customerId,
  data,
  promiseId,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/promise-to-pay/${promiseId}`,
    { ...data }
  );
};

export const deletePaymentPlan = async ({
  organization = null,
  customerId,
  promiseId,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/payment-plan/${promiseId}`
  );
};

export const deleteInstallemt = async ({
  organization = null,
  customerId,
  promiseId,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/apl-schedule-update/${promiseId}`
  );
};

// Api for Adhoc Action Dispute
export const getDisputeData = async ({
  organization = null,
  disputeId,
  id,
  invoiceId,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${disputeId}/disputes`,

    {
      params: {
        ...(invoiceId ? { invoice_id: invoiceId } : {}),
      },
    }
  );
};
export const putDisputeData = async ({
  organization = null,
  customerId,
  data,
  disputeId,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/disputes/${disputeId}`,
    { ...data }
  );
};

export const postDisputeData = async ({
  organization = null,
  customerId,
  data,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/disputes`,
    { ...data }
  );
};

// Table Post

// Api Call That need to Get Data in Customer Portal

export const getCustomerContactDetail = async ({
  customerId,
  organization = null,
  invoiceId,
  entity,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/contact`,
    {
      params: {
        invoice_id: invoiceId,
      },
    }
  );
};

export const getDairyNote = async ({ customerId, organization = null }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/notes`
  );
};

// Api That need to post the Data in Dairy Notes

export const postDairyNote = async ({
  customerId,
  organization = null,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/notes`,
    data
  );
};

export const postStickyDairyNote = async ({
  customerId,
  organization = null,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/sticky-notes`,
    data
  );
};

export const deleteStickyDairyNote = async ({
  customerId,
  organization = null,
  stickyId = null,
  ...data
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/sticky-notes/${stickyId}`,
    data
  );
};

// Api That need to Edit the Data in Dairy Notes

export const editDairyNote = async ({
  customerId,
  noteId,
  organization = null,
  entity,
  id,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${id}/notes/${noteId}`,
    data
  );
};

export const markAsCompleteDairyNote = async ({
  customerId,
  noteId,
  organization = null,
  entity,
  id,
  ...data
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${id}/notes/${noteId}`,
    data
  );
};

// Api That need to Edit the Data in Dairy Notes

export const deleteDairyNote = async ({
  customerId,
  _id,
  organization = null,
  entity,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/${entity}/${customerId}/notes/${_id}`
  );
};

export const createPaymentDetailsCustomerOverview = async ({
  id,
  organization = null,
  entity,
  payment_id,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/${entity}/${id}/payments${
      payment_id ? `?payment_id=${payment_id}` : ""
    }`,
    { ...data }
  );
};

export const updatePaymentDetailsCustomerOverview = async ({
  organization = null,
  id,
  entity,
  contactId,
  data,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/${entity}/${id}/payments/${contactId}`,
    { ...data }
  );
};

export const deletePaymentDetailsCustomerOverview = async ({
  contactId,
  customerId,
  organization = null,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/payments/${contactId}`
  );
};

// Customer invoice overview

export const customerInvoiceOverview = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters,
  customer_id = null,
  organization = null,
  exclude,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/invoices/customer-invoice/${customer_id}`,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        exclude: exclude,
        ...filters,
      },
    }
  );
};

export const paymentAllocationData = async ({
  filters,
  customer_id = null,
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customer_id}/payment-allocation`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const debtsInvoicesOverview = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters,
  customer_id = null,
  organization = null,
  is_with_mini_lic = false,
  exclude,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/invoices/customer-invoice/${customer_id}`,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        exclude: exclude,
        is_with_mini_lic: is_with_mini_lic,
        ...filters,
      },
    }
  );
};

export const addDebtLegal = async ({
  customerId,
  organization = null,
  id_list,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/legal-action/sub/DEBTS`,
    {
      id_list: id_list,
      ...rest,
    }
  );
};
export const getLegalInfo = async ({ customerId, organization = null }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/legal-action`
  );
};

export const postLegalInfomation = async ({
  customerId,
  organization,
  data,
  apiKey,
  apiNew,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/legal-action/information/${data.apiTabkey}/${data?.apiNew}`,
    data?.data
  );
};

export const updateLegalInfoMark = async ({
  customerId,
  organization,
  data,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/legal-action/information/${data.apiTabkey}/${data?.apiNew}`,
    data?.data
  );
};

export const addAttorney = async ({ organization = null, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/legal-attorney`,
    {
      ...rest,
    }
  );
};

export const updateAttorney = async ({
  organization = null,
  counselId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/legal-attorney/${counselId}`,
    {
      ...rest,
    }
  );
};

export const updateCounsel = async ({
  organization = null,
  customerId,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/legal-action/sub/COUNSEL`,
    {
      ...data,
    }
  );
};
export const addLegalStatus = async ({
  organization = null,
  customerId,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/legal-action/status`,
    {
      ...data,
    }
  );
};

export const addMotionLegal = async ({
  organization = null,
  customerId,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/legal-action/motions`,
    {
      ...data,
    }
  );
};

export const addEditMotionLegal = async ({
  organization = null,
  customerId,
  id,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/legal-action/motions/${id}`,
    {
      ...data,
    }
  );
};

export const deletelegalMotion = async ({
  organization = null,
  customerId,
  id,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/legal-action/motions/${id}`,
    {}
  );
};

export const addLegalTimer = async ({ organization = null, customerId }) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/legal-action/timesheet`,
    {}
  );
};
export const editTimeLegal = async ({
  organization = null,
  customerId,
  id,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/legal-action/timesheet/${id}`,
    {
      ...data,
    }
  );
};

export const getAuditList = async ({
  organization = null,
  customerId,
  id,
  type,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/legal-action/audit`,
    {
      params: {
        type: type,
      },
    }
  );
};
export const assignWorkflow = async ({
  workflowId,
  start_workflow_from = 0,
  customerId,
  organization = null,
  action = "ASSIGN",
  ...rest
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/workflow`,
    {
      workflow_id: workflowId,
      action,
      start_workflow_from,
      ...rest,
    }
  );
};

export const assignMulCusWfRel = async ({
  workflowId,
  start_workflow_from = 0,
  customerId,
  organization = null,
  // action = "ASSIGN",
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/WORKFLOW`,
    {
      workflow_id: workflowId,
      // action,
      customers: customerId,
      start_workflow_from,
      ...rest,
    }
  );
};

export const assignMulCusWfSgyRel = async ({
  customerId,
  organization = null,
  // action = "ASSIGN",
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/WF_SGY`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const assignMultiCheckIn = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/CHECKIN`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

//replicate client
export const replicateClientConfig = async ({
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/replicate-business-unit`,
    {
      ...rest,
    }
  );
};

export const clientGroupingConfig = async ({
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/add-client-to-group`,
    {
      ...rest,
    }
  );
};

export const bulkRevenueBilling = async ({
  customerId,
  organization = null,
  action = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/revenue-billing/actions/${action}`,
    {
      ...rest,
    }
  );
};

export const bulkPrintCheque = async ({
  customerId,
  organization = null,
  action = null,
  ...rest
}) => {
  return await axiosWithAuth
    .put(
      `${HOST}organization/${organization}/revenue-billing/actions/${action}`,
      {
        ...rest,
      },
      {
        responseType: "arraybuffer",
      }
    )
    .then((blob) => {
      if (blob && blob.data) {
        const url = window.URL.createObjectURL(
          new Blob([blob.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
      }
    });
};

export const assignCallUnsuccessful = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/CALL_UNSUCCESSFUL`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const assignSkipTrace = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/SKIP_TRACE`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const assignBulkDeleteSticky = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/sticky-notes`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const assignWorkQueue = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(`${HOST}${organization}/customer/assign/WQ`, {
    customers: customerId,
    ...rest,
  });
};
export const assignMulCusRel = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/REL_MANAGER`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const assignMulCusStatus = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/CUSTOMER_STATUS`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const assignCredit = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/generate-credit-report`,
    {
      ...rest,
    }
  );
};
export const assignMulPauseAction = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/CUSTOMER_PAUSE_ACTION`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const assignMulInvoicePauseAction = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/invoices/assign/INVOICE_PAUSE_ACTION`,
    {
      invoices: customerId,
      ...rest,
    }
  );
};

export const assignMulNextAction = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/RESCHEDULE_NEXT_ACTION`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const assignMulCusVoiceMail = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/assign/VOICE_MAIL`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const getCustomerVoiceFields = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/voice-mail-creatable-field`,
    {
      customers: customerId,
      ...rest,
    }
  );
};

export const executeCustomerAction = async ({
  id,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${id}/actions`,
    {
      ...rest,
    }
  );
};

export const executeAdhocCustomerAction = async ({
  id,
  organization = null,
  entity,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/${entity}/${id}/actions`,
    {
      ...rest,
    }
  );
};

export const executeGenericCustomerAction = async ({
  id,
  organization = null,
  entity,
  action_type,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/${entity}/${id}/actions-capture`,
    {
      ...rest,
    }
  );
};

export const executePaymentAction = async ({
  id,
  organization = null,
  entity,
  paymentId,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${id}/payments/${paymentId}`,
    {
      ...rest,
    }
  );
};

//Next Action
export const updateCustomerAction = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/actions`,
    {
      ...rest,
    }
  );
};

export const updateCustomerActionWorkflowStatus = async ({
  id,
  organization = null,
  actionStatus = null,
  entity,
  ...rest
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/${entity}/${id}/workflow/${actionStatus}`,
    {
      ...rest,
    }
  );
};

//CustomerOverviewCard

export const putTagsData = async ({
  organization = null,
  customerId,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/tags`,
    { ...data }
  );
};

export const putRelData = async ({
  organization = null,
  customerId,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/rel-manager`,
    { ...data }
  );
};

export const putCreditLimitData = async ({
  organization = null,
  customerId,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/credit-limit`,
    { ...data }
  );
};

export const markEmailAsReadCustomerTimeline = async ({
  organization = null,
  customerId,
  timelineId,
  entity,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/${entity}/${customerId}/timeline/${timelineId}/mark-as-read`
  );
};

export const invoiceDownload = async ({
  organization = null,
  invoiceId,
  templateType,
}) => {
  return await axiosWithAuth
    .get(`${HOST}${organization}/invoices/${invoiceId}/invoice-download `, {
      headers: {
        appCode: APP_CODE,
      },
      responseType: "arraybuffer",
    })
    .then((blob) => {
      download({
        fileName: "invoice.pdf",
        fileData: blob.data,
        fileOptions: {
          type: "application/pdf",
        },
      });
    });
};

export const getTimelineDownload = async ({
  organization = null,
  customerId,
  templateType,
}) => {
  return await axiosWithAuth
    .get(`${HOST}${organization}/customer/${customerId}/timeline-download`, {
      headers: {
        appCode: APP_CODE,
      },
      responseType: "arraybuffer",
    })
    .then((blob) => {
      download({
        fileName: "timeline.pdf",
        fileData: blob.data,
        fileOptions: {
          type: "application/pdf",
        },
      });
    });
};

export const getWhatsAppCus = async ({ organization = null, filters = {} }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/whatsapp-conversation/`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const getInformation = async ({
  organization = null,
  filter,
  page = 1,
  page_size = 10,
}) => {
  return await axiosWithAuth.get(`${HOST}user/me/notifications`, {
    params: {
      organization_id: organization,
      page: page,
      page_size: page_size,
      ...filter,
    },
  });
};
export const getNotificationCalendar = async ({
  organization = null,
  filters = {},
  page = 1,
  pageSize = 10,
}) => {
  return await axiosWithAuth.get(`${HOST}user/me/calendar`, {
    params: {
      organization_id: organization,
      page,
      page_size: pageSize,
      ...filters,
      // date,
    },
  });
};

export const patchInformation = async ({ organization = null, ...data }) => {
  return axiosWithAuth.patch(
    `${HOST}user/me/notifications/mark-as-read`,
    data,
    {
      headers: { appCode: APP_CODE },
    }
  );
};

export const informationMarkAllRead = async ({
  organization = null,
  appAccessRest = {},
  ...data
}) => {
  return axiosWithAuth.patch(
    `${HOST}user/me/notifications/mark-as-read-all`,
    data,
    {
      headers: { appCode: APP_CODE },
      params: { ...appAccessRest },
    }
  );
};

export const patchCalendar = async ({ organization = null, ...data }) => {
  return axiosWithAuth.patch(`${HOST}user/me/calendar`, data, {
    headers: { appCode: APP_CODE },
  });
};

export const getWhatsappChats = async ({
  clientId,
  organization = null,
  customerId,
  phoneCode,
  phoneNumber,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/whatsapp-conversation/${customerId}`,
    {
      params: {
        phone_code: phoneCode,
        phone_number: phoneNumber,
        page_size: 0,
      },
    }
  );
};

export const markAsRead = async ({
  organization = null,
  customerId,
  phoneCode,
  phoneNumber,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/whatsapp-conversation/${customerId}`,
    {
      phone: {
        code: phoneCode,
        value: phoneNumber,
      },
    }
  );
};

export const getLetters = async ({ organization = null, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/letters`
  );
};

export const editeCustomerName = async ({ organization, customerId, data }) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}`,
    { ...data }
  );
};

export const customerNameEditFn = async ({
  organization,
  customerId,
  value,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}`,
    { ...value }
  );
};

export const useCustomerNameEdit = (props) => {
  const client = useQueryClient();
  return useMutation(customerNameEditFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const makeInstantPayment = async ({
  organization,
  customerId,
  data,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/make-instant-payment`,
    { ...data }
  );
};

export const getFieldVisit = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/field-visit`
  );
};

export const deleteFieldVisit = ({
  organization = null,
  customerId = null,
  fieldVisitId,
  value,
}) => {
  return axiosWithAuth.delete(
    `${process.env.REACT_APP_HOST}${organization}/customer/${customerId}/field-visit/${fieldVisitId}`,
    {
      data: {
        ...value,
      },
    }
  );
};

export const editFieldVisit = ({
  organization = null,
  customerId = null,
  fieldVisitId,
  ...rest
}) => {
  return axiosWithAuth.put(
    `${process.env.REACT_APP_HOST}${organization}/customer/${customerId}/field-visit/${fieldVisitId}`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const putCardFieldVisit = async ({
  organization,
  customerId,
  checkinId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/actions`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const deleteCharges = async ({ organization, invoiceId }) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/invoices/${invoiceId}`
  );
};

export const assignRelmMangerFn = async ({
  organization = null,
  customerId,
  values,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/rel-manager`,
    { ...values }
  );
};

export const useRelmManger = (props) => {
  const client = useQueryClient();
  return useMutation(assignRelmMangerFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const unassignRelmMangerFn = async ({
  organization = null,
  customerId,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/rel-manager`
  );
};

export const useUnassignRelmManger = (props) => {
  const client = useQueryClient();
  return useMutation(unassignRelmMangerFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const assignWorkflowActionFn = async ({
  workflowId,
  start_workflow_from = 0,
  customerId,
  organization = null,
  action = "ASSIGN",
  ...rest
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/workflow`,
    {
      workflow_id: workflowId,
      action,
      start_workflow_from,
      ...rest,
    }
  );
};

export const useAssignWorkflowFn = ({ customerId }) => {
  return useMutation(assignWorkflowActionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
      queryClient.invalidateQueries(`${CO_TIMELINE_}${customerId}`);
    },
    onError: (err) => {},
  });
};

export const deleteDairyNoteFn = async ({
  customerId,
  _id,
  organization = null,
  entity,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/${entity}/${customerId}/notes/${_id}`
  );
};

export const useDeleteDairyNote = (props) => {
  const client = useQueryClient();
  return useMutation(deleteDairyNoteFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const getCustomerPaymentDtlFn = async ({ queryKey }) => {
  let { customerId, organization = null, filters, ...rest } = queryKey[1];
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/payments`,
    { params: { ...filters, ...rest } }
  );
};

export const useCustomerPaymentDtl = (props) => {
  let { customerId } = props;
  return useQuery(
    [`${CO_PAYMENT_}${customerId}`, { ...props }],
    getCustomerPaymentDtlFn,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export const customerActionFn = async ({
  id,
  organization = null,
  actionStatus = null,
  entity,
  ...rest
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/${entity}/${id}/workflow/${actionStatus}`,
    {
      ...rest,
    }
  );
};

export const useCustomerUpdateAction = () => {
  const client = useQueryClient();
  return useMutation(customerActionFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const addRequestInformation = async ({
  organization,
  customerId,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/request-information`,
    { ...data }
  );
};

export const updateRequestInformation = async ({
  organization,
  customerId,
  resourceId,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/request-information/${resourceId}`,
    { ...data }
  );
};

export const getCustomerRequestInformation = async ({
  organization,
  customerId,
  ...params
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/request-information`,
    {
      params,
    }
  );
};

export const getCustomerRequestInformationSingle = async ({
  organization,
  customerId,
  resourceId,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/request-information/${resourceId}`
  );
};

export const customerNextActionfn = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/actions`,
    {
      ...rest,
    }
  );
};

export const useNextAction = () => {
  const client = useQueryClient();
  return useMutation(customerNextActionfn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const removerGroupingFn = async ({
  organization = null,
  clientId = null,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${clientId}/change-process-group`
  );
};

export const updateGroupingFn = async ({
  organization = null,
  clientId = null,
  process_group_id = "",
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${clientId}/change-process-group`,
    { process_group_id }
  );
};

export const deleteClientGroupingFn = async ({
  organization = null,
  clientId = null,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${clientId}/remove-group`
  );
};

export const addKpiData = async ({ organization, values }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/kpi-maintenance/ADD`,
    {
      ...values,
    }
  );
};

export const removeKpiData = async ({ organization, values }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/kpi-maintenance/REMOVE`,
    {
      ...values,
    }
  );
};

//Bankruptcy

export const postBankruptchyData = async ({
  organization,
  values,
  customerId,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/bankruptcy?customerId=${customerId}`,
    {
      ...values,
    }
  );
};

export const postBankruptcyMaintanance = async ({ organization, values }) => {
  return await axiosWithAuth.post(`${HOST}${organization}/bankruptcy`, {
    ...values,
  });
};

export const editBankruptcyData = async ({
  organization,
  values,
  resourceId,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/bankruptcy/${resourceId}`,
    {
      ...values,
    }
  );
};

export const getMatchingConsumers = async ({ organization, filters }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/bankruptcy/get-matching-consumers?ssn=${filters.ssn}`
  );
};

export const getCustomerBankruptcy = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/bankruptcy`
  );
};

export const getAllBankruptcy = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization,
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/bankruptcy`, {
    params: {
      page: pageIndex + 1,
      sort: sortBy,
      page_size: pageSize,
      ...filters,
    },
  });
};

export const getBankruptcyInformationSingle = async ({
  organization,
  resourceId,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/bankruptcy/${resourceId}`
  );
};

export const getBankruptcyAudit = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/bankruptcy/${id}/audit`
  );
};

export const deleteBankruptcy = async ({ organization = null, resourceId }) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/bankruptcy/${resourceId}`
  );
};

export const addAttorneyBankruptcy = async ({
  organization = null,
  type,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/legal-attorney?type=${type}`,
    {
      ...rest,
    }
  );
};
export const getCheckSummery = async ({
  page = 1,
  page_size = 50,
  sort = "-_id",
  organization = null,
  id,
  entity: entity,
  ...filters
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/check-batch`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        page,
        page_size,
        sort,
        ...filters,
      },
    }
  );
};

export const getReportDownload = async ({ organization = null, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/download-generated`,
    {
      ...rest,
    }
  );
};

export const manualGroupingCat = async ({
  customerId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/manual-group`,
    {
      ...rest,
    }
  );
};

export const assignMultBatch = async ({
  organization = null,
  filter_type = null,
  filter_value = null,
  ...rest
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/check-maintenance`,
    {
      ...rest,
    },
    {
      params: {
        filter_type,
        filter_value,
      },
    }
  );
};

export const checkActionFn = async ({
  organization = null,
  batchId = null,
  ...rest
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/check-batch/${batchId}`,
    {
      ...rest,
    }
  );
};

export const manualGroupingLink = async ({
  customerId,
  organization = null,
  groupId = null,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/manual-group/${groupId}`
  );
};
//Remove the customer lock

export const removeCustomerLockFn = async ({ organization, clientId }) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${clientId}/reset-customer-lock`
  );
};

//Itemization API calls

export const postItemizationData = async ({ organization, values }) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/invoices/itemization`,
    {
      ...values,
    }
  );
};

export const putItemizationData = async ({
  organization,
  values,
  invoiceId,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/invoices/itemization/${invoiceId}`,
    {
      ...values,
    }
  );
};

export const getItemizationData = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/itemization/${customerId}`,

    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const postItemizationCommmissionData = async ({
  organization,
  value,
  customerId,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/invoices/with-and-without-commission-amount`,
    {
      ...value,
      customer_id: customerId,
    }
  );
};

export const patchItemizationData = async ({
  organization,
  date,
  invoiceId,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/invoices/itemization/${invoiceId}`,
    {
      itmzn_date: date.value,
    }
  );
};

export const getCallbackList = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/call-back`
  );
};

export const deleteSavedCards = async ({
  customerId,
  organization = null,
  saveId,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/saved-card/${saveId}`
  );
};
//Get The Recent
export const getRecentVisitCusInv = async ({ organization = null }) => {
  try {
    return await axiosWithAuth.get(`${HOST}user/recent-visits`, {
      params: {
        org_id: organization,
        type: "C",
      },
    });
  } catch (error) {
    console.error("Error fetching recent visits:", error);
    throw error;
  }
};

export const updateMaintainMapping = async ({
  organization = null,
  templateId,
  isActive,
}) => {
  try {
    const response = await axiosWithAuth.patch(
      `${HOST}${organization}/adhoc-template/${templateId}`,
      { is_active: isActive }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating maintain mapping:", error);
    throw error;
  }
};

export const deleteAdhocMapping = async ({
  organization = null,
  templateId,
}) => {
  try {
    const response = await axiosWithAuth.delete(
      `${HOST}${organization}/adhoc-template/${templateId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error updating maintain mapping:", error);
    throw error;
  }
};

//Update Cus updated Time

export const updateHideupdatedTime = async ({
  organization = null,
  customerId,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/update-tag`
  );
};

export const createAdjustment = async ({
  organization = null,
  invoiceId = null,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/invoices/${invoiceId}/adjustment`,
    data
  );
};

export const updateAdjustment = async ({
  organization = null,
  timelineId = null,
  invoiceId = null,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/invoices/${invoiceId}/adjustment/${timelineId}`,
    data
  );
};

export const deleteAdjustment = async ({
  organization = null,
  timelineId = null,
  invoiceId = null,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/invoices/${invoiceId}/adjustment/${timelineId}`
  );
};

export const customerMaxSettlementRate = async ({
  organization,
  customerId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const useMaxRateFields = (props) => {
  const client = useQueryClient();
  return useMutation(customerMaxSettlementRate, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const addRuleEngine = async ({ organization = null, ...data }) => {
  return await axiosWithAuth.post(`${HOST}${organization}/rule-engine`, data);
};
export const editRuleEngine = async (ruleId, organization = null, data) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/rule-engine/${ruleId}`,
    data
  );
};

export const getRuleEngine = async ({ organization = null }) => {
  return await axiosWithAuth.get(`${HOST}${organization}/rule-engine`);
};
export const getRuleEngineSingle = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(`${HOST}${organization}/rule-engine/${id}`);
};

export const getRevenueDashboardDownloaf = async ({
  organization = null,
  filters = {},
}) => {
  return await axiosWithAuth
    .get(`${HOST}organization/${organization}/revenue-dashboard/download`, {
      params: {
        ...filters,
      },
    })
    .then((response) => {
      toast.success(response.data.message);
    });
};
//Esign Service
export const addEsignAction = async ({ organization, customerId, ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/e-sign`,
    { ...data }
  );
};

export const updateEsignAction = async ({
  organization,
  customerId,
  resourceId,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/e-sign/${resourceId}`,
    { ...data }
  );
};
export const getCustomerEsignList = async ({ organization, customerId }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/e-sign`
  );
};

//Convience Fee Call Api
export const getConvienceFeePPL = async ({
  organization,
  customerId,
  amount,
  count,
  split_amount, // Accept split_amount if it's passed
  residual_type,
  paymentGatewayId,
}) => {
  try {
    const params = split_amount
      ? { amount, split_amount, residual_type, paymentGatewayId } // Use split_amount if available
      : { amount, count, paymentGatewayId }; // Otherwise use count

    const response = await axiosWithAuth.get(
      `${HOST}${organization}/customer/${customerId}/getFee/PAYMENT_PLAN`,
      {
        params, // Pass dynamic params based on the condition
      }
    );
    return response.data; // Return the response data directly
  } catch (error) {
    console.error("Error fetching recent fees:", error);
    throw error;
  }
};
export const getConvienceFeePAY = async ({
  organization,
  customerId,
  amount,
  count,
  paymentGatewayId,
}) => {
  try {
    const response = await axiosWithAuth.get(
      `${HOST}${organization}/customer/${customerId}/getFee/PAYMENT`,
      {
        params: {
          amount, // Query parameter for the amount
          paymentGatewayId, // Query parameter for the payment gateway ID
        },
      }
    );
    return response.data; // Return the response data directly
  } catch (error) {
    console.error("Error fetching recent fees:", error);
    throw error;
  }
};
