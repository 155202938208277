import React, { useState, useContext, useEffect } from "react";
import {
  LabelMedium,
  LabelSmall,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import { IconButton, SIZE, KIND } from "../../IconButton";
import CallBackLater from "../../../assets/img/svg/CallBackLater";
import PromiseToPayInfo from "../../../assets/img/svg/Information/PromisetoPayInfo";
import Payment from "../../../assets/img/svg/CustomerOverViewIcon/Payment";
import CallBackInfo from "../../../assets/img/svg/Information/CallBackInfo";
import PaymentInfo from "../../../assets/img/svg/Information/PaymentInfo";
import MentionInfo from "../../../assets/img/svg/Information/MentionInfo";
import EmailRepliesInfo from "../../../assets/img/svg/Information/EmailRepliesInfo";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import moment from "moment";
import { FormattedMessage, FormattedTime, useIntl } from "react-intl";
import { patchInformation } from "../../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PromiseToPay from "../../../assets/img/svg/PromiseToPay";

import PromiseToPayN from "../../../assets/img/svg/CusOverviewActionsIcon/PromiseToPayN";
import SmsInfo from "../../../assets/img/svg/Information/SmsInfo";
import Email from "../../../assets/img/svg/CustomerTemplate/Email";
import EmailInfo from "../../../assets/img/svg/Information/EmailInfo";
import SmsBounce from "../../../assets/img/svg/Information/SmsBounce";
import SmsStatus from "../../../assets/img/svg/Information/SmsStatus";
import ClarificaitonIcon from "../../../assets/img/svg/Information/ClarificaitonIcon";
import { StatefulTooltip } from "baseui/tooltip";
import RequestDocument from "../../../assets/img/svg/Information/RequestDocument";
import { getSubCategory } from "../../../services/documentSystem";
import _ from "lodash";

import Icon from "../../../components_v2/Icon";
import queryClient from "../../../providers/queryClient";

const SwitchingInformation = ({ type, data, userName }) => {
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);
  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;
  const subCategory = useQuery(
    [`sub-category-`],
    async () => {
      return await getSubCategory({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  switch (type) {
    case "CALLBACK":
      let timeSolt = data && data?.time;
      let slicedVal = timeSolt.replace("_", ":");
      let slicedFinalVal = slicedVal.replace("_", " ");

      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ" ? "info-read" : ""
              }`}
            >
              <CallBackInfo />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  Consumer{" "}
                  <span style={{ fontSize: "14px", fontWeight: "450" }}>
                    Requested
                  </span>{" "}
                  Call Back
                </ParagraphMedium>
              </div>

              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  Date:
                </ParagraphMedium>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {moment
                    .utc(data && data?.date)
                    ?.tz(timeZone)
                    ?.format(currentDefaultFormatDetails.date_format)}
                </div>
              </div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  Time Slot:
                </ParagraphMedium>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {slicedFinalVal}
                </div>
              </div>
              <div>
                <div className="cmt-clamp">
                  <span
                    style={{
                      paddingRight: "5px",
                    }}
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : "information-texts"
                    }`}
                  >
                    Comments:
                  </span>
                  <span
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : "information-texts"
                    }`}
                  >
                    {_.get(data, "comment")
                      ? _.get(data, "comment").replace(/&amp;/g, "&")
                      : " - "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            Consumer@ <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    case "PROMISE_TO_PAY":
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <PromiseToPayInfo />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  Consumer{" "}
                  <span style={{ fontSize: "14px", fontWeight: "450" }}>
                    Promised to Pay
                  </span>{" "}
                  below
                </ParagraphMedium>
              </div>

              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  Amount:
                </ParagraphMedium>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : ""
                    }`}
                  >
                    {data && data?.amount && data?.amount.currency}
                  </div>{" "}
                  <NumericFormat
                    displayType="text"
                    value={
                      data && data?.amount
                        ? Number(data && data?.amount && data?.amount.value)
                        : 0
                    }
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(value) => (
                      <ParagraphMedium
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {value}
                      </ParagraphMedium>
                    )}
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  By Date:
                </ParagraphMedium>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {moment
                    .utc(data && data?.date)
                    ?.tz(timeZone)
                    ?.format(currentDefaultFormatDetails.date_format)}
                </div>
              </div>

              <div>
                <div className="cmt-clamp">
                  <span
                    style={{
                      paddingRight: "5px",
                    }}
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : "information-texts"
                    }`}
                  >
                    Comments:
                  </span>
                  <span
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : "information-texts"
                    }`}
                  >
                    {data && data?.comment}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            Consumer@ <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );

    case "PAYMENT":
      let invoiceDataLastIndex = data?.invoices?.length;

      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <PaymentInfo />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  Consumer{" "}
                  <span style={{ fontSize: "14px", fontWeight: "450" }}>
                    Initiated
                  </span>{" "}
                  below Payment
                </ParagraphMedium>
              </div>

              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  Amount:
                </ParagraphMedium>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : ""
                    }`}
                  >
                    {data && data?.amount && data?.amount?.currency}
                  </div>{" "}
                  <NumericFormat
                    displayType="text"
                    value={
                      data && data?.amount
                        ? Number(data && data?.amount && data?.amount?.value)
                        : 0
                    }
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(value) => (
                      <ParagraphMedium
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {value}
                      </ParagraphMedium>
                    )}
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  Status:
                </ParagraphMedium>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {data && data?.status === "success" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "info-payment-success-read"
                            : "info-payment-success"
                        }`}
                      ></div>{" "}
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {" "}
                        Success{" "}
                      </div>
                    </div>
                  ) : data && data?.status === "failed" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div
                          className={`${
                            data && data?.notification_status === "READ"
                              ? "info-payment-failed-read"
                              : "info-payment-failed"
                          }`}
                        ></div>{" "}
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          className={`${
                            data && data?.notification_status === "READ"
                              ? "information-card-unread"
                              : ""
                          }`}
                        >
                          {" "}
                          Failed{" "}
                        </div>
                      </div>
                    </>
                  ) : data && data?.status === "initiated" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div
                          className={`${
                            data && data?.notification_status === "READ"
                              ? "info-payment-initiated-read"
                              : "info-payment-initiated"
                          }`}
                        ></div>{" "}
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          className={`${
                            data && data?.notification_status === "READ"
                              ? "information-card-unread"
                              : ""
                          }`}
                        >
                          {" "}
                          Initiated{" "}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}{" "}
                </div>
              </div>

              <div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <span
                    style={{
                      paddingRight: "5px",
                    }}
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : "information-texts"
                    }`}
                  >
                    Invoices#:
                  </span>
                  {data.invoices?.map((e, i) => {
                    return (
                      <>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                          className={`${
                            data && data?.notification_status === "READ"
                              ? "information-card-unread"
                              : "information-texts"
                          }`}
                        >
                          {e}
                        </div>
                        {invoiceDataLastIndex - 1 === i ? (
                          <span
                            className={`${
                              data && data?.notification_status === "READ"
                                ? "information-card-unread"
                                : "information-texts"
                            }`}
                          >
                            .
                          </span>
                        ) : (
                          <span
                            className={`${
                              data && data?.notification_status === "READ"
                                ? "information-card-unread"
                                : "information-texts"
                            }`}
                          >
                            , <span style={{ paddingLeft: "2px" }}></span>
                          </span>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            Consumer@ <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );

    case "SMS_REPLY":
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              {/* <PromiseToPayInfo /> */}
              {/* sms reply */}
              <SmsInfo />
              {data && data.is_updated_contact_invalid && (
                <div
                  style={{
                    backgroundColor: "#ffefee",
                    color:
                      data && data.update_contact_status_type === "VALID"
                        ? "#4CAF50"
                        : "#FD372A",
                    fontSize: "10px",
                    padding: "2px 5px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  {data && data.update_contact_status_type
                    ? data.update_contact_status_type
                    : "-"}
                </div>
              )}
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {/* Customer{" "} */}
                  <span style={{ fontSize: "14px", fontWeight: "450" }}>
                    {data?.reply_from}
                  </span>{" "}
                  messaged back
                  {/* below */}
                </ParagraphMedium>
              </div>

              <div style={{ display: "flex", gap: "4px" }}>
                {/* <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  By Date:
                </ParagraphMedium> */}
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {data?.reply_message?.body ? data?.reply_message?.body : ""}
                  {/* {moment
                    .utc(data && data?.date)
                    ?.tz(timeZone)
                    ?.format(currentDefaultFormatDetails.date_format)} */}
                </div>
              </div>
            </div>
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    case "EMAIL_REPLY":
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <EmailRepliesInfo />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {/* {data?.heading} */}
                  <div>
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      {data?.reply_from}{" "}
                    </span>
                    replied to your message{" "}
                    {data && data.reply_message && data.reply_message.body
                      ? data.reply_message.body
                      : ""}
                  </div>
                  <div>{data?.msg}</div>
                </ParagraphMedium>
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
        </>
      );

    case "MENTION":
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <MentionInfo />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <ParagraphMedium
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {userName} mentioned you in{" "}
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          className={`${
                            data && data?.notification_status === "READ"
                              ? "information-card-unread"
                              : ""
                          }`}
                        >
                          {_.get(data, "mention_place") === "SMS"
                            ? "SMS"
                            : _.capitalize(
                                _.get(data, "mention_place", "").replace(
                                  /_/g,
                                  " "
                                )
                              )}
                        </span>
                      </ParagraphMedium>
                      {/* <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                       
                      </div> */}
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      {/* {userMention.map((e) => (
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          className={`${
                            data && data?.notification_status === "READ"
                              ? "information-card-unread"
                              : ""
                          }`}
                        >
                          {e}
                        </div>
                      ))} */}
                      {data &&
                        data?.comment &&
                        data?.comment?.message?.replace(/(<([^>]+)>)/gi, "")}
                    </div>
                  </div>
                  <div>{data?.msg}</div>
                </ParagraphMedium>
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // color: "#787878",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            {userName}@{" "}
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );

    case "UPDATE_PROMISE_TO_PAY":
      // const numberFormat = useNumericFormat({
      //   thousandsGroupStyle: currencyGroupStyle,
      //   decimalScale: 2,
      //   fixedDecimalScale: true,
      //   thousandSeparator: currencySeparator,
      //   decimalSeparator: currencyDecimalSeparator,
      // });
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              {/* SVG */}
              <PromiseToPayInfo />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        style={{ display: "flex" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Below promise status is{" "}
                        {data.promise_status === "BROKEN" ? (
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              paddingLeft: "4px",
                              color: "#ED1B34",
                            }}
                            className={`${
                              data && data?.notification_status === "READ"
                                ? "information-card-unread"
                                : ""
                            }`}
                          >
                            Broken
                          </div>
                        ) : data.promise_status === "PARTIAL" ? (
                          <div
                            style={{
                              fontSize: "14px",
                              paddingLeft: "4px",
                              fontWeight: "500",
                              color: "#FFBB00",
                            }}
                            className={`${
                              data && data?.notification_status === "READ"
                                ? "information-card-unread"
                                : ""
                            }`}
                          >
                            Partial
                          </div>
                        ) : data.promise_status === "KEPT" ? (
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              paddingLeft: "4px",
                              color: "#4CAF50",
                            }}
                            className={`${
                              data && data?.notification_status === "READ"
                                ? "information-card-unread"
                                : ""
                            }`}
                          >
                            Kept
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Promise Amount:{" "}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          gap: "4px",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data.amount.currency}

                        <NumericFormat
                          displayType="text"
                          value={
                            data && data?.amount
                              ? Number(
                                  data && data?.amount && data?.amount?.value
                                )
                              : 0
                          }
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(value) => (
                            <ParagraphMedium
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                              className={`${
                                data && data?.notification_status === "READ"
                                  ? "information-card-unread"
                                  : ""
                              }`}
                            >
                              {value}
                            </ParagraphMedium>
                          )}
                        />
                      </div>
                    </div>{" "}
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        By Date:{" "}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {moment
                          .utc(data && data?.date)
                          ?.tz(timeZone)
                          ?.format(currentDefaultFormatDetails.date_format)}
                      </div>
                    </div>{" "}
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Payment Amount:{" "}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          gap: "4px",
                        }}
                        className={`${
                          data && data?._status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data?.payment_amount.currency}{" "}
                        {/* {data?.payment_amount.value} */}
                        <NumericFormat
                          displayType="text"
                          value={
                            data && data?.amount
                              ? Number(data?.payment_amount?.value)
                              : 0
                          }
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(value) => (
                            <ParagraphMedium
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                              className={`${
                                data && data?.notification_status === "READ"
                                  ? "information-card-unread"
                                  : ""
                              }`}
                            >
                              {value}
                            </ParagraphMedium>
                          )}
                        />
                      </div>
                    </div>{" "}
                    {/* <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Below promise status is {data.notification_status}
                      </div>
                    </div> */}
                    <div style={{ display: "flex", gap: "5px" }}>
                      {/* {userMention.map((e) => (
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                            className={`${
                              data && data?.notification_status === "READ"
                                ? "information-card-unread"
                                : ""
                            }`}
                          >
                            {e}
                          </div>
                        ))} */}
                      {/* {data &&
                        data?.comment &&
                        data?.comment?.message?.replace(/(<([^>]+)>)/gi, "")} */}
                    </div>
                  </div>
                  <div>{data?.msg}</div>
                </ParagraphMedium>
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // color: "#787878",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            <div>SYSTEM @ </div>{" "}
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );

    case "EMAIL_BOUNCED": {
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <EmailInfo />
              {/* <Email /> */}
              {/* SVG */}
              {/* <PromiseToPayInfo /> */}
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Email to{" "}
                      </div>
                      {data &&
                        data.bounced_recipients.map((e) => (
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              display: "flex",
                              gap: "4px",
                            }}
                            className={`${
                              data && data?.notification_status === "READ"
                                ? "information-card-unread"
                                : ""
                            }`}
                          >
                            {e}
                          </div>
                        ))}
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          gap: "4px",
                        }}
                      >
                        Bounced
                      </div>
                    </div>{" "}
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {/* By Date:{" "} */}
                        Email with Subject{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {data && data.email_subject
                            ? `"${data.email_subject}"`
                            : ""}
                        </span>{" "}
                        couldn't be delivered. Kindly check and resend email
                      </div>
                      {/* <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {moment
                          .utc(data && data?.date)
                          ?.tz(timeZone)
                          ?.format(currentDefaultFormatDetails.date_format)}
                      </div> */}
                    </div>{" "}
                    {/* <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Payment Amount:{" "}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          gap: "4px",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                      </div>
                    </div> */}
                  </div>
                  <div>{data?.msg}</div>
                </ParagraphMedium>
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // color: "#787878",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            {/* <div>SYSTEM @ </div>{" "} */}
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    }

    case "SMS_FAILED": {
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "sms-read"
                  : "sms-unread"
              }`}
            >
              {/* SVG */}
              {/* <PromiseToPayInfo /> */}
              {/* <SmsInfo /> */}
              <SmsBounce />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Message to{" "}
                      </div>
                      {data &&
                        data?.bounced_recipients.map((e) => (
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              display: "flex",
                              gap: "4px",
                            }}
                            className={`${
                              data && data?.notification_status === "READ"
                                ? "information-card-unread"
                                : ""
                            }`}
                          >
                            {e}
                          </div>
                        ))}
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          gap: "4px",
                        }}
                      >
                        Failed
                      </div>
                    </div>{" "}
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {/* By Date:{" "} */}
                        Message couldn't be delivered due to reason -{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {data?.sms_failed_reason
                            ? data?.sms_failed_reason
                            : ""}
                        </span>{" "}
                        . Kindly check and resend sms
                      </div>
                      {/* <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {moment
                          .utc(data && data?.date)
                          ?.tz(timeZone)
                          ?.format(currentDefaultFormatDetails.date_format)}
                      </div> */}
                    </div>{" "}
                    {/* <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Payment Amount:{" "}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          gap: "4px",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                      </div>
                    </div> */}
                  </div>
                  <div>{data?.msg}</div>
                </ParagraphMedium>
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // color: "#787878",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            {/* <div>SYSTEM @ </div>{" "} */}
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    }
    case "PAYMENT_PLAN":
      let findFrequency =
        data && data.payment_plan_frequency
          ? referenceData["payment_plan_frequency"].find(
              (e) => e.id == data.payment_plan_frequency
            )
          : [];
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <PromiseToPayInfo />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  <span style={{ fontSize: "14px", fontWeight: "450" }}>
                    Consumer Submitted Payment Plan
                  </span>
                </ParagraphMedium>
              </div>

              <div style={{ width: "83%" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  {findFrequency && findFrequency.label} Payment plan with
                  Installment Amount of{" "}
                  <b>
                    USD{" "}
                    <NumericFormat
                      displayType="text"
                      value={
                        data && data?.amount
                          ? Number(data && data?.amount && data?.amount.value)
                          : 0
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(value) => (
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          className={`${
                            data && data?.notification_status === "READ"
                              ? "information-card-unread"
                              : ""
                          }`}
                        >
                          {value}
                        </span>
                      )}
                    />
                  </b>{" "}
                  starting from{" "}
                  <b>
                    {moment
                      .tz(data?.date, timeZone)
                      .format(currentDefaultFormatDetails.date_format)}
                  </b>{" "}
                  till{" "}
                  <b>
                    {moment
                      .tz(data?.payment_plan_end_date, timeZone)
                      .format(currentDefaultFormatDetails.date_format)}
                  </b>
                </ParagraphMedium>
              </div>

              <div>
                <div className="cmt-clamp">
                  <span
                    style={{
                      paddingRight: "5px",
                    }}
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : "information-texts"
                    }`}
                  >
                    Comments:
                  </span>
                  <span
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : "information-texts"
                    }`}
                  >
                    {data && data?.comment}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            Consumer@ <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );

    // case "CONTACT_STATUS":
    case "CONTACT_STATUS":
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <SmsStatus />
              <div className="status_tag">DNC</div>
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {/* {data?.heading} */}
                  <div>
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      +91 6587412368
                    </span>{" "}
                    messaged back{" "}
                  </div>
                  <div>{data?.msg}</div>
                  Stop
                </ParagraphMedium>
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
        </>
      );

    case "CLARIFICATION_REQUEST_INFO_ASSIGNED":
    case "CLARIFICATION_REQUEST_INFO_UPDATED":
    case "CLARIFICATION_REQUEST_INFO_STATUS_CHANGE": {
      let clarificationCategory = referenceData[
        "request_information_sub_category_list"
      ].find((e) => e.id == data?.sub_category);

      const { assign_type, assigned_to, created_by } = data;

      const assignedTo =
        assign_type === "BUSINESS_UNIT" && referenceData["business_unit_list"]
          ? referenceData["business_unit_list"].find(
              (e) => e.id === assigned_to
            )
          : referenceData["users"].find((e) => e && e.id === assigned_to);

      const createdBy = referenceData["users"].find(
        (e) => e && e.id === created_by
      );

      let raisedDate = moment
        .utc(data && data.review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const statusColor = {
        CLOSED: "#0FB158",
        SUBMITTED: "#7D7C7C",
        IN_PROGRESS: "#FBBD04",
        OPEN: "#FD372A",
      };

      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <ClarificaitonIcon />
            </div>

            <div>
              <div>
                <LabelMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {type === "CLARIFICATION_REQUEST_INFO_ASSIGNED" ? (
                    `Clarification (${
                      clarificationCategory && clarificationCategory?.label
                        ? clarificationCategory?.label
                        : ""
                    }) is Assigned to ${
                      assignedTo && assignedTo.label ? assignedTo.label : ""
                    }`
                  ) : (
                    <></>
                  )}
                  {type === "CLARIFICATION_REQUEST_INFO_UPDATED" ? (
                    `${
                      createdBy && createdBy.label ? createdBy.label : ""
                    } Updated Clarification (${
                      clarificationCategory && clarificationCategory?.label
                        ? clarificationCategory?.label
                        : ""
                    })`
                  ) : (
                    <></>
                  )}
                  {type === "CLARIFICATION_REQUEST_INFO_STATUS_CHANGE" ? (
                    <>
                      Clarification (
                      {clarificationCategory && clarificationCategory?.label
                        ? clarificationCategory?.label
                        : ""}
                      ) is <FormattedMessage id={data?.req_info_status} />
                    </>
                  ) : (
                    <></>
                  )}
                </LabelMedium>
                {type === "CLARIFICATION_REQUEST_INFO_UPDATED" ? (
                  <div className="request_head_line">
                    <ParagraphMedium
                      $style={{ color: "#ADADAD" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Status :
                    </ParagraphMedium>
                    <div
                      className="request_status"
                      style={{
                        background: statusColor[data?.req_info_status]
                          ? statusColor[data?.req_info_status]
                          : "#FBBD04",
                      }}
                    ></div>
                    <ParagraphMedium
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      {_.capitalize(_.replace(data?.req_info_status, "_", " "))}
                    </ParagraphMedium>
                  </div>
                ) : (
                  <></>
                )}
                {data.review_date ? (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Review Date :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {raisedDate}
                      </span>
                    </LabelSmall>
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ display: "flex", gap: "4px" }}>
                  {data.attachments &&
                  Array.isArray(data.attachments) &&
                  data.attachments.length > 0 ? (
                    <>
                      <ParagraphMedium
                        $style={{ color: "#787878" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Documents:
                      </ParagraphMedium>
                      <ParagraphMedium
                        $style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data.attachments[0] ? data.attachments[0].name : ""}
                        {data.attachments.length > 1 ? (
                          <span className="count_notification">
                            (+{data.attachments.length - 1} more)
                          </span>
                        ) : (
                          <></>
                        )}
                      </ParagraphMedium>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {type === "CLARIFICATION_REQUEST_INFO_ASSIGNED" ? (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Details :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data?.details ? data?.details : "-"}
                      </span>
                    </LabelSmall>
                  </div>
                ) : (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Comments :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data.comment
                          ? data.comment.replace(/(<([^>]+)>)/gi, "")
                          : "-"}
                      </span>
                    </LabelSmall>
                  </div>
                )}
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // color: "#787878",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            {createdBy && createdBy.label ? createdBy.label : ""} @{" "}
            <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    }

    case "DOCUMENT_REQUEST_INFO_ASSIGNED":
    case "DOCUMENT_REQUEST_INFO_UPDATED":
    case "DOCUMENT_REQUEST_INFO_STATUS_CHANGE": {
      let docCategory =
        subCategory &&
        subCategory.data &&
        subCategory.data.data &&
        subCategory.data.data.docs.find((e) => e.id == data?.sub_category);

      const { assign_type, assigned_to, created_by } = data;

      const assignedTo =
        assign_type === "BUSINESS_UNIT" && referenceData["business_unit_list"]
          ? referenceData["business_unit_list"].find(
              (e) => e.id === assigned_to
            )
          : referenceData["users"].find((e) => e && e.id === assigned_to);

      const createdBy = referenceData["users"].find(
        (e) => e && e.id === created_by
      );

      let raisedDate = moment
        .utc(data && data.review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const statusColor = {
        CLOSED: "#0FB158",
        SUBMITTED: "#7D7C7C",
        IN_PROGRESS: "#FBBD04",
        OPEN: "#FD372A",
      };

      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <RequestDocument />
            </div>

            <div>
              <div>
                <LabelMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {type === "DOCUMENT_REQUEST_INFO_ASSIGNED" ? (
                    `Document (${
                      docCategory && docCategory?.label
                        ? docCategory?.label
                        : ""
                    }) is Assigned to ${
                      assignedTo && assignedTo.label ? assignedTo.label : ""
                    }`
                  ) : (
                    <></>
                  )}
                  {type === "DOCUMENT_REQUEST_INFO_UPDATED" ? (
                    `${
                      createdBy && createdBy.label ? createdBy.label : ""
                    } Updated Document (${
                      docCategory && docCategory?.label
                        ? docCategory?.label
                        : ""
                    })`
                  ) : (
                    <></>
                  )}
                  {type === "DOCUMENT_REQUEST_INFO_STATUS_CHANGE" ? (
                    <>
                      Document (
                      {docCategory && docCategory?.label
                        ? docCategory?.label
                        : ""}
                      ) is <FormattedMessage id={data?.req_info_status} />
                    </>
                  ) : (
                    <></>
                  )}
                </LabelMedium>
                {type === "DOCUMENT_REQUEST_INFO_UPDATED" ? (
                  <div className="request_head_line">
                    <ParagraphMedium
                      $style={{ color: "#ADADAD" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Status :
                    </ParagraphMedium>
                    <div
                      className="request_status"
                      style={{
                        background: statusColor[data?.req_info_status]
                          ? statusColor[data?.req_info_status]
                          : "#FBBD04",
                      }}
                    ></div>
                    <ParagraphMedium
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      {_.capitalize(_.replace(data?.req_info_status, "_", " "))}
                    </ParagraphMedium>
                  </div>
                ) : (
                  <></>
                )}
                {data.review_date ? (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Review Date :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {raisedDate}
                      </span>
                    </LabelSmall>
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ display: "flex", gap: "4px" }}>
                  {data.attachments &&
                  Array.isArray(data.attachments) &&
                  data.attachments.length > 0 ? (
                    <>
                      <ParagraphMedium
                        $style={{ color: "#787878" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Documents:
                      </ParagraphMedium>
                      <ParagraphMedium
                        $style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data.attachments[0] ? data.attachments[0].name : ""}
                        {data.attachments.length > 1 ? (
                          <span className="count_notification">
                            (+{data.attachments.length - 1} more)
                          </span>
                        ) : (
                          <></>
                        )}
                      </ParagraphMedium>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {type === "DOCUMENT_REQUEST_INFO_ASSIGNED" ? (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Details :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data?.details ? data?.details : "-"}
                      </span>
                    </LabelSmall>
                  </div>
                ) : (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Comments :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data.comment
                          ? data.comment.replace(/(<([^>]+)>)/gi, "")
                          : "-"}
                      </span>
                    </LabelSmall>
                  </div>
                )}
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // color: "#787878",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            {createdBy && createdBy.label ? createdBy.label : ""} @{" "}
            <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    }
    case "CON_WEB_DIS":
    case "DISPUTE_ASSIGNED":
    case "DISPUTE_UPDATED":
    case "DISPUTE_STATUS_CHANGE": {
      const { assign_type, assigned_to, created_by, sub_category } = data;

      let docCategory =
        referenceData["dispute_type"] &&
        referenceData["dispute_type"].find((e) => e.id === sub_category);

      const assignedTo =
        assign_type === "BUSINESS_UNIT" && referenceData["business_unit_list"]
          ? referenceData["business_unit_list"].find(
              (e) => e.id === assigned_to
            )
          : referenceData["users"].find((e) => e && e.id === assigned_to);

      const createdBy = referenceData["users"].find(
        (e) => e && e.id === created_by
      );

      let raisedDate = moment
        .utc(data && data.review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const statusColor = {
        CLOSED: "#0FB158",
        SUBMITTED: "#7D7C7C",
        IN_PROGRESS: "#FBBD04",
        OPEN: "#FD372A",
      };

      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <Icon icon="dispute" size={34} />
            </div>

            <div>
              <div>
                <LabelMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  {type === "DISPUTE_ASSIGNED" ? (
                    `Dispute (${
                      docCategory && docCategory?.label
                        ? docCategory?.label
                        : ""
                    }) is Assigned to ${
                      assignedTo && assignedTo.label ? assignedTo.label : ""
                    }`
                  ) : (
                    <></>
                  )}
                  {type === "DISPUTE_UPDATED" ? (
                    `${
                      createdBy && createdBy.label ? createdBy.label : ""
                    } Updated Dispute (${
                      docCategory && docCategory?.label
                        ? docCategory?.label
                        : ""
                    })`
                  ) : (
                    <></>
                  )}
                  {type === "DISPUTE_STATUS_CHANGE" ? (
                    <>
                      Dispute (
                      {docCategory && docCategory?.label
                        ? docCategory?.label
                        : ""}
                      ) is <FormattedMessage id={data?.req_info_status} />
                    </>
                  ) : (
                    <></>
                  )}
                </LabelMedium>
                {type === "DISPUTE_UPDATED" ? (
                  <div className="request_head_line">
                    <ParagraphMedium
                      $style={{ color: "#ADADAD" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Status :
                    </ParagraphMedium>
                    <div
                      className="request_status"
                      style={{
                        background: statusColor[data?.req_info_status]
                          ? statusColor[data?.req_info_status]
                          : "#FBBD04",
                      }}
                    ></div>
                    <ParagraphMedium
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      {_.capitalize(_.replace(data?.req_info_status, "_", " "))}
                    </ParagraphMedium>
                  </div>
                ) : (
                  <></>
                )}
                {data.review_date ? (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Review Date :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {raisedDate}
                      </span>
                    </LabelSmall>
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ display: "flex", gap: "4px" }}>
                  {data.attachments &&
                  Array.isArray(data.attachments) &&
                  data.attachments.length > 0 ? (
                    <>
                      <ParagraphMedium
                        $style={{ color: "#787878" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        Documents:
                      </ParagraphMedium>
                      <ParagraphMedium
                        $style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data.attachments[0] ? data.attachments[0].name : ""}
                        {data.attachments.length > 1 ? (
                          <span className="count_notification">
                            (+{data.attachments.length - 1} more)
                          </span>
                        ) : (
                          <></>
                        )}
                      </ParagraphMedium>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {type === "DISPUTE_ASSIGNED" ? (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Details :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data?.details ? data?.details : "-"}
                      </span>
                    </LabelSmall>
                  </div>
                ) : (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <LabelSmall
                      $style={{ color: "#787878" }}
                      className={`${
                        data && data?.notification_status === "READ"
                          ? "information-card-unread"
                          : ""
                      }`}
                    >
                      Comments :&nbsp;
                      <span
                        style={{ color: "#333860" }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {data.comment
                          ? data.comment.replace(/(<([^>]+)>)/gi, "")
                          : "-"}
                      </span>
                    </LabelSmall>
                  </div>
                )}
              </div>
            </div>
            {/* CUSTOMER DATAS */}
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // color: "#787878",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            {createdBy && createdBy.label ? createdBy.label : "Consumer"} @{" "}
            <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    }

    case "CCL_APLL":
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.notification_status === "READ"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <PaymentInfo />
            </div>

            <div>
              <div style={{ display: "flex", gap: "4px" }}>
                <ParagraphMedium
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  Instalment Amount:
                </ParagraphMedium>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : ""
                  }`}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : ""
                    }`}
                  >
                    {data && data?.amount && data?.amount?.currency}
                  </div>{" "}
                  <NumericFormat
                    displayType="text"
                    value={
                      data && data?.amount
                        ? Number(data && data?.amount && data?.amount?.value)
                        : 0
                    }
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(value) => (
                      <ParagraphMedium
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        className={`${
                          data && data?.notification_status === "READ"
                            ? "information-card-unread"
                            : ""
                        }`}
                      >
                        {value}
                      </ParagraphMedium>
                    )}
                  />
                </div>
              </div>
              {data && data?.date && (
                <div style={{ display: "flex", gap: "4px" }}>
                  <ParagraphMedium
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : "information-texts"
                    }`}
                  >
                    Date:
                  </ParagraphMedium>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className={`${
                      data && data?.notification_status === "READ"
                        ? "information-card-unread"
                        : ""
                    }`}
                  >
                    {moment
                      .utc(data && data?.date)
                      ?.tz(timeZone)
                      ?.format(currentDefaultFormatDetails.date_format)}
                  </div>
                </div>
              )}
              <div className="cmt-clamp">
                <span
                  style={{
                    paddingRight: "5px",
                  }}
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  Comments:
                </span>
                <span
                  className={`${
                    data && data?.notification_status === "READ"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  {_.get(data, "comment")
                    ? _.get(data, "comment").replace(/&amp;/g, "&")
                    : " - "}
                </span>
              </div>
            </div>
          </div>
          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            Consumer@ <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );

    default:
      return null;
  }
};

const Information = ({
  data,
  setInfoCount,
  infoCount,
  isLoading,
  checked,
  close,
}) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userMention, setuserMention] = useState([]);
  let dispatch = useDispatch();
  const { referenceData, users, currentOrganization } =
    useContext(MaxyfiContext);

  // options={referenceData?.payment_status}

  const updateRead = useMutation(
    (data) =>
      patchInformation({
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data?.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`NOTIFICATION_${currentOrganization}`);
      },
    }
  );

  // MENTION USER DATA

  useEffect(() => {
    if (data) {
      let user = "";
      users.filter((e) => {
        if (e.id === data?.created_by) {
          user = e.displayName;
        }
      });
      setUserName(user);
    }
  }, [data]);

  // COMMENT MENTION DATA

  // useEffect(() => {
  //   if (data) {
  //
  //     let userMention = [];
  //     users.filter((e) => {
  //       data &&
  //         data.comment &&
  //         data?.comment?.mention_users?.map((i) => {
  //           if (e.id === i) {
  //             userMention.push(e && e?.displayName);
  //           }
  //         });
  //     });
  //     setuserMention(userMention);
  //   }
  // }, [data]);

  return (
    <>
      {/* CARD STARTS*/}
      <div
        style={{
          padding: "10px 15px",
          background: `${
            data && data?.notification_status === "READ" ? "#FCFCFC" : "#F5F7FD"
          }`,
          borderTop: "1px solid #eeeeee",
        }}
        className="calendar-card"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ParagraphLarge
            $style={{
              fontWeight: "500",
              width: "80%",
              cursor: "pointer",
            }}
            className={`${
              data && data?.notification_status === "READ"
                ? "information-card-unread"
                : ""
            }`}
            onClick={() => {
              navigate(`/customers/${data?.customer_id}`);
              close.close();
            }}
          >
            {data?.customer_name}
          </ParagraphLarge>
          <div className="calendar-btn">
            <IconButton
              size={SIZE.mini}
              kind={KIND.tertiary}
              onClick={async () => {
                let markAsRead = {
                  notification_type: data && data?.notification_type,
                  notification_id: data && data?._id,
                  customer_id: data && data?.customer_id,
                  notification_status: data && data?.notification_status,
                };

                await updateRead.mutateAsync({ ...markAsRead });
              }}
            >
              {data && data?.notification_status === "READ" ? (
                <>
                  <i className="mx-icon-unview" />
                </>
              ) : (
                <>
                  <i className="mx-icon-view" />
                </>
              )}
            </IconButton>
          </div>
        </div>

        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/customers/${data?.customer_id}`);
            close.close();
          }}
        >
          <SwitchingInformation
            type={data?.notification_type}
            data={data}
            userName={userName}
            // userMention={userMention}
          />
        </div>
      </div>
      {/* CARD ENDS*/}
    </>
  );
};

export default Information;
