import { ParagraphMedium, ParagraphSmall } from "baseui/typography";
import React from "react";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";

const AllocatedReference = (props) => {
  let { value, column, alignment, accessor, row, color, ...rest } = props;
  value = _.get(row.original, accessor, 0);
  const filterClientRef =
    Array.isArray(value) && value.length > 0 ? value.filter((i) => i.cr) : [];

  let data = value?.map((i) => {
    return i.cr;
  });

  let tooltipData = [...data];
  tooltipData.shift();

  return (
    <div
      style={{
        display: "flex",
        // gap: "10px"
        gap: "3px",
        width: "100%",
        paddingRight: "2px",
        justifyContent: alignment,
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <StatefulTooltip
        accessibilityType={"tooltip"}
        content={
          <div style={{ maxWidth: "320px" }}>
            {_.get(filterClientRef, "[0].cr", "")}
          </div>
        }
      >
        <ParagraphSmall
          $style={{
            color: "#787878",
            width: `${filterClientRef?.length > 1 ? "60px" : "85px"}`,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {_.get(filterClientRef, "[0].cr", "")}
        </ParagraphSmall>
      </StatefulTooltip>

      {filterClientRef && filterClientRef?.length > 1 ? (
        <StatefulTooltip
          accessibilityType={"tooltip"}
          content={
            <div style={{ maxWidth: "320px" }}>{`${tooltipData
              .join(", ")
              .toString()}${"."}`}</div>
          }

          // content={props?.value?.map(
          //   (e) => `${e?.invoice_number.join(", ").toString()}${"."}`
          // )}
          // content={`${tootltipData.join(", ").toString()}${"."}`}
        >
          <div
            style={{
              padding: "0px 3px",
              height: "18px",
              border: "1px solid #516beb",
              borderRadius: "5px",
            }}
          >
            {filterClientRef && filterClientRef?.length - 1}+
          </div>
        </StatefulTooltip>
      ) : (
        ""
      )}
    </div>
  );
};

export default AllocatedReference;
