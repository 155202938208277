import React from "react";
import queryClient from "../../../../providers/queryClient";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setDrawerState } from "../../../../redux/actions";
import AddPayment from "../../Payment/AddPayment";
import { NEW } from "../../../../constants";
import {
  addSMSAttachment,
  smsUnSelectedDocumentData,
  updateSMSAttachment,
} from "../../../../redux/customerOverviewDrawer/SMSAction/actions";

const FieldVisitPaymentDataLayer = (props) => {
  let { service = () => {}, onSuccess = () => {}, ...rest } = props;

  return (
    <>
      <AddPayment
        type={NEW}
        onSuccess={() => {
          onSuccess();
        }}
        services={service}
        {...rest}
        fileObject={addSMSAttachment}
        removeAttchFile={smsUnSelectedDocumentData}
        updatingDeskUpload={updateSMSAttachment}
      />
    </>
  );
};

export default FieldVisitPaymentDataLayer;
