import React, { useState, useEffect, useContext, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { StatefulPopover } from "baseui/popover";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import _ from "lodash";
import { ParagraphSmall } from "baseui/typography";
import { useDispatch } from "react-redux";

import { Icon, Typography } from "../../../../components_v2";
import { SelectBox } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import PromiseToPay from "../Forms/PromiseToPay";
import CallBackLater from "../Forms/CallBackLater";
import CallUnsuccessful from "../Forms/CallUnsuccessful";
import PaymentPlan from "../Forms/PaymentPlan";
import FieldVisit from "../Forms/FieldVisit";
import Dispute from "../Forms/Dispute";
import Pay from "../Forms/Pay";
import Note from "../Forms/Note";
import AgentActionAlert from "../../../../components_v2/AgentActionAlert";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import {
  CALLBACKLATER,
  DISPUTE,
  PAY,
  PROMISE,
  PLANNED,
  UNSUCCESSFUL,
  ACT_PROMISE_TO_PAY,
  ACT_PAYMENT_PLAN,
  ACT_CALL_BACK_LATER,
  ACT_UNSUCCESSFUL,
  ACT_PAYMENT,
  ACT_RECORD_DISPUTE,
  ACT_FIELD_VISIT,
  ACT_NOTES,
} from "../../../../constants";
import { makeCall } from "../../../../services";
import { CUSTOMER_OVERVIEW_, CO_TIMELINE_ } from "../../../../constants";
import queryClient from "../../../../providers/queryClient";

import { executeGenericCustomerAction } from "../../../../services";
import { setDrawerState } from "../../../../redux/actions";

const Outcome = (props) => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const { type, formValues = {}, ...rest } = props;

  const setDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const onSuccessRefetch = () => {
    queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
    queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
    setDrawer();
  };

  switch (type) {
    case ACT_CALL_BACK_LATER: {
      return (
        <CallBackLater
          {...rest}
          formValues={{ ...formValues, outcome: ACT_CALL_BACK_LATER }}
          service={executeGenericCustomerAction}
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isConsent={false}
          isContent={false}
          is_inbound_call={true}
        />
      );
    }

    case ACT_RECORD_DISPUTE: {
      return (
        <Dispute
          {...rest}
          formValues={{ ...formValues, outcome: ACT_RECORD_DISPUTE }}
          service={executeGenericCustomerAction}
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isConsent={false}
          isContent={false}
          is_inbound_call={true}
        />
      );
    }

    case ACT_PAYMENT: {
      return (
        <Pay
          {...rest}
          formValues={{ ...formValues, outcome: ACT_PAYMENT }}
          service={executeGenericCustomerAction}
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isConsent={false}
          isContent={false}
          is_inbound_call={true}
        />
      );
    }

    case ACT_PROMISE_TO_PAY: {
      return (
        <PromiseToPay
          {...rest}
          formValues={{ ...formValues, outcome: ACT_PROMISE_TO_PAY }}
          service={executeGenericCustomerAction}
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isConsent={false}
          isContent={false}
          is_inbound_call={true}
        />
      );
    }

    case ACT_PAYMENT_PLAN: {
      return (
        <PaymentPlan
          {...rest}
          formValues={{ ...formValues, outcome: ACT_PAYMENT_PLAN }}
          service={executeGenericCustomerAction}
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isConsent={false}
          isContent={false}
          is_inbound_call={true}
        />
      );
    }

    case ACT_FIELD_VISIT: {
      return (
        <FieldVisit
          {...rest}
          formValues={{ ...formValues, outcome: ACT_FIELD_VISIT }}
          service={executeGenericCustomerAction}
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isConsent={false}
          isContent={false}
          is_inbound_call={true}
        />
      );
    }

    case ACT_NOTES: {
      return (
        <Note
          {...rest}
          formValues={{ ...formValues, outcome: ACT_NOTES }}
          service={executeGenericCustomerAction}
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          isConsent={false}
          is_inbound_call={true}
        />
      );
    }
    default:
      return null;
  }
};

const outcomeList = [
  { label: "PTP", icon: "review_promise", type: ACT_PROMISE_TO_PAY },
  { label: "CBL", icon: "callback_later", type: ACT_CALL_BACK_LATER },
  { label: "DIS", icon: "dispute", type: ACT_RECORD_DISPUTE },
  { label: "PPL", icon: "payment_plan", type: ACT_PAYMENT_PLAN },
  { label: "FV", icon: "field_visit", type: ACT_FIELD_VISIT },
  { label: "Pay", icon: "pay", type: ACT_PAYMENT },
  { label: "Note", icon: "note", type: ACT_NOTES },
];

const GenericAction = () => {
  const intl = useIntl();
  const { customerId } = useParams();
  const { referenceData } = useContext(MaxyfiContext);
  const [otherLocation, setOtherLocation] = useState();
  const [state, setState] = useState({
    action_source: [],
    recipient: [],
    outcome: null,
    is_inbound_call: true,
    other_source: "",
    custom_address: "",
  });

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const contactConf = {
    SMS: {
      keys: ["phone"],
      valid: "sms_status",
    },
    CALL: {
      keys: ["phone", "landline"],
      valid: "call_status",
    },
    EMAIL: {
      keys: ["email"],
      valid: "email_status",
    },
    LETTER: {
      keys: ["address"],
      valid: "address_status",
    },
    WHATSAPP: {
      keys: ["phone"],
      valid: "whatsapp_status",
    },
  };

  console.log(customerData, "customerData");

  const getSourceLabel = (id, intl) => {
    switch (id) {
      case "EMAIL":
        return intl.formatMessage({ id: "choose_email" });
      case "SMS":
        return intl.formatMessage({ id: "choose_phone_number" });
      case "WHATSAPP":
        return intl.formatMessage({ id: "choose_phone_number" });
      case "LETTER":
        return intl.formatMessage({ id: "choose_address" });
      case "OTHER":
        return intl.formatMessage({ id: "type_your_source" });
      default:
        return;
    }
  };

  const contacts = useMemo(() => {
    const contactType =
      state.action_source &&
      Array.isArray(state.action_source) &&
      state.action_source[0]
        ? state.action_source[0].id
        : "";

    if (contactConf[contactType]) {
      const { keys = [], valid } = contactConf[contactType];
      return _.get(customerData, "data.doc.contacts", []).reduce(
        (prev, curr) => {
          const validContacts = keys.reduce((kPrev, kCurr) => {
            let subContacts = _.get(curr, kCurr, []);

            if (subContacts && Array.isArray(subContacts)) {
              subContacts = subContacts
                .filter(
                  (e) =>
                    (e.status === "VALID" || e.status === "LEAD") &&
                    _.get(e, valid, "INVALID")
                )
                .map((e) => {
                  let designation =
                    referenceData && referenceData["recipient_type"]
                      ? referenceData["recipient_type"].find(
                          (rf) => rf?.id === curr?.designation
                        )
                      : "";

                  return {
                    label: `${_.get(curr, "first_name", "") || ""} - ${
                      _.get(designation, "label", "") || ""
                    } - ${
                      _.get(e, "country_code", "")
                        ? _.get(e, "country_code", "")
                        : ""
                    } ${_.get(e, "value", "") || ""}`,
                    id: e?._id,
                    recipient_id: curr?._id,
                  };
                });

              return [...kPrev, ...subContacts];
            }
          }, []);

          return [...prev, ...validContacts];
        },
        []
      );
    }

    return [];
  }, [customerData?.data, customerId, state.action_source]);

  const location = useMemo(() => {
    const contactType =
      state.action_source &&
      Array.isArray(state.action_source) &&
      state.action_source[0]
        ? state.action_source[0].id
        : "";

    if (contactConf[contactType]) {
      const { keys = [], valid } = contactConf[contactType];

      if (keys[0] === "address") {
        return _.get(customerData, "data.doc.contacts", []).reduce(
          (prev, curr) => {
            const addresses = _.get(curr, "address", []);

            if (Array.isArray(addresses)) {
              const formattedAddresses = addresses.map((address) => ({
                label: `${
                  _.get(address, "line_1", "")
                    ? `${_.get(address, "line_1", "")} ,`
                    : ""
                } ${
                  _.get(address, "line_2", "")
                    ? `${_.get(address, "line_2", "")} ,`
                    : ""
                } ${
                  _.get(address, "city", "")
                    ? `${_.get(address, "city", "")} ,`
                    : ""
                }${
                  _.get(address, "country", "")
                    ? `${_.get(address, "country", "")} ,`
                    : ""
                } ${
                  _.get(address, "zip_code", "")
                    ? `${_.get(address, "zip_code", "")} ,`
                    : ""
                }`,
                id: curr._id,
                recipient_id: curr._id,
                ...address,
              }));
              return [...prev, ...formattedAddresses];
            }

            return prev;
          },
          []
        );
      }
    }

    return [];
  }, [customerData?.data, customerId, state.action_source]);

  const ipycpValue =
    referenceData["business_unit_list"].find(
      (e) => e.id === _.get(customerData, "data.doc.business_unit", "")
    )?.ipycp || false;

  const filteredCallOutcomeList = ipycpValue
    ? outcomeList.filter((item) => item.type !== ACT_PAYMENT)
    : outcomeList;

  let locationOptions = [...location, { id: "other", label: "Other" }];
  return (
    <div style={{ width: "780px" }}>
      <div className="co-drawer-header">
        <div style={{ display: "flex" }}>
          <Icon icon="generic_action" size={24} />
          <Typography type="h2" subType="medium">
            Action Capture
          </Typography>
        </div>
      </div>
      <AgentActionAlert />
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ width: "150px" }}>
          <SelectBox
            size="mini"
            requiredAstric={true}
            label={intl.formatMessage({
              id: "Source",
            })}
            placeholder={intl.formatMessage({
              id: "Source",
            })}
            clearable={false}
            value={state.action_source}
            onChange={(e) => {
              setState({
                ...state,
                is_inbound_call: true,
                action_source: e.value,
                recipient: [],
                other_source: "",
              });
            }}
            options={[
              { id: "EMAIL", label: "Email" },
              { id: "SMS", label: "SMS" },
              { id: "WHATSAPP", label: "WhatsApp" },
              { id: "LETTER", label: "Letter" },
              { id: "OTHER", label: "Other" },
            ]} //TODO CHANGE TO RD
          />
        </div>
        <div style={{ width: "300px" }}>
          {state.action_source &&
          Array.isArray(state.action_source) &&
          state.action_source[0] &&
          state.action_source[0].id === "OTHER" ? (
            <TextBox
              size="mini"
              requiredAstric={true}
              label={intl.formatMessage({
                id: "type_your_source",
              })}
              placeholder={intl.formatMessage({
                id: "type_your_source",
              })}
              value={state.other_source}
              onChange={(e) => {
                setState({ ...state, other_source: e.target.value });
              }}
            />
          ) : (
            <></>
          )}

          {state.action_source &&
            Array.isArray(state.action_source) &&
            state.action_source[0] &&
            (state.action_source[0].id === "EMAIL" ||
              state.action_source[0].id === "SMS" ||
              state.action_source[0].id === "WHATSAPP" ||
              state.action_source[0].id === "LETTER") && (
              <SelectBox
                size="mini"
                requiredAstric={false}
                label={getSourceLabel(state.action_source[0].id, intl)}
                placeholder={getSourceLabel(state.action_source[0].id, intl)}
                clearable={false}
                value={state.recipient}
                onChange={(e) => {
                  setOtherLocation(_.get(e, "option.id", ""));

                  setState({ ...state, recipient: e.value });
                }}
                options={
                  state.action_source[0].id === "LETTER"
                    ? [...contacts, { id: "OTHER", label: "Other" }]
                    : [...contacts]
                }
              />
            )}
        </div>
      </div>
      {state.recipient &&
      Array.isArray(state.recipient) &&
      state.recipient[0] &&
      state.recipient[0].id === "OTHER" ? (
        <TextBox
          size="mini"
          requiredAstric={true}
          label={intl.formatMessage({
            id: "type_your_address",
          })}
          placeholder={intl.formatMessage({
            id: "type_your_address",
          })}
          value={state.custom_address}
          onChange={(e) => {
            setState({ ...state, custom_address: e.target.value });
          }}
        />
      ) : (
        <></>
      )}
      <Typography
        type="p"
        className="text-secondary"
        subType="regular"
        style={{ paddingBottom: "5px" }}
      >
        Source Outcome
      </Typography>
      <div className="co-call-outcome-container">
        {filteredCallOutcomeList.map((e) => (
          <div
            key={e.type}
            className={`co-call-outcome-btn ${
              state.outcome === e.type ? "co-call-outcome-btn--active" : ""
            } ${
              (state.action_source && state.action_source[0]) ||
              state.other_source?.length > 0
                ? ""
                : "co-call-outcome-btn--disabled"
            }`}
            onClick={() => {
              setState({ ...state, outcome: e.type });
            }}
            disabled={!state.action_source}
          >
            <Icon icon={e.icon} />
            <Typography>{e.label}</Typography>
          </div>
        ))}
      </div>
      {state.outcome ? (
        <Outcome
          type={state.outcome}
          formValues={{
            ...(state.action_source &&
            Array.isArray(state.action_source) &&
            state.action_source[0] &&
            state.action_source[0].id === "OTHER"
              ? {
                  recipients: [{ value: state.other_source }],
                }
              : state.recipient &&
                Array.isArray(state.recipient) &&
                state.recipient[0] &&
                state.recipient[0].id === "OTHER"
              ? { recipients: [{ value: state.custom_address }] }
              : {
                  recipients: state.recipient.map(({ id, recipient_id }) => ({
                    id,
                    recipient_id,
                  })),
                }),
            action_type: "ACTION_CAPTURE",
            action_source:
              state.action_source &&
              state.action_source[0] &&
              state.action_source[0].id
                ? state.action_source[0].id
                : null,
            is_increment_follow_up: true,
            // is_inbound_call: true,
          }}
          recipient_id={
            state.recipient && state.recipient[0] && state.recipient[0].id
              ? state.recipient[0].id
              : null
          }
          contacts={_.get(customerData, "data.doc.contacts", [])}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default GenericAction;
