import { Drawer } from "baseui/drawer";
import moment from "moment";
import React, { useContext } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useQuery } from "react-query";
import {
  downloadExecutionInfo,
  getRoleDeliveryArenaData,
} from "../../services/roleMaintenance";
import { Icon, Typography } from "../../components_v2";
import useFormat from "../../hooks/useFormat";
import _ from "lodash";
import Loader from "../../components/Loader";

const StatusInit = ({ type }) => {
  switch (type) {
    case "DEL":
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "7px",
              height: "7px",
              background: "#14AE5C",
              borderRadius: "100px",
            }}
          ></div>
          <Typography>Delivered</Typography>
        </div>
      );
    case "UD":
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "7px",
              height: "7px",
              background: "#FD372A",
              borderRadius: "100px",
            }}
          ></div>
          <Typography>Undelivered</Typography>
        </div>
      );
    default:
      return null;
  }
};
let x = [
  {
    exc_at: 1721395238008,
    rcv_lst: [
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "max@maxyfi.com", status: "UD", pth: "" },
      { eml: "pro@gmail.com", status: "DEL", pth: "" },
      { eml: "vicky@test.com", status: "UD", pth: "" },
    ],
    id: "669a68264c279af916b1b2fc",
  },
  {
    exc_at: 1721394440196,
    rcv_lst: [
      { eml: "test1@test.com", status: "UD", pth: "" },
      { eml: "test2@test.com", status: "UD", pth: "" },
      { eml: "test3@test.com", status: "UD", pth: "" },
      { eml: "test4@test.com", status: "DEL", pth: "" },
    ],
  },
  {
    exc_at: 1721394403536,
    rcv_lst: [
      { eml: "test5@test.com", status: "DEL", pth: "" },
      { eml: "test6@test.com", status: "DEL", pth: "" },
      { eml: "test7@test.com", status: "DEL", pth: "" },
      { eml: "test8@test.com", status: "UD", pth: "" },
    ],
  },
  {
    exc_at: 1721138437936,
    rcv_lst: [
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
    ],
  },
  {
    exc_at: 1721138437729,
    rcv_lst: [
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
    ],
  },
  {
    exc_at: 1721052037610,
    rcv_lst: [
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
    ],
  },
  {
    exc_at: 1721048636440,
    rcv_lst: [
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
    ],
  },
  {
    exc_at: 1721048614853,
    rcv_lst: [
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
    ],
  },
  {
    exc_at: 1721048541186,
    rcv_lst: [
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
      { eml: "test@test.com", status: "DEL", pth: "" },
    ],
  },
];

const ArenaMaxReport = ({ isArena = {}, setIsArena = () => {} }) => {
  const { currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);
  const format = useFormat();
  const { data, isFetching, isFetched, isError, isLoading, isRefetching } =
    useQuery(
      [
        `REPORT_DELIVERY_ARENA_${currentOrganization}`,
        {
          id: isArena.arenaId,
        },
      ],
      async () => {
        return await getRoleDeliveryArenaData({
          organization: currentOrganization,
          id: isArena.arenaId,
        });
      },
      {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
      }
    );

  let lateDate = null;
  let unReadTag = false;

  // if (isLoading || isFetching) {
  //   return <Loader />;
  // }

  // if (_.get(data, "data.docs", []).length === 0) {
  //   return (

  //   );
  // }

  return (
    <Drawer
      isOpen={isArena.isOpen}
      autoFocus
      onClose={() => setIsArena({ isOpen: false, arenaId: "" })}
    >
      {isLoading || isFetching ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          {" "}
          <Loader />
        </div>
      ) : _.get(data, "data.docs", []).length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Typography type="h3">No Records Found</Typography>
        </div>
      ) : (
        <div
          style={{
            border: "1px solid #cdced9",
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          {_.get(data, "data.docs", []).map((e) => {
            let isDateTag = false;
            let formattedDate = moment
              .utc(e.exc_at)
              ?.tz(currentDefaultFormatDetails.time_zone)
              ?.format(currentDefaultFormatDetails?.date_format);
            if (formattedDate != lateDate) {
              isDateTag = true;
              lateDate = formattedDate;
            }

            let unReadStatus = e.status;
            let showReadStatus = false;

            if (!unReadTag && unReadStatus === "UNREAD") {
              showReadStatus = true;
              unReadTag = true;
            }

            return (
              <div>
                {isDateTag && (
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "left",
                      backgroundColor: "#ebe6e6",
                      borderBottom: "1px solid #cdced9",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#ebe6e6",
                        color: "#787878",
                        paddingLeft: "10px",
                      }}
                    >
                      {lateDate}
                    </p>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "10px",
                    //   borderTop: "1px solid #cdced9",
                    borderBottom: "1px solid #cdced9",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <p>
                      {format.dateTime({
                        value: _.get(e, "exc_at", null),
                        month: true,
                        includeTime: true,
                      })}{" "}
                    </p>
                    <Icon
                      icon={"download"}
                      size={18}
                      isPressable
                      color="#516beb"
                      onClick={() =>
                        downloadExecutionInfo({
                          organization: currentOrganization,
                          id: _.get(e, "_id", null),
                        })
                      }
                    />
                  </div>
                  {e?.rcv_lst?.map((sn) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          textAlign: "left",
                          // paddingLeft: "10px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "200px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {sn?.rcvr ? sn?.rcvr : sn?.pth}
                        </Typography>
                        {sn.sts ? <StatusInit type={sn.sts} /> : <></>}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Drawer>
  );
};

export default ArenaMaxReport;
